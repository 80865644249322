import { faHubspot, faSalesforce } from "@fortawesome/free-brands-svg-icons";
import {
  faBuilding,
  faProjectDiagram,
  faSearch,
  faUser,
  faCirclePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getCanveoTier } from "../utils/getCanveoTier";

const filter = createFilterOptions();

const styles = {
  networkIcon: {
    color: theme.palette.primary.main,
    fontSize: "11px",
    padding: "0px 15px 0px 20px",
  },
  cptyIcon: {
    color: theme.palette.primary.main,
    fontSize: "11px",
    padding: "0px 15px 0px 20px",
  },
  cptySpan: {
    fontSize: "12px",
    color: theme.palette.primary.main,
  },
  networkSpan: {
    fontSize: "12px",
    color: theme.palette.primary.main,
  },
  createIcon: {
    color: theme.palette.primary.main,
    fontSize: "11px",
    padding: "0px 10px 0px 0px",
  },
  hubspotIcon: {
    color: "#FF7A59",
    fontSize: "11px",
    padding: "0px 10px 0px 0px",
  },
  salesforceIcon: {
    color: "#009EDB",
    fontSize: "11px",
    padding: "0px 10px 0px 0px",
  },
};

/**
 * @typedef {*} SelectOrganizationProps
 */

/**
 * @param {SelectOrganizationProps} props
 * @returns {React.JSX.Element}
 */
export default function SelectOrganization({ handleChange, ...props }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);

  const onChange = (/** @type {*} */ event, /** @type {*} */ newValue) => {
    let value;
    if (typeof newValue === "string") {
      value = { shortName: newValue, new: true };
    } else if (newValue?.inputValue || newValue?.inputValue === "") {
      // Create a new value from the user input
      value = {
        shortName: newValue.inputValue,
        toBeCreated: newValue.shortName === "Search in HubSpot" ? false : true,
        toBeCreatedFromHubSpot:
          newValue.shortName === "Search in HubSpot" ? true : false,
      };
    } else {
      value = newValue;
    } // mouseSelect

    handleChange(value);
  };

  const filterOptions = (/** @type {*} */ options, /** @type {*} */ params) => {
    const filtered = filter(
      params.inputValue !== "" && params.inputValue.length > 1
        ? options
        : options.filter(
            (/** @type {{ isNetwork: boolean; }} */ o) => !o.isNetwork
          ),
      params
    );
    // Suggest the creation of a new value
    if (
      params.inputValue !== "" &&
      !filtered.some(
        (f) => f.shortName.toLowerCase() === params.inputValue.toLowerCase()
      )
    ) {
      filtered.push({
        inputValue: params.inputValue,
        shortName: `Create "${params.inputValue}" as a new counterparty`,
        optionType: "create",
      });
      //Integrations - not yet implemented
      if (props.org?.integrations?.hubspot) {
        filtered.push({
          // When you have a hubspot integration, also show the hubspot search option
          inputValue: params.inputValue,
          shortName: `Search for "${params.inputValue}" in Hubspot`,
          optionType: "searchHubspot",
        });
      }
      if (props.org?.integrations?.salesforce?.instanceURL) {
        filtered.push({
          // When you have a salesforce integration, also show the salesforce search option
          inputValue: params.inputValue,
          shortName: `Search for "${params.inputValue}" in Salesforce`,
          optionType: "searchSalesforce",
        });
      }
    } else if (params.inputValue === "") {
      const staticOptions = [
        {
          isNew: true,
          inputValue: params.inputValue,
          shortName: "Create New Party",
          optionType: "create",
        },
      ];

      if (canveoTier === "experimental") {
        staticOptions.push({
          isNew: true,
          inputValue: params.inputValue,
          shortName: "Search in HubSpot",
          optionType: "searchHubspot",
        });
      }

      filtered.unshift(...staticOptions);
    }
    return filtered;
  };

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      sx={{ width: "100%" }}
      freeSolo
      {...props}
      onChange={onChange}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input.
        if (typeof option === "string") return option;
        // Add "xxx" option created dynamically.
        if (option.inputValue) return option.inputValue;
        // Regular option.
        return option.shortName;
      }}
      renderOption={(props, option) => (
        <Typography
          variant={option._id !== undefined ? "body1" : "subtitle1"}
          color="textPrimary"
          {...props}
          style={{ display: "flex", alignItems: "center" }}
        >
          {option.optionType === "create" && (
            <FontAwesomeIcon icon={faCirclePlus} style={styles.createIcon} />
          )}
          {option.optionType === "searchHubspot" && (
            <FontAwesomeIcon icon={faHubspot} style={styles.hubspotIcon} />
          )}
          {option.optionType === "searchSalesforce" && (
            <FontAwesomeIcon
              icon={faSalesforce}
              style={styles.salesforceIcon}
            />
          )}
          {option.shortName}
          {/* OLD APPROACH THAT INCLUDES "NETWORK" - FOR NOW COMMENTED OUT*/}
          {/* {option.isNetwork || option.isHubspot || option.isSalesforce ? (
            <>
              <FontAwesomeIcon
                icon={
                  option.isHubspot
                    ? faHubspot
                    : option.isSalesforce
                    ? faSalesforce
                    : faProjectDiagram
                }
                style={
                  option.isHubspot || option.isSalesforce
                    ? styles.cptyIcon
                    : styles.networkIcon
                }
              />
              <span
                style={
                  option.isHubspot || option.isSalesforce
                    ? styles.cptySpan
                    : styles.networkSpan
                }
              >
                {option.isHubspot
                  ? "Hubspot"
                  : option.isSalesforce
                  ? "Salesforce"
                  : "Canveo Network"}
              </span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={
                  ["Person"].includes(option.entityCategory)
                    ? faUser
                    : faBuilding
                }
                style={styles.cptyIcon}
              />
              <span style={styles.cptySpan}>Counterparty</span>
            </> */}
          {option.isHubspot || option.isSalesforce ? (
            <>
              <FontAwesomeIcon
                icon={option.isHubspot ? faHubspot : faSalesforce}
                style={styles.cptyIcon}
              />
            </>
          ) : null}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Select Party"}
          placeholder={"Party name ..."}
          autoFocus
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={!props.value?._id ? faSearch : faBuilding}
                  color={theme.palette.primary.main}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
