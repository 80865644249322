import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../state/store";

/**
 * @typedef {object} HubSpotCompany
 * @property {string} id
 * @property {object} properties
 * @property {string} properties.createdate
 * @property {string} properties.domain
 * @property {string} properties.hs_lastmodifieddate
 * @property {string} properties.hs_object_id
 * @property {string} properties.name
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {boolean} archived
 */

/**
 * @typedef {object} DialogCreateEntityFromHubSpot
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogCreateEntityFromHubSpot} props
 * @returns {JSX.Element}
 */
export default function DialogCreateEntityFromHubSpot({ open, close }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [companies, setCompanies] = useState(
    /** @type {HubSpotCompany[]} */ ([])
  );

  useEffect(
    () => {
      (async () => {
        const result = await axios.get(
          `${state.settings.api}hubspot/companies`
        );
        const companies = result.data.data;
        setCompanies(companies);
      })();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Create Entity From HubSpot</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container direction="column" alignContent="center">
          <Grid item my={4}>
            <Autocomplete
              options={companies}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="HubSpot Company" />
              )}
              getOptionLabel={(option) => option.properties.name}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>

        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={close}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
