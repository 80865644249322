import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, Tab, Tabs } from "@mui/material";
import axios from "axios";
import { $getRoot } from "lexical";
import React, { useContext, useEffect, useState } from "react";
import SweetScroll from "sweet-scroll";
import { useProperties } from "../../../hooks/useProperties";
import { globalStore } from "../../../state/store";
import DialogAnalyzingAgreementProgressBar from "../../dialogs/DialogAnalyzingAgreementProgressBar";
import { ComplianceAnalysisTab } from "./tabs/ComplianceAnalysisTab";
import { KeyDatesTab } from "./tabs/KeyDatesTab";
import { OtherDataPointsTab } from "./tabs/OtherDataPointsTab";
import { SummaryTab } from "./tabs/SummaryTab";

/**
 * @typedef {object} FastTrackDrawerProps
 * @property {string} agreementVersionId
 * @property {string} docId
 * @property {string} partyId
 * @property {string} agreementTypeId
 * @property {string[]} agreementLabelsIds
 * @property {boolean} documentIsTemplate
 */

/**
 * @param {FastTrackDrawerProps} props
 */
export function FastTrackDrawer({
  docId,
  agreementVersionId,
  agreementLabelsIds,
  agreementTypeId,
  partyId,
  documentIsTemplate,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [latestVersion] = state.drawerVersions.versions;

  const [editor] = useLexicalComposerContext();
  const { getVersionProperties } = useProperties();

  const [selectedTab, setSelectedTab] = useState(0);
  const [, setAnalyzedClauseTopics] = useState(
    /** @type {import("../OldFastTrackDrawer").AnalyzedClauseTopic[]} */ ([])
  );
  const [selectedAnalyzedClauseTopic, setSelectedAnalyzedClauseTopic] =
    useState(
      /** @type {{ clauseTopicId: string; clauseTopic: { complianceExplanation: string}; clauseId: string; clause: { id: string; text: string; updatedText: string; changeExplanation: string; publicComment: string; }} | null} */ (
        null
      )
    );
  const [jobStatus, setJobStatus] = useState("initial");
  const [, setInfoDialogOpen] = useState(false);
  const [, setDeleteDialogOpen] = useState(false);
  const [, setCurrentChip] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedClause, setSelectedClause] = useState(
    /** @type {import("../OldFastTrackDrawer").SelectedClause | null} */ (null)
  );
  const [selectedParameter, setSelectedParameter] = useState(
    /** @type {*} */ (null)
  );

  const [templateProperties, setTemplateProperties] = useState(
    /** @type {import("../../../hooks/useProperties").Property[]} */ ([])
  );
  const [properties, setProperties] = useState(
    /** @type {import("../../../hooks/useProperties").Property[]} */ ([])
  );

  const [jobResult, setJobResult] = useState({
    topicResults: [],
    complianceAssessmentPromptResults:
      /** @type {{ id: string; name: string; agreementClausesForTopic: { id: string; }[] }[]} */ ([]),
  });
  const [jobId, setJobId] = useState("");
  const [, setOpenDialogAddNewQuestionToQuestionnaire] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(
    /** @type {*} */ (null)
  );

  useEffect(
    () => {
      loadAnalysisData();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadAnalysisData() {
    const response = await axios.get(
      `${state.settings.api}agrv/${agreementVersionId}/analysis/details`
    );

    const data = response.data.data;
    if (data) {
      setJobStatus(data.status);
      if (data.status === "finished") {
        setAnalyzedClauseTopics(data.topicResults);
        setJobResult(data);
      } else {
        setJobId(data.jobId);
      }
    }

    getVersionProperties(latestVersion._id, true).then(setTemplateProperties);
    getVersionProperties(latestVersion._id, false).then(setProperties);
  }

  useEffect(() => {
    const element = document.getElementById(
      "fast-track-drawer-result-container"
    );
    if (element) {
      element.scrollTo(0, 0);
    }
  });

  function loadProperties() {
    getVersionProperties(latestVersion._id, true).then(setTemplateProperties);
    getVersionProperties(latestVersion._id, false).then(setProperties);
  }

  /**
   * @param {*} event
   */
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * @param {*} status
   */
  const handleComplianceChange = (status) => {
    console.log("Compliance Status:", status);
    closeMenu();
  };

  /**
   * @param {import("../OldFastTrackDrawer").AnalyzedClauseTopic} analyzedClauseTopic
   */
  function handleAnalyzedClauseTopicClick(analyzedClauseTopic) {
    if (analyzedClauseTopic.id !== selectedAnalyzedClauseTopic?.clauseTopicId) {
      setSelectedAnalyzedClauseTopic({
        clauseTopicId: analyzedClauseTopic.id,
        clauseTopic: analyzedClauseTopic,
        clauseId:
          analyzedClauseTopic?.agreementClausesForTopic?.at(0)?.id || "",
        // @ts-ignore
        clause: analyzedClauseTopic?.agreementClausesForTopic?.at(0),
      });
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: analyzedClauseTopic.id,
        },
      });

      editor.update(() => {
        const sectionNodes = $getRoot().getChildren();
        /** @type {ClauseNode[]} */
        const clauseNodes = sectionNodes.flatMap((node) => {
          return node.getChildren();
        });

        clauseNodes.forEach((cn) => cn.changeFilter("none"));

        clauseNodes
          .filter((clauseNode) =>
            analyzedClauseTopic.agreementClausesForTopic.find(
              (x) => x.id === clauseNode.id
            )
          )
          .forEach((x) => x.changeFilter("in"));

        const clauseNode = clauseNodes.find(
          (clauseNode) =>
            clauseNode.id === analyzedClauseTopic.agreementClausesForTopic[0].id
        );
        if (clauseNode) {
          const element = editor.getElementByKey(clauseNode.getKey());
          if (!element) return;

          const containerElement = document.getElementById("html-main");
          if (!containerElement) return;

          clauseNode.changeFilter("selected");

          const selectedClause = {
            serializedClauseNode: clauseNode.exportJSON(),
            textContent: clauseNode.getTextContent(),
          };
          setSelectedClause(selectedClause);

          const scroller = new SweetScroll(
            {
              duration: 800,
              offset: -70,
              easing: "easeInOutQuart",
            },
            containerElement
          );
          scroller.toElement(element);
          return;
        }
      });
    }
    // If the identifiers match then we want to deselect and revert to an unselected state.
    else {
      editor.update(() => {
        const sectionNodes = $getRoot().getChildren();
        /** @type {ClauseNode[]} */
        const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());
        for (const clauseNode of clauseNodes) {
          clauseNode.changeFilter("none");
        }
      });
      setSelectedAnalyzedClauseTopic(null);
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: null,
        },
      });
    }
  }

  /**
   * @param {MergeField} parameter
   */
  function handleParameterClick(parameter) {
    // if (analyzedClauseTopic.id !== selectedAnalyzedClauseTopic?.clauseTopicId) {
    //   setSelectedAnalyzedClauseTopic({
    //     clauseTopicId: analyzedClauseTopic.id,
    //     clauseTopic: analyzedClauseTopic,
    //     clauseId: analyzedClauseTopic.agreementClausesForTopic[0].id,
    //     clause: analyzedClauseTopic.agreementClausesForTopic[0],
    //   });
    //   dispatch({
    //     type: "NEW_CLAUSE_TOPIC_SELECTION",
    //     payload: {
    //       event: "NEW_CLAUSE_TOPIC_SELECTION",
    //       clauseTopicId: analyzedClauseTopic.id,
    //     },
    //   });
    // }

    editor.update(() => {
      const sectionNodes = $getRoot().getChildren();
      /** @type {ClauseNode[]} */
      const clauseNodes = sectionNodes.flatMap((node) => {
        return node.getChildren();
      });

      clauseNodes.forEach((cn) => cn.changeFilter("none"));

      clauseNodes
        .filter((clauseNode) =>
          clauseNode.getPropertiesIds().includes(parameter._id)
        )
        .forEach((x) => x.changeFilter("in"));

      const clauseNode = clauseNodes.find((clauseNode) =>
        clauseNode.getPropertiesIds().includes(parameter._id)
      );
      if (clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        clauseNode.changeFilter("selected");

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);
        setSelectedParameter(parameter);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      }
    });
  }

  /**
   * @param {import("../OldFastTrackDrawer").AnalyzedClauseTopic} analyzedClauseTopic
   * @param {string} clauseId
   */
  function selectClauseById(analyzedClauseTopic, clauseId) {
    editor.update(() => {
      const sectionNodes = $getRoot().getChildren();
      const clauseNodes = sectionNodes.flatMap((node) => node.getChildren());

      clauseNodes.forEach((cn) => cn.changeFilter("none"));

      clauseNodes
        .filter((clauseNode) =>
          analyzedClauseTopic.agreementClausesForTopic.find(
            (x) => x.id === clauseNode.id
          )
        )
        .forEach((x) => x.changeFilter("in"));

      const clauseNode = clauseNodes.find(
        (clauseNode) => clauseNode.id === clauseId
      );
      if (clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        clauseNode.changeFilter("selected");

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      }
    });
  }

  function handleAnalyzedClausePrevious() {
    const analyzedClauseTopics = jobResult.complianceAssessmentPromptResults;

    // If no topic is selected, start at the last topic and clause
    if (!selectedAnalyzedClauseTopic) {
      const lastTopicIndex = analyzedClauseTopics.length - 1;
      const lastTopic = analyzedClauseTopics[lastTopicIndex];
      const lastClauseIndex = lastTopic.agreementClausesForTopic.length - 1;

      setSelectedAnalyzedClauseTopic({
        clauseTopicId: lastTopic.id,
        // @ts-ignore
        clauseTopic: lastTopic,
        clauseId: lastTopic.agreementClausesForTopic[lastClauseIndex].id,
        // @ts-ignore
        clause: lastTopic.agreementClausesForTopic[lastClauseIndex],
      });
      selectClauseById(
        // @ts-ignore
        lastTopic,
        lastTopic.agreementClausesForTopic[lastClauseIndex].id
      );
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: lastTopic.id,
        },
      });
      return;
    }

    // Find the currently selected clause and topic
    const analyzedClauseTopic = analyzedClauseTopics.find(
      (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
    );

    if (analyzedClauseTopic) {
      const selectedClauseIndex =
        analyzedClauseTopic.agreementClausesForTopic.findIndex(
          (x) => x.id === selectedAnalyzedClauseTopic?.clauseId
        );

      if (selectedClauseIndex > -1) {
        if (selectedClauseIndex === 0) {
          // If we're at the first clause of this topic, go to the previous topic
          const clauseTopicIndex = analyzedClauseTopics.findIndex(
            (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
          );

          if (clauseTopicIndex > -1) {
            if (clauseTopicIndex === 0) {
              // If we're at the first topic, wrap around to the last topic and clause
              const lastTopicIndex = analyzedClauseTopics.length - 1;
              const lastTopic = analyzedClauseTopics[lastTopicIndex];
              const lastClauseIndex =
                lastTopic.agreementClausesForTopic.length - 1;

              setSelectedAnalyzedClauseTopic({
                clauseTopicId: lastTopic.id,
                // @ts-ignore
                clauseTopic: lastTopic,
                clauseId:
                  lastTopic.agreementClausesForTopic[lastClauseIndex].id,
                // @ts-ignore
                clause: lastTopic.agreementClausesForTopic[lastClauseIndex],
              });
              selectClauseById(
                // @ts-ignore
                lastTopic,
                lastTopic.agreementClausesForTopic[lastClauseIndex].id
              );
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: lastTopic.id,
                },
              });
            } else {
              // Go to the previous topic's last clause
              const previousTopic = analyzedClauseTopics[clauseTopicIndex - 1];
              const lastClauseIndex =
                previousTopic.agreementClausesForTopic.length - 1;

              setSelectedAnalyzedClauseTopic({
                clauseTopicId: previousTopic.id,
                // @ts-ignore
                clauseTopic: previousTopic,
                clauseId:
                  previousTopic.agreementClausesForTopic[lastClauseIndex].id,
                // @ts-ignore
                clause: previousTopic.agreementClausesForTopic[lastClauseIndex],
              });
              selectClauseById(
                // @ts-ignore
                previousTopic,
                previousTopic.agreementClausesForTopic[lastClauseIndex].id
              );
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: previousTopic.id,
                },
              });
            }
          }
        } else {
          // Navigate to the previous clause within the current topic
          setSelectedAnalyzedClauseTopic({
            clauseTopicId: analyzedClauseTopic.id,
            // @ts-ignore
            clauseTopic: analyzedClauseTopic,
            clauseId:
              analyzedClauseTopic.agreementClausesForTopic[
                selectedClauseIndex - 1
              ].id,
            // @ts-ignore
            clause:
              analyzedClauseTopic.agreementClausesForTopic[
                selectedClauseIndex - 1
              ],
          });
          selectClauseById(
            // @ts-ignore
            analyzedClauseTopic,
            analyzedClauseTopic.agreementClausesForTopic[
              selectedClauseIndex - 1
            ].id
          );
          dispatch({
            type: "NEW_CLAUSE_TOPIC_SELECTION",
            payload: {
              event: "NEW_CLAUSE_TOPIC_SELECTION",
              clauseTopicId: analyzedClauseTopic.id,
            },
          });
        }
      }
    }
  }

  function handleAnalyzedClauseNext() {
    const analyzedClauseTopics = jobResult.complianceAssessmentPromptResults;
    if (!selectedAnalyzedClauseTopic) {
      setSelectedAnalyzedClauseTopic({
        clauseTopicId: analyzedClauseTopics[0].id,
        // @ts-ignore
        clauseTopic: analyzedClauseTopics[0],
        clauseId: analyzedClauseTopics[0].agreementClausesForTopic[0].id,
        // @ts-ignore
        clause: analyzedClauseTopics[0].agreementClausesForTopic[0],
      });
      selectClauseById(
        // @ts-ignore
        analyzedClauseTopics[0],
        analyzedClauseTopics[0].agreementClausesForTopic[0].id
      );
      dispatch({
        type: "NEW_CLAUSE_TOPIC_SELECTION",
        payload: {
          event: "NEW_CLAUSE_TOPIC_SELECTION",
          clauseTopicId: analyzedClauseTopics[0].id,
        },
      });
      return;
    }

    const analyzedClauseTopic = analyzedClauseTopics.find(
      (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
    );
    if (analyzedClauseTopic) {
      const selectedClauseIndex =
        analyzedClauseTopic.agreementClausesForTopic.findIndex(
          (x) => x.id === selectedAnalyzedClauseTopic?.clauseId
        );
      if (selectedClauseIndex > -1) {
        if (
          selectedClauseIndex ===
          analyzedClauseTopic.agreementClausesForTopic.length - 1
        ) {
          const clauseTopicIndex = analyzedClauseTopics.findIndex(
            (act) => act.id === selectedAnalyzedClauseTopic?.clauseTopicId
          );
          if (clauseTopicIndex > -1) {
            if (clauseTopicIndex === analyzedClauseTopics.length - 1) {
              setSelectedAnalyzedClauseTopic({
                clauseTopicId: analyzedClauseTopics[0].id,
                // @ts-ignore
                clauseTopic: analyzedClauseTopics[0],
                clauseId:
                  analyzedClauseTopics[0].agreementClausesForTopic[0].id,
                // @ts-ignore
                clause: analyzedClauseTopics[0].agreementClausesForTopic[0],
              });
              selectClauseById(
                // @ts-ignore
                analyzedClauseTopics[0],
                analyzedClauseTopics[0].agreementClausesForTopic[0].id
              );
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: analyzedClauseTopics[0].id,
                },
              });
            } else {
              setSelectedAnalyzedClauseTopic({
                clauseTopicId: analyzedClauseTopics[clauseTopicIndex + 1].id,
                // @ts-ignore
                clauseTopic: analyzedClauseTopics[clauseTopicIndex + 1],
                clauseId:
                  analyzedClauseTopics[clauseTopicIndex + 1]
                    .agreementClausesForTopic[0].id,
                // @ts-ignore
                clause:
                  analyzedClauseTopics[clauseTopicIndex + 1]
                    .agreementClausesForTopic[0],
              });
              selectClauseById(
                // @ts-ignore
                analyzedClauseTopics[clauseTopicIndex + 1],
                analyzedClauseTopics[clauseTopicIndex + 1]
                  .agreementClausesForTopic[0].id
              );
              dispatch({
                type: "NEW_CLAUSE_TOPIC_SELECTION",
                payload: {
                  event: "NEW_CLAUSE_TOPIC_SELECTION",
                  clauseTopicId: analyzedClauseTopics[clauseTopicIndex + 1].id,
                },
              });
            }
          }
        } else {
          setSelectedAnalyzedClauseTopic({
            clauseTopicId: analyzedClauseTopic.id,
            // @ts-ignore
            clauseTopic: analyzedClauseTopic,
            clauseId:
              analyzedClauseTopic.agreementClausesForTopic[
                selectedClauseIndex + 1
              ].id,
            // @ts-ignore
            clause:
              analyzedClauseTopic.agreementClausesForTopic[
                selectedClauseIndex + 1
              ],
          });
          selectClauseById(
            // @ts-ignore
            analyzedClauseTopic,
            analyzedClauseTopic.agreementClausesForTopic[
              selectedClauseIndex + 1
            ].id
          );
          dispatch({
            type: "NEW_CLAUSE_TOPIC_SELECTION",
            payload: {
              event: "NEW_CLAUSE_TOPIC_SELECTION",
              clauseTopicId: analyzedClauseTopic.id,
            },
          });
        }
      }
    }
  }

  function handlePropertyNext() {
    const agreementProperties = properties || [];

    const parameter =
      selectedParameter === null
        ? agreementProperties[0]
        : agreementProperties.find(
            (/** @type {{ _id: string; }} */ x) =>
              x._id === selectedParameter._id
          );
    if (!parameter) return;

    editor.update(() => {
      const sectionNodes = $getRoot().getChildren();
      /** @type {ClauseNode[]} */
      const clauseNodes = sectionNodes.flatMap((node) => {
        return node.getChildren();
      });

      clauseNodes.forEach((cn) => cn.changeFilter("none"));

      clauseNodes
        .filter((clauseNode) =>
          clauseNode.getPropertiesIds().includes(parameter._id)
        )
        .forEach((x) => x.changeFilter("in"));

      const clauseNodeIndex = clauseNodes.findIndex(
        (clauseNode) =>
          clauseNode.getPropertiesIds().includes(parameter._id) &&
          clauseNode.id !== selectedClause?.serializedClauseNode.id
      );

      const clauseNode = clauseNodes[clauseNodeIndex];

      const selectedClauseId = clauseNodes.findIndex(
        (clauseNode) =>
          clauseNode.id === selectedClause?.serializedClauseNode.id
      );

      if (clauseNodeIndex > selectedClauseId && clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        clauseNode.changeFilter("selected");

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);
        setSelectedParameter(parameter);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      } else {
        const parameterIndex = agreementProperties.findIndex(
          (/** @type {{ _id: string; }} */ x) => x._id === selectedParameter._id
        );

        const newIndex =
          parameterIndex === agreementProperties.length - 1
            ? 0
            : parameterIndex + 1;

        const newParameter = agreementProperties[newIndex];
        if (!newParameter) return;

        handleParameterClick(newParameter);
      }
    });
  }

  function handlePropertyPrevious() {
    const agreementProperties = properties || [];

    const parameter =
      selectedParameter === null
        ? agreementProperties[agreementProperties.length - 1]
        : agreementProperties.find(
            (/** @type {{ _id: string; }} */ x) =>
              x._id === selectedParameter._id
          );
    if (!parameter) return;

    editor.update(() => {
      const sectionNodes = $getRoot().getChildren();
      /** @type {ClauseNode[]} */
      const clauseNodes = sectionNodes
        .flatMap((node) => {
          return node.getChildren();
        })
        .reverse();

      clauseNodes.forEach((cn) => cn.changeFilter("none"));

      clauseNodes
        .filter((clauseNode) =>
          clauseNode.getPropertiesIds().includes(parameter._id)
        )
        .forEach((x) => x.changeFilter("in"));

      const clauseNodeIndex = clauseNodes.findIndex(
        (clauseNode) =>
          clauseNode.getPropertiesIds().includes(parameter._id) &&
          clauseNode.id !== selectedClause?.serializedClauseNode.id
      );

      const clauseNode = clauseNodes[clauseNodeIndex];

      const selectedClauseId = clauseNodes.findIndex(
        (clauseNode) =>
          clauseNode.id === selectedClause?.serializedClauseNode.id
      );

      if (clauseNodeIndex > selectedClauseId && clauseNode) {
        const element = editor.getElementByKey(clauseNode.getKey());
        if (!element) return;

        const containerElement = document.getElementById("html-main");
        if (!containerElement) return;

        clauseNode.changeFilter("selected");

        const selectedClause = {
          serializedClauseNode: clauseNode.exportJSON(),
          textContent: clauseNode.getTextContent(),
        };
        setSelectedClause(selectedClause);
        setSelectedParameter(parameter);

        const scroller = new SweetScroll(
          {
            duration: 800,
            offset: -70,
            easing: "easeInOutQuart",
          },
          containerElement
        );
        scroller.toElement(element);
        return;
      } else {
        const parameterIndex = agreementProperties.findIndex(
          (/** @type {{ _id: string; }} */ x) => x._id === selectedParameter._id
        );

        const newIndex =
          parameterIndex === 0
            ? agreementProperties.length - 1
            : parameterIndex - 1;

        const newParameter = agreementProperties[newIndex];
        if (!newParameter) return;

        handleParameterClick(newParameter);
      }
    });
  }

  /**
   * @param {*} chipLabel
   * @param {*} event
   */
  const handleInfoClick = (chipLabel, event) => {
    event.stopPropagation();
    setCurrentChip(chipLabel);
    setInfoDialogOpen(true);
  };

  /**
   * @param {*} chipLabel
   * @param {*} event
   */
  const handleDeleteClick = (chipLabel, event) => {
    event.stopPropagation();
    setCurrentChip(chipLabel);
    setDeleteDialogOpen(true);
  };

  /**
   * @param {{ extractMetadata: boolean, complianceAnalysis: boolean }} props
   */
  async function analyzeUsingAi(props) {
    setJobStatus("ongoing");

    const response = await axios.post(
      `${state.settings.api}agrv/${agreementVersionId}/analyze`,
      props
    );

    const job = response.data.data;
    if (!job.id) throw new Error("No job id.");

    setJobId(job.id);
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(_event, newSelectedTab) => {
            setSelectedTab(newSelectedTab);
          }}
        >
          <Tab label="Summary" />
          <Tab label="Key Dates" />
          <Tab label="Other Data Points" />
          <Tab label="Compliance Analysis" />
        </Tabs>
      </Box>

      <Box display={selectedTab !== 0 ? "none" : undefined}>
        <SummaryTab
          docID={docId}
          isTemplate={documentIsTemplate}
          partyId={partyId}
          analyzeUsingAi={analyzeUsingAi}
          jobStatus={jobStatus}
          jobResult={jobResult}
        />
      </Box>

      <Box display={selectedTab !== 1 ? "none" : undefined}>
        <KeyDatesTab
          agreementId={docId}
          analyzeUsingAi={analyzeUsingAi}
          jobStatus={jobStatus}
          jobResult={jobResult}
        />
      </Box>

      <Box display={selectedTab !== 2 ? "none" : undefined}>
        <OtherDataPointsTab
          agreementId={docId}
          analyzeUsingAi={analyzeUsingAi}
          jobStatus={jobStatus}
          handlePropertyPrevious={handlePropertyPrevious}
          handlePropertyNext={handlePropertyNext}
          handleParameterClick={handleParameterClick}
          properties={properties}
          templateProperties={templateProperties}
          selectedParameter={selectedParameter}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          setOpenDialogAddNewQuestionToQuestionnaire={
            setOpenDialogAddNewQuestionToQuestionnaire
          }
          loadProperties={loadProperties}
          docId={docId}
          partyId={partyId}
          agreementTypeId={agreementTypeId}
          agreementLabelsIds={agreementLabelsIds}
          documentIsTemplate={documentIsTemplate}
          jobResult={jobResult}
        />
      </Box>

      <Box display={selectedTab !== 3 ? "none" : undefined}>
        <ComplianceAnalysisTab
          analyzeUsingAi={analyzeUsingAi}
          jobStatus={jobStatus}
          handleAnalyzedClausePrevious={handleAnalyzedClausePrevious}
          handleAnalyzedClauseNext={handleAnalyzedClauseNext}
          jobResult={jobResult}
          handleInfoClick={handleInfoClick}
          handleAnalyzedClauseTopicClick={handleAnalyzedClauseTopicClick}
          openMenu={openMenu}
          handleDeleteClick={handleDeleteClick}
          handleComplianceChange={handleComplianceChange}
          selectedAnalyzedClauseTopic={selectedAnalyzedClauseTopic}
          closeMenu={closeMenu}
          anchorEl={anchorEl}
          selectedClause={selectedClause}
        />
      </Box>

      {jobId && (
        <DialogAnalyzingAgreementProgressBar
          open={true}
          close={() => {
            // Uncomment for previous approach.
            // loadAnalysisData().then(() => {
            //   setJobId("");
            //   setJobStatus("finished");
            // });
            window.location.reload();
          }}
          jobId={jobId}
        />
      )}
    </>
  );
}
