import { faTimes, faUniversity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { countryToFlag } from "../../utils";
import { DialogNewDefaultEntityRule } from "./DialogNewDefaultEntityRule";

/**
 * @typedef {object} DialogSetDefaultContractingEntitiesProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogSetDefaultContractingEntitiesProps} props
 * @returns {JSX.Element}
 */
export function DialogSetDefaultContractingEntities({ open, close }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [openDialogNewDefaultEntityRule, setOpenDialogNewDefaultEntityRule] =
    useState(false);
  const [conditions, setConditions] = useState(/** @type {*[]} */ ([]));

  function renderOption(/** @type {*} */ option) {
    let value = option.legalName;
    if (option?.address?.[0].country) {
      value += countryToFlag(option.address[0].country);
    }

    return value;
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "none",
          width: "750px",
        },
      }}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Set Default Contracting Entities</DialogTitle>

      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <Typography sx={{ color: theme.palette.grey[600] }}>
              Define which of your legal entities should be automatically added
              as a party to new agreements
            </Typography>
          </Grid>

          <Grid item mt={4}>
            <Autocomplete
              disablePortal
              options={state.subs}
              fullWidth
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input.
                if (typeof option === "string") return option;
                // Add "xxx" option created dynamically.
                if (option.inputValue) return option.inputValue;
                // Regular option.
                return renderOption(option);
              }}
              renderOption={(props, option) => (
                <Typography
                  variant={option._id !== undefined ? "body1" : "subtitle1"}
                  color={"textPrimary"}
                  {...props}
                >
                  <span>
                    {renderOption(option)}{" "}
                    {/* {option.defaultLegalEntity ? <b>{"(Default)"}</b> : <></>} */}
                    {/* TODO: needs to be reverted upon final implementation */}
                    {option.defaultLegalEntity ? <></> : <></>}
                  </span>
                </Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus={false}
                  label="Overall Fallback Contracting Entity"
                  placeholder="Select overall fallback contracting entity ..."
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: "new-password",
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon
                          icon={faUniversity}
                          color={theme.palette.primary.main}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item mt={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Special Cases
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Button
              variant="text"
              color="primary"
              size="small"
              sx={{
                fontWeight: "600",
              }}
              onClick={() => setOpenDialogNewDefaultEntityRule(true)}
            >
              Add special case ...
            </Button>

            {openDialogNewDefaultEntityRule && (
              <DialogNewDefaultEntityRule
                open={openDialogNewDefaultEntityRule}
                close={() => setOpenDialogNewDefaultEntityRule(false)}
                submit={(condition) => {
                  setOpenDialogNewDefaultEntityRule(false);
                  setConditions((prev) => {
                    console.log(prev);
                    return [...prev, condition];
                  });
                }}
              />
            )}
          </Grid>

          <Grid item>
            {conditions.map((condition, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    outline: "dashed 1px",
                    borderRadius: "25px",
                    padding: 2,
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  }}
                  mt={2}
                >
                  When the{" "}
                  <b>Counterparty's {condition.partyInformationField.label} </b>
                  {condition.operator.label}{" "}
                  <b>{condition.comparisonValue.join(", ")}</b> then our default
                  contracting entity is{" "}
                  <b>
                    {renderOption(condition.overallFallbackContractingEntity)}
                  </b>
                  .
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={close}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
