import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const elementsToAdd = [12, 15, 5, 8, 10, 8, 6, 5, 10, 4, 8, 4, 3, 2];
const timesElapsedForUpdate = [
  3000, 3000, 3600, 4000, 4600, 4800, 5400, 4400, 5600, 5200, 5600, 5200, 5600,
  5200,
];

/**
 * @typedef {object} DialogSigningProgressProps
 * @property {boolean} open
 * @property {() => void} close
 */

/**
 * @param {DialogSigningProgressProps} props
 * @returns {JSX.Element}
 */
export default function DialogSigningProgress({ open, close }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    /** @type {NodeJS.Timeout[]} */
    let timeoutIds = [];

    const updateProgress = (/** @type {number} */ index) => {
      if (index < elementsToAdd.length) {
        setProgress((prevProgress) => {
          const toAdd = elementsToAdd[index];
          return Math.min(prevProgress + toAdd, 100);
        });

        const timeoutId = setTimeout(() => {
          updateProgress(index + 1);
        }, timesElapsedForUpdate[index]);

        timeoutIds.push(timeoutId);
      }
    };

    updateProgress(0);

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, []);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>Preparing Your Document for Signature Setup</DialogTitle>

      <DialogContent>
        <Typography>
          Please wait while we prepare your document for signature setup ...
        </Typography>

        <LinearProgressWithLabel value={progress} />
      </DialogContent>
    </Dialog>
  );
}

/**
 * @param {import("@mui/material").LinearProgressProps & { value: number }} props
 * @returns {JSX.Element}
 */
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
        <LinearProgress
          variant={props.value === 100 ? "indeterminate" : "determinate"}
          {...props}
        />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
