import { faCaretDown, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../theme/theme";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
function Collapsable({ title, collapsed, children, ...props }) {
  const [collapsedState, setCollapsedState] = useState(!!collapsed);
  return (
    <Box {...props}>
      <Box
        sx={{ display: "flex", cursor: "pointer" }}
        onClick={() => setCollapsedState((prev) => !prev)}
      >
        <Typography
          style={{
            fontWeight: "700",
            flexGrow: 1,
            fontSize: "19px",
            color: theme.palette.primary.main,
          }}
        >
          {title}
        </Typography>
        <FontAwesomeIcon
          icon={!collapsedState ? faCaretDown : faCaretRight}
          color={theme.palette.grey[500]}
        />
      </Box>
      <Collapse in={!collapsedState}>{children}</Collapse>
    </Box>
  );
}

export default Collapsable;
