import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs } from "@lexical/utils";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { format } from "date-fns";
import { $isTextNode } from "lexical";
import React, { useContext, useState } from "react";
import useMergeFieldService from "../../hooks/useMergeFieldService";
import { globalStore } from "../../state/store";
import { $isMarkNode } from "../editor/nodes/MarkNode";
import { $createRedlineNode } from "../editor/nodes/RedlineNode";
import { handleNodeDeletion } from "../editor/plugins/TrackChangesPlugin/utils";
import { getDefaultRedlineData } from "../editor/utils/getDefaultRedlineData";
import { currencyCodes, durationUnits } from "./constants";
import { getMergeFieldDisplayValue, mergeFieldValueIsValid } from "./utils";

/**
 * @typedef {object} MergeFieldValueProps
 * @property {MergeField} mergeField
 * @property {string} partyId
 */

/**
 * @param {MergeFieldValueProps} props
 */
export function MergeFieldValue({ mergeField, partyId }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { updateMergeField } = useMergeFieldService();
  const [editor] = useLexicalComposerContext();

  useState(mergeField);

  /** @type {import("../editor/nodes/RedlineNode").NodeMetadata} */
  const metadata = {
    creatorId: state.user?._id,
    creatorEmail: state.user?.email,
    creatorDisplayName: state.user?.displayName,
    creatorPhotoUrl: state.user?.photoURL,
    creationDate: new Date().toUTCString(),
    partyId: partyId,
  };

  const [mergeFieldValue, setMergeFieldValue] = useState(mergeField.value);

  const [selectedListOptionsIds] = useState(mergeField.selectedListOptionsIds);
  const [enable, setEnable] = useState(false);

  async function submitMergeFieldValueChanges() {
    const updatedMergeField = await updateMergeField(
      {
        ...mergeField,
        value: mergeFieldValue,
        selectedListOptionsIds,
        displayValue: getMergeFieldDisplayValue(mergeFieldValue),
      },
      "Merge Field"
    ).catch((error) => {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while updating the merge field value.",
          severity: "error",
        },
      });
    });

    if (mergeField.scope === "document") {
      updateEditorMergeField(
        updatedMergeField.editorMarkNodeId,
        updatedMergeField
      );
    }
  }

  /**
   * Updates existing Mark nodes on the document text with the updated Merge Field.
   *
   * @param {string} editorMarkNodeId
   * @param {MergeField} mergeField
   */
  function updateEditorMergeField(editorMarkNodeId, mergeField) {
    editor.update(() => {
      const depthFirstSearch = $dfs();
      const defaultRedlineData = getDefaultRedlineData(partyId, state.user);

      for (const { node } of depthFirstSearch) {
        // Find all the Mark Nodes that correspond to the Merge Field.
        if (
          $isMarkNode(node) &&
          node.getMarkType() === "mergeField" &&
          node.getIDs().find((id) => id === editorMarkNodeId)
        ) {
          // Update the Merge Field on the node.
          node.setMergeField(mergeField);

          if (
            // TODO: Double-check how we have done this in the other file. Centralize this logic in one place.
            mergeField.displayValue.toLowerCase() !==
            node.getTextContent().toLowerCase()
          ) {
            const nodes = node.getChildren();
            for (const node of nodes) {
              if ($isTextNode(node)) {
                handleNodeDeletion(node, defaultRedlineData);
                continue;
              }
            }

            const lastTextNode = node.getAllTextNodes().at(-1);
            if (lastTextNode) {
              const addition = $createRedlineNode({
                partyID: partyId,
                redlineType: "add",
                date: new Date().toUTCString(),
                metadata,
                text: mergeField.displayValue,
              });
              addition.setFormat(lastTextNode.getFormat());
              addition.setStyle(lastTextNode.getStyle());

              lastTextNode.insertAfter(addition);
            }
          }
        }
      }
    });
  }

  return (
    <>
      {mergeFieldValue.type === "freeText" && (
        <>
          <Grid container>
            <TextField
              autoFocus={true}
              value={mergeFieldValue.value}
              variant="outlined"
              fullWidth
              multiline
              onFocus={() => {
                setEnable(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setEnable(false);
                }, 100);
              }}
              onChange={(event) => {
                setMergeFieldValue({
                  ...mergeFieldValue,
                  value: event.target.value,
                });
              }}
            />
          </Grid>
        </>
      )}

      {mergeFieldValue.type === "date" && (
        <Grid container>
          <TextField
            autoFocus={true}
            value={
              mergeFieldValue
                ? format(new Date(mergeFieldValue.value), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd")
            }
            type="date"
            variant="outlined"
            fullWidth
            multiline
            onFocus={() => {
              setEnable(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setEnable(false);
              }, 100);
            }}
            onChange={(event) => {
              setMergeFieldValue({
                ...mergeFieldValue,
                value: event.target.value,
              });
            }}
          />
        </Grid>
      )}

      {mergeFieldValue.type === "duration" && (
        <Grid container direction={"row"} justifyContent={"center"}>
          <Grid item xs={5}>
            <TextField
              type="number"
              autoFocus={true}
              value={mergeFieldValue.durationValue}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={7} sx={{ paddingLeft: "10px" }}>
            <Autocomplete
              value={mergeFieldValue.durationUnit}
              options={durationUnits}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Duration Unit"
                    placeholder="Select Duration Unit ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      )}

      {mergeFieldValue.type === "number" && (
        <Grid container>
          <TextField
            autoFocus={true}
            type="number"
            value={mergeFieldValue.value}
            variant="outlined"
          />
        </Grid>
      )}

      {mergeFieldValue.type === "currency" && (
        <Grid container direction={"row"} justifyContent={"center"}>
          <Grid item xs={6}>
            <Autocomplete
              options={currencyCodes}
              autoHighlight
              getOptionLabel={(option) => option.label}
              value={mergeFieldValue.currencyUnit}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Currency Unit"
                    placeholder="Select Currency Unit ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: "10px" }}>
            <TextField
              autoFocus={true}
              type="number"
              value={mergeFieldValue.currencyValue}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}

      {mergeFieldValue.type === "percentage" && (
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={10}>
            <TextField
              type="number"
              autoFocus={true}
              value={mergeFieldValue.value}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
            <div>{"%"}</div>
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" justifyContent="end" mt={2}>
        <Grid item>
          <Button>Cancel</Button>
        </Grid>

        <Grid item>
          <Button
            disabled={!enable || !mergeFieldValueIsValid(mergeField.value)}
            onClick={submitMergeFieldValueChanges}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
