export const initialNodes = [
  {
    id: "draft",
    type: "statusNode",
    data: { label: "Draft", id: "draft", agreementStatus: "Draft" },
    position: { x: 50, y: 200 },
  },
  {
    id: "review",
    type: "statusNode",
    data: {
      id: "review",
      label: "Review by Counterparty",
      agreementStatus: "Review",
    },
    position: { x: 250, y: 200 },
  },
  {
    id: "negotiation",
    type: "statusNode",
    data: {
      id: "negotiation",
      label: "Negotiation",
      agreementStatus: "Negotiation",
    },
    position: { x: 450, y: 200 },
  },
  {
    id: "agreedform",
    type: "statusNode",
    data: {
      id: "agreedform",
      label: "Agreed Form",
      agreementStatus: "AgreedForm",
    },
    position: { x: 650, y: 200 },
  },
  {
    id: "approvedforsignature",
    type: "statusNode",
    data: {
      id: "approvedforsignature",
      label: "Approved for Signature",
      agreementStatus: "Execution",
    },
    position: { x: 850, y: 200 },
  },
  {
    id: "beingSigned",
    type: "statusNode",
    data: {
      id: "beingSigned",
      label: "Being Signed",
      agreementStatus: "Execution",
    },
    position: { x: 1050, y: 200 },
  },
  {
    id: "ineffect",
    type: "statusNode",
    data: { id: "ineffect", label: "In Effect", agreementStatus: "InEffect" },
    position: { x: 1250, y: 200 },
  },
];
/** @type {*[]} */
export const initialEdges = [];
