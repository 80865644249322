import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import { defaultRequestor } from "../../reviewsAndApprovals/constants/defaultRequestor";
import { getAgreementData } from "../../reviewsAndApprovals/functions/getAgreementData";
import { getAvailableOrganizationUsers } from "../../reviewsAndApprovals/functions/getAvailableOrganizationUsers";
import AddReviewerTab from "./tabs/AddReviewerTab";
import ReviewListTab from "./tabs/ReviewListTab";

/**
 * @typedef {object} DialogCreateReviewTicketProps
 * @property {boolean} open
 * @property {boolean} isTemplate
 * @property {(refreshReviewTickets: boolean) => void} close
 * @property {(reviewList: ReviewList, comment: string) => CreateReviewTicket} createReviewTicket
 * @property {string} [dialogTitle]
 * @property {boolean} [isDefault]
 * @property {ReviewList} [reviewListTemplate]
 * @property {string} [commentLabel]
 * @property {boolean} [persistChanges]
 */

/**
 * @param {DialogCreateReviewTicketProps} props
 * @returns {JSX.Element}
 */
export default function DialogCreateReviewTicket({
  open,
  isTemplate,
  close,
  createReviewTicket,
  dialogTitle = "Create Review Request",
  isDefault,
  reviewListTemplate = [],
  commentLabel,
  persistChanges = true,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  /** @type {StateUser} */
  const currentUser = state.user;
  const currentRequestor = /** @type {const} */ ({
    type: "user",
    _id: currentUser._id,
    photoUrl: currentUser.photoURL,
    role: "requestor",
    displayName: currentUser.displayName,
    email: currentUser.email,
    title: currentUser.title,
  });
  /** @type {StateUser[]} */
  const organizationUsers = state.users;
  const [isLoading, setIsLoading] = useState(false);

  const [reviewList, setReviewList] = useState(() => {
    if (reviewListTemplate.length > 0) {
      const [, ...rest] = reviewListTemplate;
      return [currentRequestor, ...rest];
    }

    /** @type {ReviewList} */
    const defaultReviewList = [];

    if (!isDefault) {
      defaultReviewList.push(currentRequestor);
    } else {
      defaultReviewList.push(defaultRequestor);
    }

    defaultReviewList.push(
      { type: "caret" },
      { type: "addReviewer" },
      { type: "caret" },
      { type: "check" }
    );

    return defaultReviewList;
  });

  const reviewListUsers = /** @type {ReviewListUserItem[]} */ (
    reviewList.filter((reviewListItem) => reviewListItem.type === "user")
  );

  const [tab, setTab] = useState(
    /** @type {"reviewList" | "addReviewer"} */ ("reviewList")
  );
  const [reviewListItemIndex, setReviewListItemIndex] = useState(
    /** @type {number | null} */ (null)
  );

  const availableOrganizationUsers = getAvailableOrganizationUsers(
    state?.user?.role?.name,
    organizationUsers,
    reviewListUsers
  );

  const [comment, setComment] = useState(() => {
    const { firstCounterpartyLegalName, agreementTypeName } = getAgreementData(
      isTemplate,
      state
    );
    if (agreementTypeName && firstCounterpartyLegalName) {
      return `Please review this ${agreementTypeName} for ${firstCounterpartyLegalName}.`;
    }

    if (agreementTypeName) {
      const sentenceStart = !isDefault
        ? "Please review this"
        : "Please review this";
      return `${sentenceStart} ${agreementTypeName}.`;
    }

    return "Please review this review request.";
  });

  /**
   * @param {StateUser} newApprover
   * @returns {void}
   */
  function addNewReviewer(newApprover) {
    if (!reviewListItemIndex) return;

    setReviewList((previous) => {
      const copy = [...previous];

      /** @type {ReviewList} */
      const newItems = [
        { type: "addReviewer" },
        {
          type: "user",
          _id: newApprover._id,
          photoUrl: newApprover.photoURL,
          role: "reviewer",
          displayName: newApprover.displayName,
          email: newApprover.email,
          title: newApprover.title,
          reviewStatus: "Pending",
        },
        { type: "addReviewer" },
      ];

      copy.splice(reviewListItemIndex, 1, ...newItems);

      return copy.filter((x) => x.type !== "caret");
    });

    setTab("reviewList");
  }

  /**
   * @param {string} comment
   * @returns {Promise<void>}
   */
  async function submitReviewTicket(comment) {
    setIsLoading(true);

    const task = createReviewTicket(reviewList, comment);

    if (persistChanges) {
      const response = await axios
        .post(state.settings.api + "task", { task })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message:
                "Unable to create the review request, try again or contact Canveo Support if the issue persists.",
              severity: "error",
            },
          });
        })
        .finally(() => setIsLoading(false));
      if (!response) return;
    }

    close(true);
  }

  return (
    <Dialog open={open} onClose={() => close(false)} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={() => close(false)}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>{dialogTitle}</DialogTitle>

      {isLoading ? (
        <>
          <DialogContent sx={{ paddingX: 10 }}>
            <Grid container justifyContent="center">
              <CanveoCircularProgress />
            </Grid>
          </DialogContent>
        </>
      ) : (
        <>
          {tab === "reviewList" && (
            <ReviewListTab
              reviewList={reviewList}
              handleAddNewReviewer={(reviewListItemIndex) => {
                setReviewListItemIndex(reviewListItemIndex);
                setTab("addReviewer");
              }}
              handleRemoveReviewer={(reviewListItemIndex) => {
                setReviewList((previous) => {
                  const copy = [...previous];
                  copy.splice(reviewListItemIndex, 2);
                  return copy;
                });
              }}
              handleCancel={() => close(false)}
              handleSubmit={submitReviewTicket}
              comment={comment}
              setComment={setComment}
              commentLabel={commentLabel}
            />
          )}

          {tab === "addReviewer" && (
            <AddReviewerTab
              organizationUsers={availableOrganizationUsers}
              handleBack={() => setTab("reviewList")}
              handleNext={addNewReviewer}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
