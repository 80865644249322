import { faCirclePlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useState } from "react";
import { CanveoCircularProgress, FabStandard } from "../../components";
import DialogConfirmDelete from "../../components/dialogs/DialogConfirmDelete";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";

const columns = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 970,
    renderCell: (/** @type {{ row: { name: string; }; }} */ params) => {
      const taskStatus = params.row.name;
      return (
        <Chip
          size="small"
          label={taskStatus}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            fontWeight: "bold",
          }}
        />
      );
    },
  },
  // {
  //   headerName: "Area",
  //   field: "area",
  //   minWidth: 150,
  //   renderCell: (/** @type {{ row: { area: string[]; }; }} */ params) => {
  //     const areaLabels = params.row.area || [];
  //     if (typeof areaLabels === "string") return areaLabels;

  //     return (
  //       <Grid container direction="column" gap={1}>
  //         {areaLabels.map((al, index) => (
  //           <Grid key={index} item>
  //             <Chip
  //               size="small"
  //               label={al}
  //               sx={{
  //                 backgroundColor: "#FFFFFF",
  //                 color: theme.palette.primary.main,
  //                 border: `1px solid ${theme.palette.primary.main}`,
  //               }}
  //             />
  //           </Grid>
  //         ))}
  //       </Grid>
  //     );
  //   },
  // },
  // {
  //   headerName: "Agreement Type",
  //   field: "type",
  //   minWidth: 150,
  // },
  // {
  //   headerName: "Created",
  //   field: "created",
  //   valueFormatter: dateColumnFormatter,
  // },
  // {
  //   headerName: "Updated",
  //   field: "updated",
  //   type: "date",
  //   valueFormatter: dateColumnFormatter,
  // },
  // {
  //   headerName: "Creator",
  //   field: "creator",
  //   minWidth: 250,
  //   renderCell: (/** @type {{ row: { creator: *; }; }} */ params) => {
  //     const creator = params.row.creator || {};
  //     return getDisplayUsersCellForTable([
  //       {
  //         displayName: creator.displayName,
  //         title: creator.title,
  //         photoURL: creator.photoURL,
  //       },
  //     ]);
  //   },
  // },
];

/**
 * @typedef {object} TopicsProps
 * @property {React.Dispatch<React.SetStateAction<*>>} setDialogClauseOpen
 * @property {React.Dispatch<React.SetStateAction<*>>} setDialogDetails
 */

/**
 * @param {TopicsProps} props
 * @returns {JSX.Element}
 */
export function Topics({ setDialogClauseOpen, setDialogDetails }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [selectedRowsIds, setSelectedRowsIds] = useState(
    /** @type {string[]} */ ([])
  );
  const [openDeleteTopicsDialog, setOpenDeleteTopicsDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const rows = state.clauseTypes
    .filter((/** @type {{ active: boolean; }} */ ct) => ct.active)
    .map((/** @type {{ _id: string; }} */ ct) => ({ ...ct, id: ct._id }));

  /**
   * @param {import("@mui/x-data-grid").GridRowSelectionModel} rowSelectionModel
   * @param {import("@mui/x-data-grid").GridCallbackDetails<any>} _details
   * @returns {void}
   */
  const handleRowSelectionModelChange = (rowSelectionModel, _details) =>
    setSelectedRowsIds(rowSelectionModel.map((id) => id.toString()));

  const handleCloseDeleteDialog = () => setOpenDeleteTopicsDialog(false);

  const deleteTopics = async () => {
    try {
      setLoading(true);
      await axios.delete(
        `${state.settings.api}clausetype/bulk/${selectedRowsIds}`
      );
      // Dispatch the refresh displayed data
      dispatch({
        type: "DELETE_CLAUSETYPES",
        payload: selectedRowsIds,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while deleting the topics.",
          severity: "error",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <Grid container justifyContent="center" sx={{ mt: 50 }}>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item display="flex" justifyContent="center" xs={8}>
          <Typography variant="h4">Manage Legal Topics</Typography>
        </Grid>
        <Grid item display="flex" justifyContent="center" xs={8} sx={{ m: 2 }}>
          <Typography variant="body1" textAlign="center">
            Specify which Legal Topics your content should be grouped by when
            reviewing and searching your agreements (for example, "Payment
            Terms")
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" alignItems="center">
        <FabStandard
          click={() => {
            setDialogDetails({ type: "newtopic" });
            setDialogClauseOpen(true);
          }}
          text="New"
          icon={faCirclePlus}
          sx={{
            left: "100px",
            top: "80px",
            right: "unset",
          }}
        />
        <Grid container mb={16} justifyContent="center">
          <Box sx={{ height: 400, width: 1020 }}>
            <Grid container direction="row" mt={4} spacing={1}>
              <Grid item>
                <Button
                  disabled={!selectedRowsIds.length}
                  variant="outlined"
                  size="small"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ fontSize: "14px" }}
                    />
                  }
                  onClick={() => setOpenDeleteTopicsDialog(true)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              onRowSelectionModelChange={handleRowSelectionModelChange}
              checkboxSelection
              density="comfortable"
              getRowHeight={() => "auto"}
              onCellClick={(params) => {
                // Check if the clicked cell is the selection checkbox
                if (params.field === "__check__") {
                  return;
                }
                setDialogDetails({
                  type: "updatetopic",
                  _id: params.row._id,
                  name: params.row.name,
                });
                setDialogClauseOpen(true);
              }}
              // @ts-ignore
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              filterMode="client"
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 2,
                  cursor: "pointer",
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontWeight: 400,
                },
                [`& .${gridClasses.detailPanel}`]: {
                  background: "transparent",
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
                border: 0,
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
                "& .MuiDataGrid-columnHeader": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
              hideFooter
              autoHeight
              disableColumnFilter
            />
          </Box>
        </Grid>
      </Grid>
      <DialogConfirmDelete
        open={openDeleteTopicsDialog}
        title="Delete Legal Topic"
        message="Are you sure you want to delete this legal topic?"
        handleClose={handleCloseDeleteDialog}
        handleConfirm={async () => {
          handleCloseDeleteDialog();
          await deleteTopics();
        }}
      />
    </>
  );
}
