/**
 * @param {string} str
 * @param {number} wordLength
 * @param {number} totalLength
 * @returns {string}
 */
function processLongString(str = "", wordLength, totalLength) {
  /** @type {string[]} */
  const res = [];
  let lengthCaptured = 0;
  const temp = str.split(" ");
  temp.forEach((w) => {
    if (lengthCaptured < totalLength) {
      const toPush =
        lengthCaptured + w.length > totalLength
          ? w.substr(0, totalLength - lengthCaptured - 1) + ".."
          : w.length > wordLength
          ? w.substr(0, wordLength - 1) + ".."
          : w;
      res.push(toPush);
      lengthCaptured = lengthCaptured + toPush.length;
    }
  });
  return res.join(" ");
}

export default processLongString;
