import {
  faCassetteTape,
  faClipboard,
  faCodeMerge,
  faComments,
  faInfoCircle,
  faThumbsUp,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCassetteTape as faCassetteTapeSolid,
  faClipboard as faClipboardSolid,
  faCodeMerge as faCodeMergeSolid,
  faComments as faCommentsSolid,
  faInfoCircle as faInfoCircleSolid,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useContext, useRef } from "react";
import { createPortal } from "react-dom";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import { getColorForPartyID } from "../../../../utils";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import { $isClauseNode } from "../../nodes/ClauseNode";

/**
 * @typedef {object} OpenIssuesLineContainerProps
 * @property {HTMLElement} anchorElem
 * @property {import(".").OpenIssue[]} line
 * @property {import("lexical").LexicalEditor} editor
 * @property {string} partyId
 * @property {string} origin
 */

/**
 * @param {OpenIssuesLineContainerProps} _
 * @returns {JSX.Element}
 */
export default function OpenIssuesLineContainer({ anchorElem, line, origin }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);

  const [editor] = useLexicalComposerContext();
  // We can get the height from the first element of the line.
  const [firstElement] = line;
  const rect = firstElement.element.getBoundingClientRect();
  const anchorElemRect = anchorElem.getBoundingClientRect();
  /** @type {React.RefObject<HTMLDivElement>} */
  const lineRef = useRef(null);

  /** @type {JSX.Element[]} */
  const icons = [];

  /**
   *
   * @param {import(".").OpenIssue} openIssue
   */
  function handleIconClickEvent(openIssue) {
    const status = $isClauseNode(openIssue.node) ? "completed" : "ongoing";

    // Pop out clause if open issue is attached to a clause.
    if ($isClauseNode(openIssue.node)) {
      editor.update(() => {
        openIssue.node.changeFilter("in");
      });
    }

    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: {
        id: openIssue.id,
        type: "navigation",
        status,
      },
    });
  }

  let hasInfoIcon;
  const getWorkflow = (/** @type {string} */ openIssueId) => {
    const id = openIssueId.split("_").slice(1).join("_");

    return state.workflows.find((/** @type {*} */ w) => w.lid === id);
  };

  for (let index = 0; index < line.length; index++) {
    const openIssue = line[index];

    const { isSelected, openIssueType, metadata } = openIssue;

    let icon;
    let style;
    let partyId;

    switch (openIssueType) {
      case "internalComment": {
        partyId = metadata?.partyId;

        if (!partyId) {
          throw new Error(
            "Missing Party ID for this open issue node metadata."
          );
        }

        if (isSelected) {
          icon = faClipboardSolid;
          style = {
            color: getColorForPartyID(partyId),
          };
        } else {
          const workflow = getWorkflow(openIssue.id);

          if (workflow?.wfStatus === "resolved") {
            icon = faClipboardSolid;
            style = {
              color: theme.palette.grey[300],
            };
          } else {
            icon = faClipboard;
          }
        }
        break;
      }

      case "approvalRequest": {
        if (isSelected) {
          partyId = metadata?.partyId;

          if (!partyId) {
            throw new Error(
              "Missing Party ID for this open issue node metadata."
            );
          }
          icon = faThumbsUpSolid;
          style = {
            color: getColorForPartyID(partyId),
          };
        } else {
          icon = faThumbsUp;
        }
        break;
      }

      case "mergeField": {
        // Commented for David's demo.
        // if (isSelected) {
        //   icon = faFileImportSolid;
        //   style = {
        //     color: "#4AA8FF",
        //   };
        // } else {
        //   icon = faFileImport;
        // }

        break;
      }

      case "publicComment": {
        partyId = metadata?.partyId;

        if (!partyId) {
          throw new Error(
            "Missing Party ID for this open issue node metadata."
          );
        }

        if (isSelected) {
          icon = faCommentsSolid;
          style = {
            color: getColorForPartyID(partyId),
          };
        } else {
          const workflow = getWorkflow(openIssue.id);

          if (workflow?.wfStatus === "resolved") {
            icon = faCommentsSolid;
            style = {
              color: theme.palette.grey[300],
            };
          } else {
            icon = faComments;
          }
        }

        break;
      }

      case "info": {
        if (canveoTier === "experimental" || origin === "playbook") {
          if (isSelected) {
            const partyId = state.parties.length
              ? state.parties.find(
                  (/** @type {*} */ p) => p.creationBy === state.user._id
                )?.partyID
              : "party0";

            if (!partyId) {
              throw new Error(
                "Missing Party ID for this open issue node metadata."
              );
            }

            icon = faInfoCircleSolid;

            style = {
              color: getColorForPartyID(partyId),
            };
          } else {
            icon = faInfoCircle;
          }
        }

        hasInfoIcon = index;
        break;
      }

      case "both": {
        //TODO: change icon
        if (isSelected) {
          const partyId = metadata?.partyId;

          if (!partyId) {
            throw new Error(
              "Missing Party ID for this open issue node metadata."
            );
          }

          icon = faCassetteTapeSolid;
          style = {
            color: getColorForPartyID(partyId),
          };
        } else {
          icon = faCassetteTape;
        }
        break;
      }

      case "variants": {
        // Only display on templates.
        if (!window.location.href.includes("agreement")) {
          if (isSelected) {
            icon = faCodeMergeSolid;
          } else {
            icon = faCodeMerge;
          }
        }

        break;
      }

      default: {
        throw new Error(`${openIssueType} is not a valid open issue type.`);
      }
    }

    if (icon) {
      icons.push(
        <FontAwesomeIcon
          key={index}
          icon={icon}
          style={{
            ...style,
            cursor: "pointer",
          }}
          onClick={() => handleIconClickEvent(openIssue)}
        />
      );
    }
  }

  if (hasInfoIcon !== undefined) {
    icons.push(icons.splice(hasInfoIcon, 1)[0]);
  }

  return (
    <>
      {createPortal(
        <div
          ref={lineRef}
          style={{
            display: "flex",
            gap: "10px",
            position: "absolute",
            top: rect.top - anchorElemRect.top + 2,
            left: anchorElemRect.width + 180,
          }}
        >
          {icons}
        </div>,
        anchorElem
      )}
    </>
  );
}
