import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../state/store";

// 180 progress steps that sum exactly to 100, updating roughly every 4 seconds (with fluctuations)
// 18 cycles of 10 steps each (total 180 steps)
const elementsToAdd = [
  // Cycle 1
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 2
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 3
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 4
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 5
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 6
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 7
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 8
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 9
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 10
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 11
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 12
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 13
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 14
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 15
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 16
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 17
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.56,
  // Cycle 18 (final cycle, adjust last element)
  0.55, 0.56, 0.55, 0.56, 0.55, 0.55, 0.56, 0.55, 0.56, 0.66,
];
const timesElapsedForUpdate = [
  // Cycle 1
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 2
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 3
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 4
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 5
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 6
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 7
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 8
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 9
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 10
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 11
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 12
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 13
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 14
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 15
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 16
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 17
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
  // Cycle 18
  3700, 4200, 3800, 4000, 3600, 4100, 3900, 4300, 3500, 4900,
];

/**
 * @typedef {object} DialogAnalyzingAgreementProgressBarProps
 * @property {boolean} open
 * @property {(error?: *) => void} close
 * @property {string} jobId
 */

/**
 * @param {DialogAnalyzingAgreementProgressBarProps} props
 * @returns {JSX.Element}
 */
export default function DialogAnalyzingAgreementProgressBar({
  open,
  close,
  jobId,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [progress, setProgress] = useState(0);
  const [isPolling, setIsPolling] = useState(true);

  useEffect(() => {
    /** @type {NodeJS.Timeout[]} */
    let timeoutIds = [];

    const updateProgress = (/** @type {number} */ index) => {
      if (index < elementsToAdd.length) {
        setProgress((prevProgress) => {
          const toAdd = elementsToAdd[index];
          return Math.min(prevProgress + toAdd, 100);
        });

        const timeoutId = setTimeout(() => {
          updateProgress(index + 1);
        }, timesElapsedForUpdate[index]);

        timeoutIds.push(timeoutId);
      }
    };

    updateProgress(0);

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, []);

  useEffect(
    () => {
      /** @type {*} */
      let interval;

      const fetchProgress = async () => {
        try {
          const response = await axios.get(
            `${state.settings.api}jobs/${jobId}/status`
          );
          const data = response.data.data;

          // Uncomment and comment out previous useEffect to have the polling update the progress bar.
          // setProgress(data.progress);

          if (data.progress >= 100) {
            clearInterval(interval); // Stop polling if progress is 100.
            setIsPolling(false);
            close();
          }
        } catch (error) {
          console.error("Error fetching progress:", error);
          clearInterval(interval); // Stop polling on error.
          setIsPolling(false);
          close(error);
        }
      };

      // Make the first request immediately.
      fetchProgress();

      // Then poll every 10 seconds.
      if (isPolling) {
        interval = setInterval(fetchProgress, 10000);
      }

      // Cleanup interval on unmount or if `isPolling` changes.
      return () => clearInterval(interval);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPolling]
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Analyzing Agreement ...</DialogTitle>

      <DialogContent>
        <Typography>
          Take a break while we analyze this agreement and fill in information
        </Typography>

        <LinearProgressWithLabel value={progress} />
      </DialogContent>
    </Dialog>
  );
}

/**
 * @param {import("@mui/material").LinearProgressProps & { value: number }} props
 * @returns {JSX.Element}
 */
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant={props.value === 100 ? "indeterminate" : "determinate"}
          {...props}
        />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
