import { faTimes, faUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import docx from "../../../assets/img/docx.png";
import pdf from "../../../assets/img/pdf.png";
import xlsx from "../../../assets/img/xlsx.png";
import { CanveoCircularProgress } from "../../../components";
import DialogFileConversionErrorDetails from "../../../components/dialogs/DialogFileConverionErrorDetails";
import convertSfdtToLexical from "../../../components/editor/converters/convertSfdtToLexical";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { randomString } from "../../../utils";
import { decompressData } from "../../../utils/decompressData";
import { NewTicketContext } from "../contexts/NewTicketContext";

const fileAppTypes = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const fileSources = [
  { label: "Template by counterparty", value: "Counterparty" },
  {
    label: "Template by us",
    value: "Owner",
  },
  { label: "Other", value: "Other" },
];

export default function AddDocumentSelector() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const { ticket, setTicket } = useContext(NewTicketContext);

  const [open, setOpen] = useState(false);
  const [dropHover, setDropHover] = useState(false);
  const [, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversionError, setConversionError] = React.useState(
    /** @type {Error | undefined} */ (undefined)
  );

  function handleClose() {
    setOpen(false);
  }

  /**
   * @param {File} file
   */
  function getFileType({ name, type }) {
    if (name.endsWith(".pdf") && type === fileAppTypes.pdf) return "pdf";
    if (name?.endsWith(".doc") && type === fileAppTypes.doc) return "doc";
    if (name.endsWith(".docx") && type === fileAppTypes.docx) return "docx";
    if (name.endsWith(".xlsx") && type === fileAppTypes.xlsx) return "xlsx";

    throw new Error("Invalid file type.");
  }

  /**
   * @param {File} file
   */
  async function handleUploadChange(file) {
    try {
      setErrMsg("");
      setLoading(true);

      const fileType = getFileType(file);
      if (!fileType) {
        setErrMsg("Unable to recognize the file type");
        setLoading(false);
        return;
      }

      const uploadSignedUrlResponse = await axios.post(
        state.settings.api + "upload/signedUrl",
        {
          contentType: file.type,
          bucketAlias: "documents",
        }
      );

      const uploadSignedUrl = uploadSignedUrlResponse.data.data.uploadSignedUrl;
      const fileName = uploadSignedUrlResponse.data.data.key;

      await fetch(uploadSignedUrl, { method: "PUT", body: file });

      let uploadFile = {
        fileType: fileType,
        file: fileName,
        fileName: file.name,
        uploadType: "import",
        agrType: null,
        fileSource: fileSources[1],
        tempKey: randomString(20),
      };

      if (fileType === "doc" || fileType === "docx") {
        const importResult = await axios.get(
          `${state.settings.api}document/import/${fileName}`
        );

        if (!importResult?.data?.data) {
          setErrMsg(
            "An error occurred while converting the file (interim step)"
          );
          setLoading(false);
          return;
        }

        const sfdt = await decompressData(importResult.data.data);
        const lexical = await convertSfdtToLexical(
          importResult.data.data,
          state
        );

        uploadFile = {
          ...uploadFile,
          // @ts-ignore
          content: lexical?.content ?? [],
          contentMetadata: {
            listsStructure: lexical.listsStructure,
          },
          sfdt,
          firstPageHeader: lexical.header,
          firstPageFooter: lexical.footer,
        };
      }

      setLoading(false);
      setTicket((ticket) => {
        return {
          ...ticket,
          documents: [...ticket.documents, uploadFile],
        };
      });
      setOpen(false);
    } catch (error) {
      setErrMsg("An error occurred while processing the Word document.");
      if (error instanceof Error) {
        setConversionError(error);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  /**
   * @param {number} index
   */
  function removeDocument(index) {
    const documentsCopy = [...ticket.documents];
    documentsCopy.splice(index, 1);

    setTicket((ticket) => {
      return {
        ...ticket,
        documents: documentsCopy,
      };
    });
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        px: 2,
        pt: 2,
        maxHeight: 420,
        overflow: "auto",
        maxWidth: 800,
      }}
    >
      {ticket.documents.map((document, index) => (
        <Grid
          container
          key={index}
          gap={2}
          mb={ticket.documents.length - 1 === index ? 4 : undefined}
        >
          <Grid item>
            <Box component="span">
              <Link
                sx={{ color: "blue", textDecorationColor: "blue" }}
                href="#"
              >
                {document.fileName}
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Box component="span">
              <IconButton onClick={() => removeDocument(index)}>
                <FontAwesomeIcon icon={faTimes} style={{ fontSize: "10px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Grid container>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
          sx={{ fontWeight: "600" }}
        >
          Add document ...
        </Button>
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center">
            {loading ? (
              <>
                <Box
                  sx={{
                    my: 6,
                    width: "fit-content",
                    margin: "0 auto",
                  }}
                >
                  <CanveoCircularProgress />
                </Box>
              </>
            ) : (
              <>
                <FileUploader
                  handleChange={handleUploadChange}
                  name="uploadfile"
                  types={["doc", "docx", "pdf", "xlsx"]}
                  label={"Upload or drop your file here"}
                  maxSize={20}
                  minSize={0}
                  onDraggingStateChange={(/** @type {boolean} */ dragging) =>
                    setDropHover(dragging)
                  }
                  hoverTitle={" "}
                  children={
                    <Box
                      sx={{
                        width: "320px",
                        height: "180px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        border: dropHover
                          ? "2px solid" + theme.palette.primary.main
                          : "1px dotted" + theme.palette.grey[300],
                        backgroundColor: dropHover
                          ? theme.palette.grey[200]
                          : theme.palette.grey[50],
                        padding: "30px",
                        fontSize: "14px",
                        fontWeight: "600",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{
                              color: theme.palette.primary.main,
                              fontSize: "30px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Box
                            sx={{
                              mt: 1,
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <>
                              {dropHover
                                ? "Time to let the file go ..."
                                : "Upload or drop your file here ..."}
                              <br />
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                sx={{ mt: 1 }}
                              >
                                <Grid item>
                                  <img src={docx} alt="docx" width={20} />
                                </Grid>
                                <Grid item>
                                  <img src={pdf} alt="pdf" width={20} />
                                </Grid>
                                <Grid item>
                                  <img src={xlsx} alt="xlsx" width={20} />
                                </Grid>
                              </Grid>
                            </>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={{ marginRight: "auto" }} onClick={handleClose}>
            Cancel
          </Button>

          {/* <Button
            sx={{ marginLeft: "auto" }}
            //   disabled={!(party.organization && party.entity)}
            onClick={() => {}}
          >
            Add&nbsp;&nbsp;
            <FontAwesomeIcon icon={faCircleArrowRight} />
          </Button> */}
        </DialogActions>
      </Dialog>

      {conversionError && (
        <DialogFileConversionErrorDetails
          conversionError={conversionError}
          close={() => setConversionError(undefined)}
        />
      )}
    </Grid>
  );
}
