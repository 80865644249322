import theme from "../../theme/theme";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

/**
 * A dialog component for confirming deletion actions
 * @component
 * @param {Object} props - The component props
 * @param {boolean} props.open - Controls whether the dialog is displayed
 * @param {string} props.title - The title text of the dialog
 * @param {string} props.message - The main message content of the dialog
 * @param {() => void} props.handleClose - Callback function when dialog is closed or cancelled
 * @param {() => void} props.handleConfirm - Callback function when deletion is confirmed
 * @returns {JSX.Element} A Material-UI Dialog component for delete confirmation
 */
export default function DialogConfirmDelete({
  open,
  title,
  message,
  handleClose,
  handleConfirm,
}) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ my: 3 }}>
        <Box
          sx={{
            textAlign: "center",
            mb: 2,
            fontSize: "30px",
            color: theme.palette.primary.main,
          }}
        >
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography align="center">{message}</Typography>
        </Box>
        <Typography variant="subtitle1" align="center">
          This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 2, pl: 2, pr: 2 }}>
        <Button onClick={handleClose} sx={{ marginRight: "auto" }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" disableElevation>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
