import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CanveoCircularProgress, Editor } from "../components";
import { globalStore } from "../state/store";
import { decompressData } from "../utils/decompressData";

const Agreement = () => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { aid } = useParams();
  if (!aid) throw new Error("Agreement ID is required.");

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  /**
   * Loads the details of an agreement and updates them in the store.
   *
   * @param {string} agreementId
   * @returns {Promise<void>}
   */
  async function loadAgreementDetails(agreementId) {
    setLoading(true);

    const getAgreementDetailsResponse = await axios
      .get(`${state.settings.api}agr/agrdetails/${agreementId}`)
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            severity: "error",
            message:
              "An error occurred while retrieving the agreement details, try again or contact Canveo Support if the issue persists.",
          },
        });
        navigate("/dashboard");
      })
      .finally(() => {
        setLoading(false);
      });
    if (!getAgreementDetailsResponse) return;

    const data = await decompressData(getAgreementDetailsResponse.data.data);

    // Update agreement related data in the store.
    dispatch({ type: "INIT_AGRS", payload: data.ags });
    dispatch({ type: "INIT_AVS", payload: data.avs });
    dispatch({
      type: "INIT_PARTIES",
      payload: data.parties,
    });
    dispatch({
      type: "UPDATE_AGREXEC",
      payload: data.agrExec,
    });
    dispatch({
      type: "INIT_WORKFLOWS",
      payload: data.workflows,
    });
    dispatch({
      type: "INIT_PARAMS_DOC",
      payload: data.params,
    });
  }

  useEffect(
    () => {
      if (!aid) return;
      loadAgreementDetails(aid);
    },
    // Runs only when `aid` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aid]
  );

  return (
    <div>
      {loading ||
      state.agrs.length === 0 ||
      !state.agrs.some((/** @type {{ _id: string; }} */ a) => a._id === aid) ||
      state.avs.length === 0 ||
      !state.avs.some(
        (/** @type {{ agrID: string; }} */ av) => av.agrID === aid
      ) ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item sx={{ mb: 1 }}>
            <CanveoCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
          {/* @ts-ignore */}
          <Editor page="Agreement" aid={aid} />
        </Box>
      )}
    </div>
  );
};

export default Agreement;
