import { DateTime } from "luxon";

/**
 * @param {{ value: Date; } | string | undefined} params
 * @returns {Date | string}
 */
export function dateColumnFormatter(params) {
  if (!params) return "";

  if (typeof params === "string") return params;

  if (typeof params.value === "string") {
    return params.value;
  }

  if (params instanceof Date) {
    return DateTime.fromJSDate(params).toFormat("dd/MM/yyyy");
  }

  if (params.value instanceof Date) {
    return DateTime.fromJSDate(params.value).toFormat("dd/MM/yyyy");
  }

  return "";
}
