import { Box, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import DialogCreateReviewTicket from "../../../../../../components/dialogs/DialogCreateReviewTicket";
import DialogReviewTicketDetails from "../../../../../../components/dialogs/DialogReviewTicketDetails";
import { NewReviewTicketButtonPanel } from "../../../../../../components/reviewsAndApprovals/components/NewReviewTicketButtonPanel";
import ReviewsTable from "../../../../../../components/reviewsAndApprovals/components/ReviewsTable";
import { globalStore } from "../../../../../../state/store";

const title = "Add Review Task";
const subtitle =
  "Review Tasks let you ask someone to review the agreement - for example, someone in your legal team";

/**
 * @typedef {object} WorkflowDefaultReviewsTabProps
 * @property {DialogConfigureTemplateTemplate} template
 * @property {ReviewTicket[]} reviewTickets
 * @property {(reviewTickets: ReviewTicket[]) => void} updateReviewTickets
 * @property {(approvalTicket: ReviewTicket) => void} createReviewTicket
 */

/**
 * @param {WorkflowDefaultReviewsTabProps} props
 * @returns {JSX.Element}
 */
export function WorkflowDefaultReviewsTab({
  template,
  reviewTickets,
  updateReviewTickets,
  createReviewTicket,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [openDialogCreateReviewTicket, setOpenDialogCreateReviewTicket] =
    useState(false);
  const [selectedReviewTicketIndex, setSelectedReviewTicketIndex] =
    useState(-1);

  const openDialogReviewTicketDetails = selectedReviewTicketIndex >= 0;

  /**
   * @param {ReviewTicket} reviewTicket
   * @returns {void}
   */
  function handleUpdateReviewTicket(reviewTicket) {
    const updatedReviewTickets = [...reviewTickets];

    const index = updatedReviewTickets.findIndex(
      (rt) => rt._id === reviewTicket._id
    );
    updatedReviewTickets[index] = reviewTicket;

    updateReviewTickets(updatedReviewTickets);
  }

  /**
   * @param {string} reviewTicketId
   * @returns {void}
   */
  function handleDeleteReviewTicket(reviewTicketId) {
    const updatedReviewTickets = [...reviewTickets];

    const index = updatedReviewTickets.findIndex(
      (rt) => rt._id === reviewTicketId
    );
    updatedReviewTickets.splice(index, 1);

    updateReviewTickets(updatedReviewTickets);
  }

  return (
    <Box mt={4}>
      <Grid container>
        {reviewTickets.length === 0 && (
          <NewReviewTicketButtonPanel
            title={title}
            subtitle={subtitle}
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />
        )}
      </Grid>

      {reviewTickets.length === 1 && (
        <>
          <NewReviewTicketButtonPanel
            title={title}
            subtitle={subtitle}
            buttonPosition="left"
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />

          <DialogReviewTicketDetails
            open={openDialogReviewTicketDetails}
            close={() => setSelectedReviewTicketIndex(-1)}
            agreement={{
              ents: [],
              agrTitle: template.agrTitle,
              _id: template._id,
            }}
            reviewTicket={reviewTickets[0]}
            updateReviewTicket={handleUpdateReviewTicket}
            deleteReviewTicket={handleDeleteReviewTicket}
            displayOnlyDialogContent
            isTemplate
          />
        </>
      )}

      {reviewTickets.length > 1 && (
        <>
          <NewReviewTicketButtonPanel
            title={title}
            subtitle={subtitle}
            buttonPosition="left"
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />

          <ReviewsTable
            reviewTickets={reviewTickets}
            setSelectedReviewTicketIndex={setSelectedReviewTicketIndex}
          />

          {openDialogReviewTicketDetails && (
            <DialogReviewTicketDetails
              open={openDialogReviewTicketDetails}
              close={() => setSelectedReviewTicketIndex(-1)}
              agreement={{
                ents: [],
                agrTitle: template.agrTitle,
                _id: template._id,
              }}
              reviewTicket={reviewTickets[selectedReviewTicketIndex]}
              updateReviewTicket={handleUpdateReviewTicket}
              deleteReviewTicket={handleDeleteReviewTicket}
              isTemplate
            />
          )}
        </>
      )}

      {openDialogCreateReviewTicket && (
        <DialogCreateReviewTicket
          isTemplate
          isDefault
          dialogTitle="Create Default Review Flow"
          open={openDialogCreateReviewTicket}
          persistChanges={false}
          close={(refreshReviewTickets) => {
            setOpenDialogCreateReviewTicket(false);
            // if (refreshReviewTickets) {
            //   loadTemplateReviewTickets(template._id);
            // }
          }}
          createReviewTicket={(reviewList, comment) => {
            /** @type {CreateReviewTicket} */
            const reviewTicket = {
              type: "Review",
              status: "Pending Assignment",
              template: template._id,
              organization: state.user.orgID,
              requestor: state.user._id,
              isTemplateConfiguration: true,
              reviewChain: {
                reviewList,
                messages: comment
                  ? [
                      {
                        text: comment,
                        senderId: state.user._id,
                        senderDisplayName: state.user.displayName,
                        senderPhotoUrl: state.user.photoURL,
                        sentTime: new Date().toISOString(),
                      },
                    ]
                  : [],
                subscribers: [
                  {
                    _id: state.user._id,
                    email: state.user.email,
                    orgId: state.user.orgID,
                  },
                ],
              },
            };
            // @ts-ignore
            createReviewTicket(reviewTicket);
            return reviewTicket;
          }}
          commentLabel="Optionally Add Default Review Instructions"
        />
      )}
    </Box>
  );
}
