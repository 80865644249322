import pako from "pako";

/**
 * Compresses data into a Base64-encoded string using gzip.
 *
 * @param {*} data The data to compress.
 * @returns {Promise<string>} A promise that resolves to the Base64-encoded compressed string.
 */
export function compressData(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const jsonString = JSON.stringify(data);
        const compressedBuffer = pako.gzip(jsonString);

        // Convert Uint8Array to a binary string.
        const binaryString = Array.from(compressedBuffer, (byte) =>
          String.fromCharCode(byte)
        ).join("");

        // Encode the binary string as Base64.
        const base64String = btoa(binaryString);

        resolve(base64String);
      } catch (error) {
        reject(error);
      }
    }, 0); // Yield to the event loop.
  });
}
