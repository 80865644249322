// If you are using npm and have pako installed
import pako from "pako";

/**
 * @param {string} base64string
 * @returns {Promise<*>}
 */
export function decompressData(base64string) {
  return new Promise((resolve, reject) => {
    try {
      const compressedData = Uint8Array.from(atob(base64string), (c) =>
        c.charCodeAt(0)
      );
      // Decompress the data using pako (similar to zlib's gunzip).
      const decompressedBuffer = pako.inflate(compressedData);

      // Convert the decompressed buffer to a string.
      const jsonString = new TextDecoder().decode(decompressedBuffer);

      try {
        // Parse the JSON string into a JavaScript object.
        const parsedData = JSON.parse(jsonString);
        resolve(parsedData);
      } catch (parseError) {
        reject(parseError);
      }
    } catch (error) {
      reject(error);
    }
  });
}
