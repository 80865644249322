import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import {
  faChevronDown,
  faEllipsisVertical,
  faInfoCircle,
  faRepeat,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs } from "@lexical/utils";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { $createTextNode, $getSelection, $isRangeSelection } from "lexical";
import React, { useContext, useState } from "react";
import { v4 as uuid } from "uuid";
import { globalStore } from "../../../../state/store";
import CanveoCircularProgress from "../../../CanveoCircularProgress";
import { ADD_WORKFLOW_COMMAND } from "../../commands";
import { $isClauseNode } from "../../nodes/ClauseNode";
import { $createCustomParagraphNode } from "../../nodes/CustomParagraphNode";
import { $createRedlineNode } from "../../nodes/RedlineNode";
import { getClauseNode } from "../../utils";
import { createWorkflow } from "../commenting";
import useClauseOptions from "./useClauseOptions";

/**
 * @typedef {object} ClauseTypesMenuProps
 * @property {import("../OpenIssuesPlugin").OpenIssue} currentOpenIssue
 * @property {boolean} isInEffect
 * @property {string} partyId
 */

/**
 * @param {ClauseTypesMenuProps} _
 * @returns {React.JSX.Element}
 */
export default function ClauseTypesMenu({
  currentOpenIssue,
  isInEffect,
  partyId,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const theme = useTheme();
  const [clauseTypesSelected, setClauseTypesSelected] = useState([]);
  const [anchorElClause, setAnchorElClause] = useState(null);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [
    displayExplainThisChangeInAPublicComment,
    setDisplayExplainThisChangeInAPublicComment,
  ] = useState(false);

  const { setDialogClauseOpen } = useClauseOptions();

  const [messages, setMessages] = useState(() => {
    const /** @type {ChatMessage[]} */ defaultMessages = [
        {
          sender: "VeoBot",
          role: "system",
          message:
            "You are VeoBot a ChatGPT bot that specializes on giving legal advice regarding contracts and agreements.",
          position: "normal",
          direction: "incoming",
          display: false,
        },
        {
          sender: "VeoBot",
          role: "assistant",
          message: `Hi ${state.user.displayName}, how can I help you with this clause?`,
          sentTime: "just now",
          direction: "incoming",
          position: "normal",
          display: true,
        },
      ];
    return defaultMessages;
  });
  const [isTyping, setIsTyping] = useState(false);

  async function handleSendRequest(
    /** @type {string} */ message,
    /** @type {{ displayMessageInChat?: boolean; displayResponseInChat?: boolean; }} */ options = {}
  ) {
    try {
      const { displayMessageInChat = true, displayResponseInChat = true } =
        options;
      const /** @type {ChatMessage} */ newMessage = {
          sender: state.user.displayName,
          role: "user",
          message,
          direction: "outgoing",
          position: "normal",
          display: displayMessageInChat,
        };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      setIsTyping(true);

      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response?.choices[0]?.message?.content;
      if (content) {
        const /** @type {ChatMessage} */ chatGPTResponse = {
            sender: "VeoBot",
            role: "assistant",
            message: content,
            direction: "incoming",
            position: "normal",
            display: displayResponseInChat,
          };

        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
      }

      return content;
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  }

  async function processMessageToChatGPT(
    /** @type {ChatMessage[]} */ chatMessages
  ) {
    const messages = chatMessages.map((chatMessage) => {
      return { role: chatMessage.role, content: chatMessage.message };
    });

    const response = await axios.post(state.settings.api + "chatGpt/chat", {
      messages,
    });

    return response.data.data;
  }

  async function suggestLowerRiskVersion() {
    try {
      setLoading(true);

      const clause = getSelectedOpenIssueClause().node;

      const party = state.parties.find(
        (/** @type {{ partyID: string; }} */ party) => party.partyID === partyId
      );
      if (!party) {
        throw new Error("Party does not exist.");
      }

      const clauses = getEditorClauses();
      const paragraph = clauses?.find(
        // @ts-ignore
        (/** @type {{ id: string; }} */ child) => child?.id === clause?.__id
      )?.children;

      const jsonString = JSON.stringify(paragraph);
      const role = party.role || "owner";
      // VeoBot prompt.
      const content = `
        Consider the following agreement clause in JSON format:
    
        ${jsonString}
    
        Suggest a version of this clause that lowers my risk as the ${role} of the agreement.
    `.trim();

      const response = await handleSendRequest(content, {
        displayMessageInChat: false,
        displayResponseInChat: false,
      });

      const paragraphs = JSON.parse(response || null);
      if (!paragraphs) {
        throw new Error("VeoBot generated JSON is not valid.");
      }

      editor.update(() => {
        const clauseNode = currentOpenIssue.node;
        if (!$isClauseNode(clauseNode)) {
          throw new Error(
            "Current open issue node for clause types menu must be a clause node."
          );
        }

        let /** @type {import("../../nodes/ClauseNode").ClauseNode | undefined} */ editorClauseNode;

        const dfs = $dfs();
        for (const { node } of dfs) {
          if ($isClauseNode(node) && node.id === clauseNode.id) {
            editorClauseNode = node;
          }
        }

        if (!editorClauseNode) {
          throw new Error(
            "Current open issue node does not exist on the editor state."
          );
        }

        const newParagraphs = [];

        for (const paragraph of paragraphs) {
          if (paragraph.type === "customParagraph") {
            const customParagraph = $createCustomParagraphNode();

            for (const child of paragraph.children) {
              switch (child.type) {
                case "text": {
                  const textNode = $createTextNode(child.text);
                  customParagraph.append(textNode);
                  break;
                }

                case "redline": {
                  const redlineNode = $createRedlineNode({
                    date: new Date().toISOString(),
                    metadata: {
                      partyId: partyId,
                      creatorDisplayName: `${state.user.displayName} (vb)`,
                      creatorId: state.user._id,
                      creationDate: new Date().toISOString(),
                      creatorEmail: state.user.email,
                      creatorPhotoUrl: state.user.photoURL,
                      revisionId: uuid(),
                    },
                    // @ts-ignore
                    redlineType: child.redlineType,
                    partyID: partyId,
                    // @ts-ignore
                    text: child.text,
                  });
                  customParagraph.append(redlineNode);

                  break;
                }

                default: {
                  continue;
                }
              }
            }

            newParagraphs.push(customParagraph);
          }
        }

        clauseNode.getChildren().forEach((child) => child.remove(true));
        clauseNode.append(...newParagraphs);
      });

      if (!displayExplainThisChangeInAPublicComment) {
        setDisplayExplainThisChangeInAPublicComment(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function explainLastAction() {
    // VeoBot prompt.
    const message =
      "Explain your last action. Be as concise as possible. Only go above 40 words if necessary.";
    await handleSendRequest(message, { displayMessageInChat: false });
  }

  async function explainThisChangeInAPublicComment() {
    try {
      setLoading(true);
      // VeoBot prompt.
      const message =
        "Draft a short comment for the counterparty that explains the reasons for your previous lower-risk suggestion, and why this should be an acceptable compromise. Be as concise as possible. Only go above 40 words if absolutely required.";
      const response = await handleSendRequest(message, {
        displayMessageInChat: false,
        displayResponseInChat: false,
      });

      const selectedText = "";
      const partyID = partyId;
      const isPrivate = true;

      const clauseNode = editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        const anchor = selection ? selection.anchor : null;
        const clauseNode = anchor ? getClauseNode(anchor.getNode()) : null;
        return clauseNode;
      });
      if (!clauseNode) throw new Error("No clause node currently selected.");

      const quote =
        selectedText.length > 100
          ? selectedText.slice(0, 99) + "…"
          : selectedText;

      const prefix = isPrivate ? `cp${partyID.substring(5)}_` : "cp_";
      const clauseKey = clauseNode.getKey();

      const workflow = createWorkflow(
        isPrivate ? "ithread" : "pthread",
        prefix,
        quote,
        response,
        state.user,
        null,
        [],
        state.drawerVersions.active._id,
        partyID,
        [state.user.orgID]
      );

      const createWorkflowResponse = await axios.post(
        state.settings.api + "workflow",
        {
          workflow,
        }
      );
      if (!createWorkflowResponse) throw new Error("Error creating workflow.");

      const createdWorkflow = createWorkflowResponse.data.data;

      editor.dispatchCommand(ADD_WORKFLOW_COMMAND, {
        key: clauseKey,
        wfid: workflow.lid,
      });
      dispatch({ type: "ADD_WORKFLOW", payload: createdWorkflow });

      // setTimeout(() => {
      //   dispatch({
      //     type: "NEW_OPEN_ISSUE_SELECTION",
      //     payload: {
      //       id: `${clauseKey}_${newWF.lid}`,
      //       type: "navigation",
      //       status: "completed",
      //     },
      //   });
      // }, 50);

      // Send emails to recipients.
      // const legalNames = state.parties.map(
      //   (/** @type {{ legalName: string; }} */ p) => p.legalName
      // );
      // const partyFullString =
      //   state.parties.length === 2
      //     ? legalNames.join(" and ")
      //     : legalNames.join(", ");
      // const /** @type {*[]} */ emailRecipients = [];
      // emailRecipients.forEach((/** @type { * } */ recipient) => {
      //   axios.post(state.settings.api + "mail/informcomm", {
      //     doc: recipient.doc,
      //     whiteLabel: recipient.isTemplating ? null : null, // TODO: Add white label.
      //     partyFullString: partyFullString,
      //     recipient: recipient.recipient,
      //     isPublic: !recipient.isInternal,
      //     isTemplating: recipient.isTemplating,
      //     isApproval: ["approval"].includes(workflow.wfType),
      //     comment: recipient.content,
      //     clauseHTML: recipient.clauseHTML,
      //     wfid: createdWorkflow._id,
      //     lid: createdWorkflow.lid,
      //   });
      // });

      // TODO (TEMPRORARILY DISABLED)
      // if (displayExplainThisChangeInAPublicComment) {
      //   setDisplayExplainThisChangeInAPublicComment(false);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function assessRisk() {
    const clientParty = state.parties.find(
      (/** @type {{ myClient: boolean; }} */ x) => x.myClient
    );

    const party =
      clientParty ||
      state.parties.find(
        (/** @type {{ partyID: string; }} */ party) => party.partyID === partyId
      );

    if (!party) {
      throw new Error("Party does not exist.");
    }
    const clause = getSelectedOpenIssueClause();
    const role = party.role;
    // VeoBot prompt.
    let message;
    if (clientParty) {
      message = `Assess the risk that the following clause has for my client as the ${role}. Be as concise as possible. Only go above 40 words if necessary. Assess the clause from the perspective of my client as the ${role}, and start your answer with "For your client as the ${role}, ...". Here is the clause: ${clause.textContent}`;
    } else {
      message = `Assess the risk that the following clause has for me as the ${role}. Be as concise as possible. Only go above 40 words if necessary. Assess the clause from the perspective of me as the ${role}, and start your answer with "For you as the ${role}, ...". Here is the clause: ${clause.textContent}`;
    }

    await handleSendRequest(message, { displayMessageInChat: false });
  }

  function getEditorClauses() {
    const editorJson = editor.getEditorState().toJSON();
    const sections = editorJson.root.children;
    const clauses = [];
    for (const section of sections) {
      // @ts-ignore
      if (section?.children) {
        // @ts-ignore
        clauses.push(...section.children);
      }
    }

    return clauses;
  }

  function getSelectedOpenIssueClause() {
    const clauses = getEditorClauses();

    const clauseNode = currentOpenIssue.node;
    if (!$isClauseNode(clauseNode)) {
      throw new Error(
        "Current open issue node for clause types menu must be a clause node."
      );
    }

    const clauseJson = clauses.find(
      // @ts-ignore
      (clause) => clause.id === clauseNode.__id
    );
    if (!clauseJson) {
      throw new Error(
        "Current open issue node does not exist on the editor state."
      );
    }

    const clause = editor.getEditorState().read(() => {
      const clauseNode = currentOpenIssue.node;
      if (!$isClauseNode(clauseNode)) {
        throw new Error(
          "Current open issue node for clause types menu must be a clause node."
        );
      }

      let /** @type {import("../../nodes/ClauseNode").ClauseNode | undefined} */ latestClauseNode;

      const dfs = $dfs();
      for (const { node } of dfs) {
        if ($isClauseNode(node) && node.id === clauseNode.id) {
          latestClauseNode = node;
        }
      }

      if (!latestClauseNode) {
        throw new Error(
          "Current open issue node does not exist on the editor state."
        );
      }

      const clauseTextContent = latestClauseNode.getTextContent();

      return {
        node: clauseNode,
        clauseTextContent,
      };
    });

    return {
      node: clause.node,
      json: clauseJson,
      textContent: clause.clauseTextContent,
    };
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          aria-label="guidance tabs"
          variant="scrollable"
        >
          <Tab label="Topics" {...a11yProps(0)} />
          <Tab label="AI Review (Beta)" {...a11yProps(1)} />

          {/* <Tab label="Labels" {...a11yProps(2)} />
          <Tab label="Clause Library" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {
          // @ts-ignore
          currentOpenIssue.metadata?.clauseTypes[0] === "empty" ? (
            <>
              <br />
              <Grid container direction={"row"} justifyContent={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setDialogClauseOpen({
                      type: "assignclausetype",
                      activeCTs:
                        // @ts-ignore
                        currentOpenIssue.metadata?.clauseTypes,
                      activeClauseKey: currentOpenIssue.node.getKey(),
                      mode: "default",
                    });
                  }}
                >
                  Select Clause Topic ...
                </Button>
              </Grid>
              <br />
            </>
          ) : (
            <Grid
              container
              mt={1}
              p={2}
              sx={{
                width: "100%",
                maxHeight: "calc(100vh - 142px)",
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mb: "25px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Grid
                        xs={2}
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="rgba(98, 0, 238, 1)"
                          size="2x"
                          style={{
                            maxWidth: 20,
                            maxHeight: 20,
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          fontWeight={700}
                          color="rgba(98, 0, 238, 1)"
                        >
                          Topics
                        </Typography>
                      </Grid>

                      {state.drawerVersions?.active?._id ===
                        state.drawerVersions.versions[0]?._id && (
                        <Grid
                          xs={2}
                          item
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <IconButton
                            onClick={(e) => {
                              //@ts-ignore
                              setAnchorElClause(e.currentTarget);
                            }}
                            sx={{
                              maxWidth: 10,
                              maxHeight: 10,
                            }}
                            disableRipple
                          >
                            {/* three dot actions button */}
                            <FontAwesomeIcon
                              size="xs"
                              style={{
                                maxHeight: 14,
                                maxWidth: 14,
                              }}
                              icon={faEllipsisVertical}
                              color={theme.palette.grey[800]}
                            />
                          </IconButton>
                          <Menu
                            anchorEl={anchorElClause}
                            keepMounted
                            open={!!anchorElClause}
                            onClose={() => setAnchorElClause(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            {[
                              {
                                text: "Edit clause topics",
                                click: () => {
                                  setDialogClauseOpen({
                                    type: "assignclausetype",
                                    activeCTs:
                                      // @ts-ignore
                                      currentOpenIssue.metadata?.clauseTypes,
                                    activeClauseKey:
                                      currentOpenIssue.node.getKey(),
                                    mode: "default",
                                  });
                                },
                              },
                            ].map(({ text, click }, idx) => {
                              return (
                                <MenuItem
                                  key={`${text}-${idx}`}
                                  onClick={() => {
                                    click();
                                    setAnchorElClause(null);
                                  }}
                                >
                                  {text} ...
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid
                    xs={8}
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "25px",
                    }}
                  >
                    {/* list per clause type */}
                    {
                      // @ts-ignore
                      currentOpenIssue.metadata?.clauseTypes // @ts-ignore // @ts-ignore
                        .map((clt) => {
                          if (clt === "empty") return <></>;

                          const isSelected =
                            // @ts-ignore
                            currentOpenIssue.metadata?.clauseTypes.length ===
                              1 ||
                            // @ts-ignore
                            clauseTypesSelected.includes(clt);
                          /** @type {{name: string, id: string}} */
                          const clauseType = state.clauseTypes.find(
                            (/** @type {*} */ el) => el._id === clt
                          );
                          const clauseTypeGuide = state.clauseTypeGuides.find(
                            (/** @type {*} */ ctg) => ctg.ctid === clt
                          );
                          return (
                            <Box
                              key={clt}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#989898",
                                }}
                              >
                                Clause Topic
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Box
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {clauseType
                                    ? clauseType?.name?.at(0)?.toUpperCase() +
                                      clauseType?.name?.slice(1)
                                    : ""}
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "5px",
                                    ml: "auto",
                                    mr: 0,
                                  }}
                                >
                                  {clauseTypeGuide && (
                                    <IconButton
                                      sx={{
                                        maxWidth: 10,
                                        maxHeight: 10,
                                      }}
                                      disableRipple
                                      onClick={() => {
                                        if (isSelected) {
                                          setClauseTypesSelected(
                                            clauseTypesSelected.filter(
                                              (el) => el !== clt
                                            )
                                          );
                                        } else {
                                          setClauseTypesSelected([
                                            // @ts-ignore
                                            ...clauseTypesSelected,
                                            // @ts-ignore
                                            clt,
                                          ]);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        width={10}
                                        height={10}
                                        icon={faChevronDown}
                                        rotation={isSelected ? undefined : 270}
                                      />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                              {isSelected && clauseTypeGuide && (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      color: "#989898",
                                      mt: "15px",
                                      mb: "5px",
                                    }}
                                  >
                                    Guidance
                                  </Typography>
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      color: "rgba(91, 91, 91, 1)",
                                      maxHeight: "150px",
                                      overflow: "hidden",
                                      overflowY: "auto",
                                      pr: 3,
                                    }}
                                  >
                                    {clauseTypeGuide.guidance}
                                  </Box>
                                </>
                              )}
                            </Box>
                          );
                        })
                    }
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    p={1}
                    pb={0}
                    display="flex"
                    justifyContent="end"
                  >
                    {!isInEffect &&
                      state.drawerVersions?.active?._id ===
                        state.drawerVersions.versions[0]?._id && (
                        <Tooltip title="Fallback clauses">
                          <IconButton
                            sx={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                            }}
                            disableRipple
                            onClick={() => {
                              setDialogClauseOpen({
                                type: "insertclause",

                                activeCTs:
                                  // @ts-ignore
                                  currentOpenIssue.metadata?.clauseTypes,
                                activeClauseKey: currentOpenIssue.node.getKey(),
                                mode: "default",
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faRepeat}
                              color="rgba(98, 0, 238, 1)"
                              style={{
                                width: 18,
                                height: 18,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <br />

        {loading ? (
          <Grid container direction="column" alignItems="center">
            <Box sx={{ my: 2 }}>
              <CanveoCircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid container direction="row" justifyContent={"center"}>
              <>
                <div
                  style={{
                    height: "400px",
                  }}
                >
                  <MainContainer
                    style={{
                      border: "none",
                      padding: "5px",
                    }}
                  >
                    <ChatContainer>
                      <MessageList
                        scrollBehavior="auto"
                        autoScrollToBottom={true}
                        typingIndicator={
                          isTyping ? (
                            <TypingIndicator content="VeoBot is typing..." />
                          ) : null
                        }
                      >
                        {messages
                          .filter((message) => message.display)
                          .map((message, i) => {
                            return (
                              <Message key={i} model={message}>
                                <Message.Header
                                  sender={message.sender}
                                  sentTime={message.sentTime}
                                />
                              </Message>
                            );
                          })}
                      </MessageList>

                      <MessageInput
                        style={{ paddingTop: "20px" }}
                        attachButton={false}
                        placeholder="Ask a question"
                        onSend={(innerHtml) => handleSendRequest(innerHtml)}
                      />
                    </ChatContainer>
                  </MainContainer>
                </div>
              </>
            </Grid>

            <br />

            <Grid container direction="row" justifyContent="center">
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={suggestLowerRiskVersion}
              >
                Update clause to lower my risk
              </Button>
            </Grid>

            <br />

            <Grid container direction="row" justifyContent="center">
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={explainLastAction}
              >
                Explain your last action
              </Button>
            </Grid>

            {displayExplainThisChangeInAPublicComment && (
              <>
                <br />

                <Grid container direction="row" justifyContent="center">
                  <Button
                    variant="outlined"
                    size="small"
                    disableElevation
                    onClick={explainThisChangeInAPublicComment}
                  >
                    Explain this change in a comment
                  </Button>
                </Grid>
              </>
            )}

            <br />

            <Grid container direction="row" justifyContent="center">
              <Button
                variant="outlined"
                size="small"
                disableElevation
                onClick={assessRisk}
              >
                Assess risk of clause
              </Button>
            </Grid>
          </>
        )}

        <br />
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}

/**
 * @typedef {import("@chatscope/chat-ui-kit-react").MessageModel & { message: string; sender: "VeoBot" | "user"; role: "system" | "assistant" | "user"; display: boolean;}} ChatMessage
 */

/**
 * @typedef {object} TabPanelProps
 * @property {import("react").ReactNode} [children]
 * @property {number} index
 * @property {number} value
 */

/**
 *
 * @param {TabPanelProps} props
 * @returns {React.JSX.Element}
 */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
}

/**
 *
 * @param {number} index
 * @returns
 */
function a11yProps(index) {
  return {
    id: `guidance-tab-${index}`,
    "aria-controls": `guidance-tabpanel-${index}`,
  };
}
