import theme from "../../../../../theme/theme";

export const circleContainerStyle = {
  position: "relative",
};

export const circleStyle = {
  width: 110,
  height: 110,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

export const circleLabelStyle = {
  color: "white",
  fontWeight: "bold",
  fontFamily: "Roboto, sans-serif",
  fontSize: "14px",
  textAlign: "center",
};

export const actionNodeStyle = {
  padding: 10,
  paddingBottom: 20,
  background: "#fff",
  borderRadius: 8,
  boxShadow: "0 0 8px rgba(128,0,128,0.4)",
  minWidth: 120,
  fontFamily: "Roboto, sans-serif",
  cursor: "grab",
  position: "relative",
};

export const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0,0,0,0.5)",
  zIndex: 9999,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const modalContentStyle = {
  width: 300,
  background: "#fff",
  borderRadius: 4,
  padding: 20,
  boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
  fontFamily: "Roboto, sans-serif",
};
