import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, IconButton } from "@mui/material";
// @ts-ignore
import { Handle, Position } from "@xyflow/react";
import React from "react";
import theme from "../../../../../../theme/theme";
import { circleContainerStyle, circleLabelStyle, circleStyle } from "../styles";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export function StatusNode({ data }) {
  return (
    <Box sx={circleContainerStyle}>
      <Box sx={circleStyle}>
        <Box component="span" sx={circleLabelStyle}>
          {data.label}
        </Box>

        <Handle type="source" position={Position.Bottom} />

        <IconButton
          size="small"
          onClick={() => data.onAddAction(data.id)}
          style={{
            position: "absolute",
            right: "-40px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <AddCircleOutlineIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}
