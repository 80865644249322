import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import { defaultRequestor } from "../../reviewsAndApprovals/constants/defaultRequestor";
import { getAgreementData } from "../../reviewsAndApprovals/functions/getAgreementData";
import { getAvailableOrganizationUsers } from "../../reviewsAndApprovals/functions/getAvailableOrganizationUsers";
import AddApproverTab from "./tabs/AddApproverTab";
import ApprovalListTab from "./tabs/ApprovalListTab";

/**
 * @typedef {object} DialogCreateApprovalTicketProps
 * @property {boolean} open
 * @property {boolean} isTemplate
 * @property {(refreshApprovalTickets: boolean) => void} close
 * @property {(approvalList: ApprovalList, comment: string) => CreateApprovalTicket} createApprovalTicket
 * @property {string} [dialogTitle]
 * @property {boolean} [isDefault]
 * @property {ApprovalList} [approvalListTemplate]
 * @property {string} [commentLabel]
 * @property {boolean} [persistChanges]
 */

/**
 * @param {DialogCreateApprovalTicketProps} props
 * @returns {JSX.Element}
 */
export default function DialogCreateApprovalTicket({
  open,
  isTemplate,
  close,
  createApprovalTicket,
  dialogTitle = "Create Approval Request",
  isDefault,
  approvalListTemplate = [],
  commentLabel,
  persistChanges = true,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  /** @type {StateUser} */
  const currentUser = state.user;
  const currentRequestor = /** @type {const} */ ({
    type: "user",
    _id: currentUser._id,
    photoUrl: currentUser.photoURL,
    role: "requestor",
    displayName: currentUser.displayName,
    email: currentUser.email,
    title: currentUser.title,
  });
  /** @type {StateUser[]} */
  const organizationUsers = state.users;
  const [isLoading, setIsLoading] = useState(false);

  const [approvalList, setApprovalList] = useState(() => {
    if (approvalListTemplate.length > 0) {
      const [, ...rest] = approvalListTemplate;
      return [currentRequestor, ...rest];
    }

    /** @type {ApprovalList} */
    const defaultApprovalList = [];

    if (!isDefault) {
      defaultApprovalList.push(currentRequestor);
    } else {
      defaultApprovalList.push(defaultRequestor);
    }

    defaultApprovalList.push(
      { type: "caret" },
      { type: "addApprover" },
      { type: "caret" },
      { type: "check" }
    );

    return defaultApprovalList;
  });

  const approvalListUsers = /** @type {ReviewListUserItem[]} */ (
    approvalList.filter((approvalListItem) => approvalListItem.type === "user")
  );

  const [tab, setTab] = useState(
    /** @type {"approvalList" | "addApprover"} */ ("approvalList")
  );
  const [approvalListItemIndex, setApprovalListItemIndex] = useState(
    /** @type {number | null} */ (null)
  );

  const availableOrganizationUsers = getAvailableOrganizationUsers(
    state?.user?.role?.name,
    organizationUsers,
    approvalListUsers
  );

  const [comment, setComment] = useState(() => {
    const { firstCounterpartyLegalName, agreementTypeName } = getAgreementData(
      isTemplate,
      state
    );

    if (agreementTypeName && firstCounterpartyLegalName) {
      return `Please approve this ${agreementTypeName} for ${firstCounterpartyLegalName}.`;
    }

    if (agreementTypeName) {
      const sentenceStart = !isDefault
        ? "Please approve this"
        : "Please approve this";
      return `${sentenceStart} ${agreementTypeName}.`;
    }

    return "Please approve this approval request.";
  });

  /**
   * @param {StateUser} newApprover
   * @returns {void}
   */
  function addNewApprover(newApprover) {
    if (!approvalListItemIndex) return;

    setApprovalList((previous) => {
      const copy = [...previous];

      /** @type {ApprovalList} */
      const newItems = [
        { type: "addApprover" },
        {
          type: "user",
          _id: newApprover._id,
          photoUrl: newApprover.photoURL,
          role: "approver",
          displayName: newApprover.displayName,
          email: newApprover.email,
          title: newApprover.title,
          approvalStatus: "Pending",
        },
        { type: "addApprover" },
      ];

      copy.splice(approvalListItemIndex, 1, ...newItems);

      return copy.filter((x) => x.type !== "caret");
    });

    setTab("approvalList");
  }

  /**
   * @param {string} comment
   * @returns {Promise<void>}
   */
  async function submitApprovalTicket(comment) {
    try {
      setIsLoading(true);

      const task = createApprovalTicket(approvalList, comment);

      if (persistChanges) {
        await axios.post(state.settings.api + "task", { task });
      }

      close(true);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to create the approval request, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={() => close(false)} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={() => close(false)}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>{dialogTitle}</DialogTitle>

      {isLoading ? (
        <DialogContent sx={{ paddingX: 10 }}>
          <Grid container justifyContent="center">
            <CanveoCircularProgress />
          </Grid>
        </DialogContent>
      ) : (
        <>
          {tab === "approvalList" && (
            <ApprovalListTab
              approvalList={approvalList}
              handleAddNewApprover={(approvalListItemIndex) => {
                setApprovalListItemIndex(approvalListItemIndex);
                setTab("addApprover");
              }}
              handleRemoveApprover={(approvalListItemIndex) => {
                setApprovalList((previous) => {
                  const copy = [...previous];
                  copy.splice(approvalListItemIndex, 2);
                  return copy;
                });
              }}
              handleCancel={() => close(false)}
              handleSubmit={submitApprovalTicket}
              comment={comment}
              setComment={setComment}
              isDefault={isDefault}
              commentLabel={commentLabel}
            />
          )}

          {tab === "addApprover" && (
            <AddApproverTab
              organizationUsers={availableOrganizationUsers}
              handleBack={() => setTab("approvalList")}
              handleNext={addNewApprover}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
