import axios from "axios";
import { useContext, useState } from "react";
import { globalStore } from "../state/store";
import { getExhibit } from "../utils/agreementCreation";
import { compressData } from "../utils/compressData";
import { decompressData } from "../utils/decompressData";

export default function useVersionService() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [loading] = useState(false);

  /**
   * @param {*} exhibitData
   * @param {*} isTemplate
   * @returns {Promise<*>}
   */
  async function createExhibit(exhibitData, isTemplate) {
    const creationDate = new Date().toISOString();

    // The _id of the original document from whence the exhibit is coming from.
    const originalExhibitDocumentId = exhibitData._id || "";

    const exhibit = getExhibit(
      exhibitData,
      creationDate,
      state.user._id,
      state.org._id
    );

    if (isTemplate) {
      // @ts-ignore
      exhibit.orgID = exhibit.createdByOrg;
    }

    const payload = isTemplate
      ? { originalExhibitDocumentId, template: exhibit }
      : {
          payload: await compressData({
            originalExhibitDocumentId,
            agr: exhibit,
          }),
        };

    const result = await axios.post(
      `${state.settings.api}${isTemplate ? "template" : "agr"}`,
      payload
    );

    if (result.data.success) {
      dispatch({
        type: isTemplate ? "ADD_TEMPLATE" : "ADD_AGRS",
        payload: isTemplate
          ? result.data.data
          : (await decompressData(result.data.data)).agr,
      });
      if (!isTemplate) {
        dispatch({
          type: "ADD_AVS",
          payload: result.data.data.version,
        });
      }
    } else {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while creating the exhibit",
          severity: "error",
        },
      });
    }

    return result.data.success;
  }

  /**
   * @param {*} exhibitID
   * @param {*} isTemplate
   */
  async function deleteExhibit(exhibitID, isTemplate) {
    const result = await axios.delete(
      `${state.settings.api}${isTemplate ? "template" : "agr"}/${exhibitID}`
    );

    if (result.data.success) {
      dispatch({
        type: isTemplate ? "DELETE_TEMPLATE" : "DELETE_AGRS",
        payload: exhibitID,
      });
    } else {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while deleting the exhibit",
          severity: "error",
        },
      });
    }

    return result.data.success;
  }

  /**
   * @param {*} exhibit
   * @param {*} isTemplate
   */
  async function updateExhibit(exhibit, isTemplate) {
    try {
      const result = await axios.put(
        `${state.settings.api}${isTemplate ? "template" : "agr"}/${
          exhibit._id
        }`,
        isTemplate ? { template: exhibit } : { agr: exhibit }
      );

      if (result.data.success) {
        dispatch({
          type: isTemplate ? "UPDATE_TEMPLATE" : "UPDATE_AGRS",
          payload: result.data.data,
        });

        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "The title was succesfully updated",
            severity: "success",
          },
        });
      } else {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while updating the title",
            severity: "error",
          },
        });
      }

      return result.data.success;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while updating the title",
          severity: "error",
        },
      });
    }
  }

  return {
    loading,
    createExhibit,
    deleteExhibit,
    updateExhibit,
  };
}
