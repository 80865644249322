import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../../../../state/store";
import { WorkflowDefaultApprovalsTab } from "../components/WorkflowDefaultApprovalsTab";
import { WorkflowDefaultReviewsTab } from "../components/WorkflowDefaultReviewsTab";

/**
 * @typedef {typeof actions[number]} WorkflowAction
 */

const actions = [
  {
    _id: 0,
    label: "Review Task",
    description:
      "This action allows you to set up template review requests in the next step",
  },
  {
    _id: 1,
    label: "Approval Request",
    description:
      "This action allows you to set up template approval requests in the next step",
  },
  {
    _id: 2,
    label: "AI Analysis",
    description:
      "This action will automatically review the agreement and provide you with an updated version.",
  },
  // {
  //   _id: 3,
  //   label: "Update Agreement Status",
  //   description: "This action allows you to set up template approval requests in the next step"
  // },
];

/**
 * @typedef {object} DialogAddActionProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(action: *) => void} submit
 */

/**
 * @param {DialogAddActionProps} props
 * @returns {JSX.Element}
 */
export default function DialogAddAction({ open, close, submit }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [action, setAction] = useState(
    /** @type {WorkflowAction | null} */ (null)
  );
  const [
    extractMetadata,
    // setExtractMetadata
  ] = useState(true);
  const [fillInCustomProperties, setFillInCustomProperties] = useState(false);
  const [doComplianceAnalysis, setDoComplianceAnalysis] = useState(false);
  const [approvalTickets, setApprovalTickets] = useState(
    /** @type {ApprovalTicket[]}  */ ([])
  );
  const [reviewTickets, setReviewTickets] = useState(
    /** @type {ReviewTicket[]}  */ ([])
  );
  useEffect(() => {
    if (doComplianceAnalysis) {
      setFillInCustomProperties(true);
    }
  }, [doComplianceAnalysis]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Add Action</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container direction="column" my={4}>
          <Grid item mb={4}>
            <Autocomplete
              options={actions}
              getOptionLabel={(option) => option.label}
              value={action}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Action Type"
                  placeholder="Select Action Type ..."
                />
              )}
              onChange={(_event, value) => setAction(value)}
            />
          </Grid>

          {action && (
            <>
              {/* <Grid item my={4}>
                <Alert icon={<></>} severity="info">
                  <Typography fontWeight="bold" color="black">
                    {action.description}
                  </Typography>
                </Alert>
              </Grid> */}

              {action.label === "Review Task" && (
                <WorkflowDefaultReviewsTab
                  template={{
                    _id: "dialog-add-action",
                    agrTitle: "agrTitle",
                    active: true,
                    orgID: state.user.orgID,
                    versionId: "",
                  }}
                  reviewTickets={reviewTickets}
                  updateReviewTickets={(reviewTickets) => {
                    console.log(reviewTickets);
                  }}
                  createReviewTicket={(reviewTicket) => {
                    console.info(reviewTicket);
                    setReviewTickets((previous) => [...previous, reviewTicket]);
                  }}
                />
              )}

              {action.label === "Approval Request" && (
                <WorkflowDefaultApprovalsTab
                  template={{
                    _id: "dialog-add-action",
                    agrTitle: "agrTitle",
                    active: true,
                    orgID: state.user.orgID,
                    versionId: "",
                  }}
                  approvalTickets={approvalTickets}
                  updateApprovalTickets={(approvalTickets) => {
                    console.log(approvalTickets);
                  }}
                  createApprovalTicket={(approvalTicket) => {
                    console.info(approvalTicket);
                    setApprovalTickets((previous) => [
                      ...previous,
                      approvalTicket,
                    ]);
                  }}
                />
              )}

              {action.label === "AI Analysis" && (
                <>
                  {/* <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <Checkbox
                        checked={extractMetadata}
                        disabled={complianceAnalysis}
                        onChange={(_event, checked) =>
                          setExtractMetadata(checked)
                        }
                      />
                    </Grid>

                    <Grid item>
                      <Grid item>
                        <Typography fontWeight="bold">
                          Extract Basic Information
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center">
                    <Grid item xs={1} />

                    <Grid item>
                      <Grid item>Extract ...Parties, Agr type, dates</Grid>
                    </Grid>
                  </Grid> */}

                  <Grid container justifyContent="center" mt={4}>
                    <Typography variant="h4">Add AI Analysis</Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                  >
                    <Grid item xs={1} />

                    <Grid item xs={12} mb={4}>
                      <Typography textAlign="center">
                        Select the appropriate level of AI analysis, from
                        metadata extraction to full compliance and risk review
                      </Typography>
                    </Grid>

                    <Grid item xs={1} />
                    <Grid container alignItems="center">
                      <Grid item xs={1}>
                        <Checkbox
                          checked={fillInCustomProperties}
                          disabled={doComplianceAnalysis}
                          onChange={(_event, checked) =>
                            setFillInCustomProperties(checked)
                          }
                        />
                      </Grid>

                      <Grid item>
                        <Grid item>
                          <Typography fontWeight="bold">
                            Auto-fill Tracked Properties
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                      <Grid item xs={1} />

                      <Grid item>
                        <Grid item>
                          Automatically populate Agreement Properties that you
                          track for this agreement type.
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center" mt={4}>
                      <Grid item xs={1}>
                        <Checkbox
                          checked={doComplianceAnalysis}
                          onChange={(_event, checked) =>
                            setDoComplianceAnalysis(checked)
                          }
                        />
                      </Grid>

                      <Grid item>
                        <Grid item>
                          <Typography fontWeight="bold">
                            Assess for Risk and Compliance
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center">
                      <Grid item xs={1} />

                      <Grid item>
                        <Grid item>
                          Run a full risk and compliance analysis, including
                          comments and markup
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Back</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => {
            if (!action) return;

            submit({
              ...action,
              approvalTickets,
              reviewTickets,
              extractMetadata,
              fillInCustomProperties,
              doComplianceAnalysis,
            });
          }}
          disabled={!action}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
