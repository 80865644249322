import {
  faFolderOpen,
  faRectangleHistoryCirclePlus,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { useGoogleDriveFolderPicker } from "../../hooks/useGoogleDriveFolderPicker";
import { globalStore } from "../../state/store";
import { getCanveoTier } from "../../utils/getCanveoTier";
import StyledFormHelperText from "../styledComponents/StyledFormHelperText";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
const DialogIntegrationSettings = ({
  open,
  currentIntegration,
  closeDialog,
  pushOrg,
}) => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const canveoTier = getCanveoTier(state?.user?.email);

  const [loading, setLoading] = useState(false);
  const [gdrivePickerLoaded, setGdrivePickerLoaded] = useState(false);

  const close = useCallback(
    (/** @type {*} */ action, /** @type {*} */ message) => {
      closeDialog(action, message);
    },
    [closeDialog]
  );

  const { handleOpenPicker } = useGoogleDriveFolderPicker({
    setLoading,
    gdrivePickerLoaded,
    setGdrivePickerLoaded,
    pushOrg,
  });

  const handleAutoSync = useCallback(
    (value) => {
      let newIntegration = {};
      //TODO(R): not sure if should be working with this here
      newIntegration.autoSync = value;
      if (value === false) {
        newIntegration.folderId = null;
        newIntegration.folderName = null;
      }
      const newOrg = state.org;
      newOrg.integrations.gdrive = {
        ...newOrg.integrations.gdrive,
        ...newIntegration,
      };
      pushOrg(newOrg);
    },
    [state.org, pushOrg]
  );

  const syncPreExistingContracts = () => {
    const orgID = state.org._id;
    setLoading(true);
    axios
      .get(`${state.settings.api}org/${orgID}/agreements/sync`)
      .then(({ data }) => {
        setLoading(false);
        closeDialog();
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: data.data,
            severity: "success",
          },
        });
      })
      .catch((err) => {
        closeDialog();
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: err.response.data.message,
            severity: "error",
          },
        });
      });
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ zIndex: gdrivePickerLoaded ? 1000 : 1300 }}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>
      <DialogTitle>
        {loading
          ? "Loading ..."
          : `Integration with ${currentIntegration.name}`}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            py: 3,
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {currentIntegration.id === "gdrive" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "flex-start" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={!!state.org?.integrations?.gdrive?.autoSync}
                    onChange={(e) => handleAutoSync(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  ></Switch>
                }
              ></FormControlLabel>
              <Box>
                <span style={{ fontWeight: "400" }}>Auto-sync</span>
                <StyledFormHelperText m={0} color="black">
                  Automatically syncs the PDFs of all signed contracts to a
                  designated folder within Google Drive <b>(going forward)</b>
                </StyledFormHelperText>
                {!!state.org?.integrations?.gdrive.autoSync && (
                  <Button
                    variant={
                      state.org?.integrations?.gdrive?.folderName
                        ? "contained"
                        : "outlined"
                    }
                    size="small"
                    sx={{ mt: 1, height: "min-content" }}
                    disabled={gdrivePickerLoaded}
                    onClick={handleOpenPicker}
                  >
                    <FontAwesomeIcon icon={faFolderOpen} />
                    &nbsp;&nbsp;
                    {state.org?.integrations?.gdrive?.folderName
                      ? state.org?.integrations?.gdrive?.folderName
                      : "Select Designated Folder ..."}
                  </Button>
                )}

                {!!state.org?.integrations?.gdrive.autoSync &&
                  canveoTier === "experimental" && (
                    <>
                      <StyledFormHelperText color="black">
                        To also sync all <b>existing</b> signed contracts to the
                        designated folder, click the button below. Before doing
                        so, we recommend selecting a new, empty folder as the
                        designated folder, otherwise you may end up creating
                        duplicates.
                      </StyledFormHelperText>
                      <Box sx={{ position: "relative" }}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ minWidth: 150, mt: 1, height: "min-content" }}
                          disabled={gdrivePickerLoaded || loading}
                          onClick={syncPreExistingContracts}
                        >
                          <FontAwesomeIcon
                            icon={faRectangleHistoryCirclePlus}
                          />
                          &nbsp;&nbsp;Sync historic contracts now
                        </Button>
                        {loading && (
                          <CircularProgress
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                            size={24}
                          />
                        )}
                      </Box>
                    </>
                  )}
              </Box>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogIntegrationSettings;
