import axios from "axios";
import { useContext } from "react";
import useDrivePicker from "react-google-drive-picker";
import { globalStore } from "../state/store";

/**
 * @typedef {object} useGoogleDriveFolderPickerProps
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setLoading
 * @property {boolean} gdrivePickerLoaded
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setGdrivePickerLoaded
 * @property {(newOrg: *) => void} pushOrg
 */

/**
 * @param {useGoogleDriveFolderPickerProps} props
 */
export function useGoogleDriveFolderPicker({
  setLoading,
  gdrivePickerLoaded,
  setGdrivePickerLoaded,
  pushOrg,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [openPicker] = useDrivePicker();
  async function handleOpenPicker() {
    if (!state.org?.integrations?.gdrive || gdrivePickerLoaded) return;

    const response = await axios.get(
      `${state.settings.api}gdrive/oAuthMetadata`
    );
    const data = response.data.data;

    setLoading(true);
    openPicker({
      clientId: data.clientId,
      developerKey: data.apiKey,
      viewId: "FOLDERS",
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: false,
      multiselect: false,
      customScopes: [data.scopes],
      setSelectFolderEnabled: true,
      // customViews: customViewsArray, // Custom view.
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          setGdrivePickerLoaded(false);
        }

        if (data.action === "picked") {
          setGdrivePickerLoaded(false);

          const newIntegration = {};
          // TODO: Not sure if should be working with this here.
          newIntegration.folderName = data.docs[0].name;
          newIntegration.folderId = data.docs[0].id;
          const newOrg = state.org;
          newOrg.integrations.gdrive = {
            ...newOrg.integrations.gdrive,
            ...newIntegration,
          };

          pushOrg(newOrg);
        }

        if (data.action === "loaded") {
          setGdrivePickerLoaded(true);
        }

        setLoading(false);
      },
    });
  }

  return {
    handleOpenPicker,
  };
}
