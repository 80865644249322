import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import MergeFieldMenu from "../MergeFieldMenu";

/**
 * @typedef {object} DialogAddNewQuestionToQuestionnaireProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(question: *) => void} submit
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {boolean} documentIsTemplate
 * @property {string} partyId
 * @property {string} agrvId
 * @property {import("../editor/nodes/MarkNode").MergeField} [mergeField]
 * @property {string} [agreementTypeId]
 * @property {string[]} [agreementLabelsIds]
 * @property {boolean} [allowSaveAsATemplate]
 * @property {boolean} [allowSelectingLabels]
 * @property {boolean} [allowSelectingAgreementTypes]
 * @property {string} [mergeFieldTemplateId]
 */

/**
 * @param {DialogAddNewQuestionToQuestionnaireProps} props
 */
export default function DialogAddNewQuestionToQuestionnaire({
  open,
  close,
  submit,
  organizationId,
  organizationName,
  documentIsTemplate,
  partyId,
  agrvId,
  mergeField,
  agreementTypeId = "",
  agreementLabelsIds = [],
  allowSaveAsATemplate = true,
  allowSelectingLabels = true,
  allowSelectingAgreementTypes = true,
  mergeFieldTemplateId = "",
}) {
  const [name] = useState("");
  const [wizardQuestion] = useState("");
  const [wizardGuidance] = useState("");

  const handleClose = () => close();

  const handleSubmit = () => {
    submit({ name, wizardQuestion, wizardGuidance });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {mergeField ? "Edit Agreement Property" : "Add Agreement Property"}
      </DialogTitle>

      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogContent id="list-options-dialog-content">
        <MergeFieldMenu
          open={true}
          isQuestionnaire={true}
          organizationId={organizationId}
          organizationName={organizationName}
          mergeFieldText=""
          documentIsTemplate={documentIsTemplate}
          partyId={partyId}
          docId={agrvId}
          handleSubmit={handleSubmit}
          handleUpdate={handleClose}
          handleClose={handleClose}
          existingMergeField={mergeField}
          agreementTypeId={agreementTypeId}
          agreementLabelsIds={agreementLabelsIds}
          allowSaveAsATemplate={allowSaveAsATemplate}
          allowSelectingLabels={allowSelectingLabels}
          allowSelectingAgreementTypes={allowSelectingAgreementTypes}
          mergeFieldTemplateId={mergeFieldTemplateId}
        />
      </DialogContent>
    </Dialog>
  );
}
