import { faCirclePlus, faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CanveoCircularProgress,
  ClauseLibraryList,
  DialogClause,
  DialogTemplate,
  Editor,
  FabStandard,
  Header,
  SelectLabels,
  ThumbAgr,
} from "../components";
import CoreDrawer from "../components/drawer/CoreDrawer";
import { getLegalDrawerItems } from "../components/drawer/getDrawerItems";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { trunc } from "../utils";
import { getCanveoTier } from "../utils/getCanveoTier";
import { ManagePlaybooks } from "./ManagePlaybooks";
import Labels from "./legal/Labels";
import Policy from "./legal/Policy";
import { Properties } from "./legal/Properties";
import { Teams } from "./legal/Teams";
import { Topics } from "./legal/Topics";
import { Workflows } from "./legal/Workflows";

export default function Templates() {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const pageSizeTemplates = 8;

  const navigate = useNavigate();
  const tid = useParams()?.tid || "";

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [filters, setFilters] = useState({
    agrTypeID: "",
    reference: "",
    labels: [],
  });
  const [filteredTemplates, setFilteredTemplates] = useState(
    /** @type {*[]} */ ([])
  );

  const [curPageTemplates, setCurPageTemplates] = useState(1);
  const [dialogTemplateOpen, setDialogTemplateOpen] = useState(false);
  const [dialogDetails, setDialogDetails] = useState(
    /** @type {* | null} */ (null)
  );
  const [dialogClauseOpen, setDialogClauseOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(/** @type {* | null} */ (null));
  const [drawerItems, setDrawerItems] = useState(/** @type {*[]} */ ([]));

  useEffect(() => {
    const navigateAction = (/** @type {string} */ target) => navigate(target);

    const drawerItems = getLegalDrawerItems(
      tid,
      navigateAction,
      state?.user?.email,
      state
    );

    setDrawerItems(drawerItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  useEffect(() => {
    // Update the filteredTemplates array upon change of filters or Templates in state
    const ft = state.templates
      .filter((/** @type {{ deleted: any; }} */ t) => !t.deleted) // Filters out any recently deleted template
      //Commenting this code as i don't understand it and it's filtering out some templates, which shouldn't
      // .filter(
      //   (t) =>
      //     !state.templates.some(
      //       (temp) =>
      //         temp._id !== t._id &&
      //         temp.blueprintID === t.blueprintID &&
      //         (temp.active || (!t.active && temp.version > t.version))
      //     )
      // )
      .filter(
        (/** @type {{ agrTypeID: string; }} */ t) =>
          filters.agrTypeID === "" || t.agrTypeID === filters.agrTypeID
      ) // FILTER: agr types
      .filter(
        (/** @type {{ reference: string; }} */ t) =>
          filters.reference !== undefined &&
          t.reference.toLowerCase().includes(filters.reference.toLowerCase())
      ) // FILTER: reference
      .filter(
        (/** @type {{ labelIDs: string | never[]; }} */ t) =>
          filters.labels.length === 0 ||
          filters.labels.every((fl) => t.labelIDs.includes(fl))
      ) // FILTER: labels
      .sort(
        (
          /** @type {{ agrTitle: number; }} */ a,
          /** @type {{ agrTitle: number; }} */ b
        ) => (a.agrTitle > b.agrTitle ? 1 : -1)
      );

    setFilteredTemplates(ft);
    setCurPageTemplates(1);
  }, [state.templates, filters, tid]);

  useEffect(() => {
    // Pull the entire template (incl. content) when you're loading a new template page

    if (Boolean(tid) && tid.length > 10 && tid.length < 30) {
      setLoading(true);

      axios
        .get(state.settings.api + "template/" + tid)
        .then((resTemp) => {
          if (resTemp.data.success) {
            dispatch({
              type: "INIT_WORKFLOWS",
              payload: resTemp.data.data.workflows,
            });
            dispatch({
              type: "INIT_PARAMS_DOC",
              payload: resTemp.data.data.params,
            });
            dispatch({
              type: "INIT_TEMPLATE",
              payload: resTemp.data.data.templates,
            });
            const template = resTemp.data.data.templates.find(
              (/** @type {{ _id: string | undefined; }} */ t) => t._id === tid
            );
            setTemplate(template);
            setLoading(false);
          } else {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "Unable to open the template",
                severity: "error",
              },
            });
          }
        })
        .catch(() =>
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Unable to open the template",
              severity: "error",
            },
          })
        )
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  const handleLabelSelect = (/** @type {any[]} */ newLabels) => {
    /**
     * @type {any[]}
     */
    let newLabs = [];
    newLabels.forEach((/** @type {{ _id: any; }} */ nl) => {
      newLabs.push(nl._id);
    });

    // @ts-ignore
    setFilters({ ...filters, labels: newLabs });
  };

  const closeTemplateDialog = (/** @type {string} */ action) => {
    if (["snackTemplateCreated"].includes(action)) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: "The template was created", severity: "success" },
      });
    }
    setDialogTemplateOpen(false);
  };

  /**
   * Close the ClauseType dialog
   * @param {Object | string | null} action
   */
  const closeClauseTypeDialog = (action) => {
    const messages = {
      clauseTypeCreated: "Clause type created",
      clauseTypeUpdated: "Clause type updated",
      clauseTypeGuidanceCreated: "Clause type guidance created",
      clauseTypeGuidanceUpdated: "Clause type guidance updated",
      clauseTypeDeleted: "Clause type deleted",
      clauseLibUpdated: "Library clause updated",
      clauseLibDeleted: "Clause removed from library",
    };

    if (action && messages[action]) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: messages[action],
          severity: "success",
        },
      });
    }
    setDialogClauseOpen(false);
  };

  const handleEditClauseLibItem = (/** @type {*} */ item) => {
    setDialogDetails({ type: "editlibrary", clitem: item });
    setDialogClauseOpen(true);
  };

  const renderContent = () => {
    switch (tid) {
      case "main":
        return (
          <>
            <Grid container justifyContent="center">
              <Grid item display="flex" justifyContent="center" xs={8}>
                <Typography variant="h4">Manage Templates</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="center"
                xs={8}
                sx={{ m: 2 }}
              >
                <Typography variant="body1" textAlign="center">
                  Active templates will show as available when creating new
                  agreements
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center">
              <FabStandard
                click={() => setDialogTemplateOpen(true)}
                text="New"
                icon={faCirclePlus}
                sx={{
                  left: "100px",
                  top: "80px",
                  right: "unset",
                }}
              />
              <Grid item xs={12} style={{ width: "100%", maxWidth: "980px" }}>
                <Box sx={{ my: 6, width: "100%" }}>
                  {getCanveoTier(state?.user?.email) === "experimental" && (
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          id="filter-agrType"
                          options={state.agrTypes
                            .filter((at) => at.active)
                            .filter((at) =>
                              state.templates.some(
                                (t) => t.agrTypeID === at._id
                              )
                            )
                            .sort(
                              (
                                /** @type {{ fullName: number[]; }} */ a,
                                /** @type {{ fullName: number[]; }} */ b
                              ) => (a.fullName[0] > b.fullName[0] ? 1 : -1)
                            )}
                          onChange={(e, newVal) =>
                            setFilters({
                              ...filters,
                              agrTypeID: newVal?._id ?? "",
                            })
                          }
                          getOptionLabel={(option) =>
                            trunc(option.fullName[0], 35)
                          }
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agreement Type"
                              placeholder="Agreement Type ..."
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon icon={faFilter} />
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Reference"
                          placeholder="Reference ..."
                          value={filters.reference}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              reference: e.target.value,
                            })
                          }
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FontAwesomeIcon icon={faFilter} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SelectLabels
                          adornIcon={faFilter}
                          handleLabelSelect={handleLabelSelect}
                          selectedIDs={filters.labels}
                          options={state.labels
                            .filter((l) => l.active)
                            .sort(
                              (
                                /** @type {{ name: number; }} */ a,
                                /** @type {{ name: number; }} */ b
                              ) => (a.name > b.name ? 1 : -1)
                            )
                            .sort(
                              (
                                /** @type {{ type: { color: number; }; }} */ a,
                                /** @type {{ type: { color: number; }; }} */ b
                              ) => (a.type.color > b.type.color ? 1 : -1)
                            )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid item>
                {filteredTemplates.length > 0 ? (
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{
                      [theme.breakpoints.only("xs")]: { width: "340px" },
                      [theme.breakpoints.only("sm")]: { width: "410px" },
                      [theme.breakpoints.up("md")]: { width: "880px" },
                    }}
                  >
                    {filteredTemplates
                      .sort(
                        (previous, next) =>
                          new Date(next.lastUpdateDate).getTime() -
                          new Date(previous.lastUpdateDate).getTime()
                      )
                      .slice(
                        (curPageTemplates - 1) * pageSizeTemplates,
                        curPageTemplates * pageSizeTemplates
                      )
                      .map((t) => {
                        t.agrStatus = t.active ? "Active" : "Draft";
                        return (
                          <Grid item key={t._id} xs={6} md={3}>
                            <ThumbAgr
                              ag={t}
                              actionReq={!t.active}
                              thumbClick={() => navigate("/templates/" + t._id)}
                              showLogo={state.org.logoURL}
                              primaryLegalName={t.reference}
                              secondaryLegalName={"Version " + t.version}
                              isTemplate
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                ) : (
                  <Box sx={{ mx: 5, my: 5 }}>
                    <Typography align="center">No templates yet.</Typography>
                  </Box>
                )}
              </Grid>
              <Grid item sx={{ mt: 7 }}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(
                      filteredTemplates.length / pageSizeTemplates
                    )}
                    defaultPage={1}
                    page={curPageTemplates}
                    onChange={(e, newVal) => setCurPageTemplates(newVal)}
                    siblingCount={0}
                    boundaryCount={2}
                    renderItem={(item) => <PaginationItem {...item} />}
                  />
                </Stack>
              </Grid>
            </Grid>
          </>
        );
      case "library":
        return (
          <>
            <Grid container justifyContent="center">
              <Grid item display="flex" justifyContent="center" xs={8}>
                <Typography variant="h4">Manage Clause Library</Typography>
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="center"
                xs={8}
                sx={{ m: 2, mb: 5 }}
              >
                <Typography variant="body1" textAlign="center">
                  Clauses added to the clause library can be accessed and used
                  from any agreement
                </Typography>
              </Grid>
            </Grid>
            <ClauseLibraryList type="edit" onSelect={handleEditClauseLibItem} />
          </>
        );
      case "topics":
        return (
          <Topics
            setDialogClauseOpen={setDialogClauseOpen}
            setDialogDetails={setDialogDetails}
          />
        );
      case "properties":
        return <Properties />;
      case "labels":
        return <Labels />;
      case "policy":
        return <Policy />;
      case "playbook":
        return <ManagePlaybooks />;
      case "teams":
        return <Teams />;
      case "workflows":
        return <Workflows />;
      default:
        return null;
    }
  };

  return (
    <div>
      {loading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <CanveoCircularProgress />
        </Grid>
      ) : ![
          "main",
          "library",
          "topics",
          "properties",
          "labels",
          "playbook",
          "workflows",
        ].includes(tid) && template ? (
        <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
          <Editor page="Template" template={template} />
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", width: "100%", margin: "0px", padding: "0px" }}
        >
          <Header page="Templates" />
          <CoreDrawer drawerItems={drawerItems} />
          <Box sx={{ my: 17, mx: isSmUp ? 3 : 2, width: "100%" }}>
            {renderContent()}
          </Box>
        </Box>
      )}

      <DialogTemplate
        open={dialogTemplateOpen}
        closeDialog={closeTemplateDialog}
      />
      <DialogClause
        open={dialogClauseOpen}
        details={dialogDetails}
        close={closeClauseTypeDialog}
      />
    </div>
  );
}
