import { faTimes, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import theme from "../../theme/theme";

/**
 * @typedef {object} DialogCancelSignatureProcessProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {() => void} confirm
 */

/**
 * @param {DialogCancelSignatureProcessProps} props
 * @returns {JSX.Element}
 */
export default function DialogCancelSignatureProcess({ open, close, confirm }) {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Cancel Signature Process</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container direction="column" alignItems="center">
          <Grid item mt={4}>
            <FontAwesomeIcon
              icon={faWarning}
              fontSize="40px"
              color={theme.palette.primary.main}
            />
          </Grid>

          <Grid item mt={4}>
            <Typography variant="subtitle1">
              Are you sure you want to cancel the signature process?
            </Typography>
          </Grid>

          <Grid item mt={4}>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Any signatures already collected will be lost,
              <br /> but you can start afresh.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "space-between", pb: 2, pl: 2, pr: 2 }}
      >
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={confirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
