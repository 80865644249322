/**
 * @param {*} agreement
 */
export function getOrigin(agreement) {
  const originType = agreement?.uploadType?.toUpperCase() ?? "TEMPLATE";

  if (originType === "TEMPLATE") {
    return {
      template: `${agreement.agrTypeID?.shortName} (${agreement.reference})`,
    };
  }

  if (originType === "IMPORT") {
    return {
      import: `${agreement.fileName}#${agreement.file}`,
    };
  }

  if (originType === "SCRATCH") {
    return {
      scratch: "Blank page",
    };
  }

  throw new Error(`Invalid origin ${originType}`);
}

/**
 * @param {*} exhibitData
 * @param {*} creationDate
 * @param {*} userID
 * @param {*} orgID
 * @param {boolean} withId
 */
export function getExhibit(
  exhibitData,
  creationDate,
  userID,
  orgID,
  withId = false
) {
  const origin = getOrigin(exhibitData);
  return {
    _id: withId ? exhibitData._id : undefined,
    parentID: exhibitData.parentID,
    versionType: ["pdf", "docx", "xlsx", "pptx"].includes(exhibitData.fileType) // AgreementVersion related
      ? exhibitData.fileType // This is an attachment
      : "canveo", // This is a Canveo Doc
    content: exhibitData.content,
    contentMetadata: exhibitData.contentMetadata,
    agrTypeID: exhibitData.agrTypeID,
    agrTitle: exhibitData.agrTitle,
    agrStatus: "Exhibit",
    orgs: exhibitData.organizations,
    ents: exhibitData.entities,
    collabs: [],
    signers: [],
    priority: exhibitData.priority,
    amendment: null,
    effectiveDate: null,
    renewalType: "n/a",
    effectiveTerm: null,
    expiryDate: null,
    sigConfig: null,
    customURL: null,
    whiteLabel: null,
    avOwners: [orgID],
    createdByOrg: orgID,
    owner: orgID,
    creationBy: userID,
    creationDate: creationDate,
    lastUpdateBy: userID,
    lastUpdateDate: creationDate,
    origin: origin,
    description: exhibitData.description,
    sfdt: exhibitData.sfdt,
    firstPageHeader: exhibitData.firstPageHeader,
    firstPageFooter: exhibitData.firstPageFooter,
    visibleTo: [orgID],
    fileSource: exhibitData.fileSource?.value,
    embeddedFileId: ["pdf", "xlsx", "pptx"].includes(exhibitData.fileType)
      ? exhibitData.file
      : null,
  };
}

/**
 * @param {{ labels: string []; areaLabel: { orgID: string; _id: string; }}} agreementData
 * @param {*} state
 */
export function getLabels(agreementData, state) {
  const labels = agreementData.labels.map((label) => ({
    orgID: state.org._id,
    labelID: label,
  }));

  const areaLabels = [
    {
      orgID: agreementData.areaLabel.orgID,
      labelID: agreementData.areaLabel._id,
    },
  ];

  return { labels, areaLabels };
}

/**
 * @typedef {{ uid: any; partyID: string; entityName: any; email: any; order: null; }} Signer
 */

/**
 * @param {*} agreementData
 * @param {*} state
 * @param {boolean} isBackload
 */
export function extractPartyInfo(agreementData, state, isBackload) {
  const organizations = [state.org._id];
  const entities = [];
  /** @type {Signer[]} */
  const signers = [];
  const collaborators = [];

  let parties = agreementData.parties || [];
  // If no parties were set during agreement creation and organization has configured a default entity, we use
  // that as the entity for the ghost party.
  if (!agreementData?.parties?.length) {
    const defaultLegalEntity = state.subs.find(
      (/** @type {{ defaultLegalEntity: boolean; }} */ x) =>
        x.defaultLegalEntity
    );
    if (defaultLegalEntity) {
      parties = [
        {
          ...defaultLegalEntity,
          partyID: "party0",
          role: null,
        },
      ];
    }
  }

  // Loop through the agreement parties to enrich the respective arrays with the necessary data.
  for (const party of parties) {
    organizations.push(party.orgID);
    entities.push({
      entID: party._id,
      role: party.role,
      partyID: party.partyID,
      myClient: party.myClient,
    });

    // @ts-ignore
    if (!!party.pers && !isBackload) {
      // Add any person automatically as a collaborator and signer.
      collaborators.push({
        // @ts-ignore
        uid: party.pers._id,
        // @ts-ignore
        email: party.pers.email,
        // @ts-ignore
        orgID: party.pers.orgID,
        partyID: party.partyID,
      });
      signers.push({
        // @ts-ignore
        uid: party.pers._id,
        partyID: party.partyID,
        // @ts-ignore
        entityName: party.ent.legalName,
        // @ts-ignore
        email: party.pers.email,
        order: null,
      });
    }

    if (party?.defaultSigners?.length) {
      const partySigners = party.defaultSigners.map(
        (/** @type {{ _id: string; email: string; }} */ signer) => ({
          uid: signer._id,
          partyID: party.partyID,
          entityName: party.legalName,
          email: signer.email,
          order: null,
        })
      );
      signers.push(...partySigners);
    }
  }

  if (
    !parties.find(
      (/** @type {{ orgID: string; }} */ p) => p.orgID === state.org._id
    )
  ) {
    entities.push({
      entID: state.subs[0]._id,
      partyID: "party0",
    });
  }

  collaborators.push({
    uid: state.user._id,
    email: state.user.email,
    orgID: state.user.orgID,
    partyID: "party0",
  });

  // Remove duplicated organization IDs (specific case where our current organization is also a party).
  // @ts-ignore
  const uniqueOrganizations = [...new Set(organizations)];

  return {
    organizations: uniqueOrganizations,
    entities,
    signers,
    collaborators,
  };
}
