import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import EditReviewerTab from "./tabs/EditReviewerTab";
import ReviewTicketDetailsTab from "./tabs/ReviewTicketDetailsTab";

/**
 * @typedef {object} DialogReviewTicketDetailsProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {boolean} [displayOnlyDialogContent]
 * @property {{ ents: { entID: string, partyID: string }[], agrTitle: string, _id: string }} agreement
 * @property {ReviewTicket} reviewTicket
 * @property {(reviewTicket: ReviewTicket) => void} updateReviewTicket
 * @property {(reviewTicketId: string) => void} deleteReviewTicket
 * @property {boolean} [isTemplate]
 */

/**
 * @param {DialogReviewTicketDetailsProps} props
 * @returns {JSX.Element}
 */
export default function DialogReviewTicketDetails({
  open,
  close,
  displayOnlyDialogContent = false,
  agreement,
  reviewTicket,
  updateReviewTicket,
  deleteReviewTicket,
  isTemplate = false,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(
    /** @type {"ticketDetails" | "editReviewer"} */ ("ticketDetails")
  );

  useEffect(
    () => {
      if (!reviewTicket._id) return;

      loadReviewTicket(reviewTicket._id);
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * @param {string} reviewTicketId
   * @returns {Promise<ReviewTicket | undefined>}
   */
  async function loadReviewTicket(reviewTicketId) {
    try {
      setIsLoading(true);

      const getReviewTicketByIdResponse = await axios.get(
        `${state.settings.api}task/${reviewTicketId}`
      );
      /** @type {ReviewTicket} */
      const reviewTicket = getReviewTicketByIdResponse.data.data;

      updateReviewTicket(reviewTicket);

      return reviewTicket;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to load the review request, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (displayOnlyDialogContent) {
    return (
      <>
        {isLoading ? (
          <DialogContent sx={{ paddingX: 10 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <CanveoCircularProgress />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <>
            {tab === "ticketDetails" && (
              <ReviewTicketDetailsTab
                close={close}
                agreement={agreement}
                reviewTicket={reviewTicket}
                updateReviewTicket={updateReviewTicket}
                deleteReviewTicket={deleteReviewTicket}
                onEditReviewer={() => setTab("editReviewer")}
                isTemplate={isTemplate}
                messageListHeight="150px"
                hideBackButton
              />
            )}

            {tab === "editReviewer" && (
              <EditReviewerTab
                close={() => setTab("ticketDetails")}
                reviewTicket={reviewTicket}
                updateReviewTicket={(reviewTicket) => {
                  setTab("ticketDetails");
                  updateReviewTicket(reviewTicket);
                }}
              />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: "750px",
        },
      }}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        <Grid container direction="row" alignItems="center" gap={2}>
          Review Request Details
        </Grid>
      </DialogTitle>

      {isLoading ? (
        <DialogContent sx={{ paddingX: 10 }}>
          <Grid container justifyContent="center">
            <Grid item>
              <CanveoCircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <>
          {tab === "ticketDetails" && (
            <ReviewTicketDetailsTab
              close={close}
              agreement={agreement}
              reviewTicket={reviewTicket}
              updateReviewTicket={updateReviewTicket}
              deleteReviewTicket={deleteReviewTicket}
              onEditReviewer={() => setTab("editReviewer")}
              isTemplate={isTemplate}
            />
          )}

          {tab === "editReviewer" && (
            <EditReviewerTab
              close={() => setTab("ticketDetails")}
              reviewTicket={reviewTicket}
              updateReviewTicket={(reviewTicket) => {
                setTab("ticketDetails");
                updateReviewTicket(reviewTicket);
              }}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
