import { Box } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import DialogApprovalTicketDetails from "../../../../../../components/dialogs/DialogApprovalTicketDetails";
import DialogCreateApprovalTicket from "../../../../../../components/dialogs/DialogCreateApprovalTicket";
import ApprovalsTable from "../../../../../../components/reviewsAndApprovals/components/ApprovalsTable";
import { NewApprovalTicketButtonPanel } from "../../../../../../components/reviewsAndApprovals/components/NewApprovalTicketButtonPanel";
import { globalStore } from "../../../../../../state/store";

const title = "Add Approval Request";
const subtitle =
  "Approval Requests let you get approvals from internal stakeholders, such as senior business leaders";

/**
 * @typedef {object} WorkflowDefaultApprovalsTabProps
 * @property {DialogConfigureTemplateTemplate} template
 * @property {ApprovalTicket[]} approvalTickets
 
 * @property {(approvalTickets: ApprovalTicket[]) => void} updateApprovalTickets
 * @property {(approvalTicket: ApprovalTicket) => void} createApprovalTicket
 */

/**
 * @param {WorkflowDefaultApprovalsTabProps} props
 * @returns {JSX.Element}
 */
export function WorkflowDefaultApprovalsTab({
  template,
  approvalTickets,
  updateApprovalTickets,
  createApprovalTicket,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [openDialogCreateApprovalTicket, setOpenDialogCreateApprovalTicket] =
    useState(false);
  const [selectedApprovalTicketIndex, setSelectedApprovalTicketIndex] =
    useState(-1);

  const openDialogApprovalTicketDetails = selectedApprovalTicketIndex >= 0;

  /**
   * @param {ApprovalTicket} approvalTicket
   * @returns {void}
   */
  function handleUpdateApprovalTicket(approvalTicket) {
    const updatedApprovalTickets = [...approvalTickets];

    const index = updatedApprovalTickets.findIndex(
      (at) => at._id === approvalTicket._id
    );
    updatedApprovalTickets[index] = approvalTicket;

    updateApprovalTickets(updatedApprovalTickets);
  }

  /**
   * @param {string} approvalTicketId
   * @returns {void}
   */
  function handleDeleteApprovalTicket(approvalTicketId) {
    const updatedApprovalTickets = [...approvalTickets];

    const index = updatedApprovalTickets.findIndex(
      (rt) => rt._id === approvalTicketId
    );
    updatedApprovalTickets.splice(index, 1);

    updateApprovalTickets(updatedApprovalTickets);
  }

  return (
    <Box mt={4}>
      {approvalTickets.length === 0 && (
        <NewApprovalTicketButtonPanel
          title={title}
          subtitle={subtitle}
          setOpenDialogCreateApprovalTicket={setOpenDialogCreateApprovalTicket}
        />
      )}

      {approvalTickets.length === 1 && (
        <>
          <NewApprovalTicketButtonPanel
            title={title}
            subtitle={subtitle}
            buttonPosition="left"
            setOpenDialogCreateApprovalTicket={
              setOpenDialogCreateApprovalTicket
            }
          />

          <DialogApprovalTicketDetails
            open={openDialogApprovalTicketDetails}
            close={() => setSelectedApprovalTicketIndex(-1)}
            agreement={{
              ents: [],
              agrTitle: template.agrTitle,
              _id: template._id,
            }}
            approvalTicket={approvalTickets[0]}
            updateApprovalTicket={handleUpdateApprovalTicket}
            deleteApprovalTicket={handleDeleteApprovalTicket}
            displayOnlyDialogContent
            isTemplate
          />
        </>
      )}

      {approvalTickets.length > 1 && (
        <>
          <NewApprovalTicketButtonPanel
            title={title}
            subtitle={subtitle}
            buttonPosition="left"
            setOpenDialogCreateApprovalTicket={
              setOpenDialogCreateApprovalTicket
            }
          />

          <ApprovalsTable
            approvalTickets={approvalTickets}
            setSelectedApprovalTicketIndex={setSelectedApprovalTicketIndex}
          />

          {openDialogApprovalTicketDetails && (
            <DialogApprovalTicketDetails
              open={openDialogApprovalTicketDetails}
              close={() => setSelectedApprovalTicketIndex(-1)}
              agreement={{
                ents: [],
                agrTitle: template.agrTitle,
                _id: template._id,
              }}
              approvalTicket={approvalTickets[selectedApprovalTicketIndex]}
              updateApprovalTicket={handleUpdateApprovalTicket}
              deleteApprovalTicket={handleDeleteApprovalTicket}
              isTemplate
            />
          )}
        </>
      )}

      {openDialogCreateApprovalTicket && (
        <DialogCreateApprovalTicket
          isTemplate
          isDefault
          dialogTitle="Create Default Approval Flow"
          open={openDialogCreateApprovalTicket}
          persistChanges={false}
          close={(refreshApprovalTickets) => {
            setOpenDialogCreateApprovalTicket(false);
            // if (refreshApprovalTickets) {
            //   loadTemplateApprovalTickets(template._id);
            // }
          }}
          createApprovalTicket={(approvalList, comment) => {
            /** @type {CreateApprovalTicket} */
            const approvalTicket = {
              type: "Approval",
              status: "Pending Assignment",
              template: template._id,
              organization: state.user.orgID,
              requestor: state.user._id,
              isTemplateConfiguration: true,
              approvalChain: {
                approvalList,
                messages: comment
                  ? [
                      {
                        text: comment,
                        senderId: state.user._id,
                        senderDisplayName: state.user.displayName,
                        senderPhotoUrl: state.user.photoURL,
                        sentTime: new Date().toISOString(),
                      },
                    ]
                  : [],
                subscribers: [
                  {
                    _id: state.user._id,
                    email: state.user.email,
                    orgId: state.user.orgID,
                  },
                ],
              },
            };

            // @ts-ignore
            createApprovalTicket(approvalTicket);
            return approvalTicket;
          }}
          commentLabel="Optionally Add Default Approval Instructions ..."
        />
      )}
    </Box>
  );
}
