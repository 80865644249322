export const defaultSeparators = [
  { label: "               [space]" },
  { label: "(" },
  { label: ")" },
  { label: "-" },
  { label: "_" },
  { label: "|" },
  { label: "." },
  { label: "," },
  { label: "/               [new subfolder]" },
];

/**
 * If it is more than one counterparty, client, or organization entity:
 * we keep the approach where system just puts the first one from the
 * array and adds “et. al.” afterwards.
 */
export const defaultOptions = [
  // Agreement Info
  {
    label: "Name",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  {
    label: "Type",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  {
    label: "Area",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  {
    label: "Labels",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  {
    label: "Status",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  {
    label: "Version",
    group: "Agreement Info",
    isLawFirmRequired: false,
  },
  // Party Info
  {
    label: "Counterparty Org Name",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  {
    label: "Counterparty Legal Name",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  {
    label: "Counterparty Country",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  {
    label: "Client Org Name",
    group: "Party Info",
    isLawFirmRequired: true,
  },
  {
    label: "Client Legal Name",
    group: "Party Info",
    isLawFirmRequired: true,
  },
  {
    label: "Client Country",
    group: "Party Info",
    isLawFirmRequired: true,
  },
  {
    label: "Our Org Name",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  {
    label: "Our Legal Name",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  {
    label: "Our Country",
    group: "Party Info",
    isLawFirmRequired: false,
  },
  // Dates
  {
    label: "Created Date",
    group: "Dates",
    isLawFirmRequired: false,
  },
  {
    label: "Last Updated Date",
    group: "Dates",
    isLawFirmRequired: false,
  },
  {
    label: "Effective Date",
    group: "Dates",
    isLawFirmRequired: false,
  },
  {
    label: "Signed Year",
    group: "Dates",
    isLawFirmRequired: false,
  },
  // Collaborators
  {
    label: "Current Agreement Owner",
    group: "Collaborators",
    isLawFirmRequired: false,
  },
  {
    label: "Agreement Creator",
    group: "Collaborators",
    isLawFirmRequired: false,
  },
];

export const /** @type {PathElement[]} */ defaultElements = [
    {
      label: "Element",
      selectedOption: null,
    },
  ];
