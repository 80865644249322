import {
  faBarsFilter,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faCirclePlus,
  faFileContract,
  faFileImport,
  faHandshake,
  faSave,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CanveoCircularProgress, FabStandard } from "../../../components";
import { capitalizeFirstLetter } from "../../../components/MergeFieldMenu/utils";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import FilterDropdown from "../../Agreements/components/FilterDropdown";
import { getDisplayUsersCellForTable } from "../../Tasks/getAssigneesChip";
import { dateColumnFormatter } from "../../utils/dateColumnFormatter";
import { DialogCreateWorkflow } from "./dialogs/DialogCreateWorkflow";

/**
 * @typedef {object} AgreementWorklfow
 * @property {string} _id
 * @property {string} name
 * @property {string} type
 * @property {boolean} active
 * @property {boolean} appliesToAllAreas
 * @property {object[]} selectedAreas
 * @property {string} selectedAreas._id
 * @property {string} selectedAreas.name
 * @property {boolean} appliesToAllAgreementTypes
 * @property {object[]} selectedAgreementTypes
 * @property {string} selectedAgreementTypes._id
 * @property {string} selectedAgreementTypes.shortName
 * @property {string[]} selectedAgreementTypes.fullName
 * @property {*[]} nodes
 * @property {string} organizationId
 * @property {object} createdBy
 * @property {string} createdBy._id
 * @property {string} createdBy.displayName
 * @property {string} createdBy.title
 * @property {string} createdBy.photoURL
 * @property {string} updatedBy
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 */

/**
 * @import {GridColDef} from "@mui/x-data-grid"
 * @typedef {ReturnType<typeof mapAgreementWorkflowsToRows>[number]} AgreementWorkflowTableRow
 * @typedef {GridColDef<ReturnType<typeof mapAgreementWorkflowsToRows>[number]>} AgreementWorkflowTableColumn
 */

/** @type {AgreementWorkflowTableColumn[]} */
const columns = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
  },
  {
    headerName: "Status",
    field: "status",
    renderCell: (params) => {
      return (
        <Chip
          size="small"
          label={capitalizeFirstLetter(params.row.status)}
          sx={(theme) =>
            params.row.status.toLowerCase() === "inactive"
              ? {
                  variant: "outlined",
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }
              : {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                  fontWeight: "bold",
                }
          }
        />
      );
    },
  },
  {
    headerName: "Type",
    field: "type",
    minWidth: 200,
  },
  {
    headerName: "Area",
    field: "area",
    minWidth: 150,
    renderCell: (params) => {
      const areaLabels = params.row.area;
      if (typeof areaLabels === "string") return areaLabels;

      return (
        <Grid container direction="column" gap={1}>
          {areaLabels.map((al, index) => (
            <Grid key={index} item>
              <Chip
                size="small"
                label={al}
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
              />
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    headerName: "Agreement Type",
    field: "agreementType",
    minWidth: 200,
    renderCell: (params) => {
      const agreementType = params.row.agreementType;
      if (typeof agreementType === "string") return agreementType;

      return (
        <Grid container direction="column" gap={1}>
          {agreementType.map((at, index) => (
            <Grid key={index} item>
              {at} {index !== agreementType.length - 1 ? " | " : undefined}
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    headerName: "Created",
    field: "created",
    valueFormatter: dateColumnFormatter,
  },
  {
    headerName: "Updated",
    field: "updated",
    type: "date",
    valueFormatter: dateColumnFormatter,
  },
  {
    headerName: "Creator",
    field: "creator",
    minWidth: 250,
    renderCell: (params) => {
      const creator = params.row.creator;
      return getDisplayUsersCellForTable([
        {
          displayName: creator.displayName,
          title: creator.title,
          photoURL: creator.photoURL,
        },
      ]);
    },
  },
];

/**
 * @param {AgreementWorklfow[]} agreementWorkflows
 */
function mapAgreementWorkflowsToRows(agreementWorkflows) {
  const rows = agreementWorkflows.map((aw) => {
    return {
      id: aw._id,
      name: aw.name,
      status: aw.active ? "Active" : "Inactive",
      type: aw.type,
      area: aw.appliesToAllAreas
        ? "All"
        : aw.selectedAreas.map((sa) => sa.name),
      agreementType: aw.appliesToAllAgreementTypes
        ? "All"
        : aw.selectedAgreementTypes.map(
            (sat) => `${sat.fullName.at(0)} (${sat.shortName})`
          ),
      created: new Date(aw.createdAt),
      updated: new Date(aw.updatedAt),
      creator: aw.createdBy || {
        displayName: "Canveo User",
        title: undefined,
        photoURL: undefined,
      },
    };
  });

  return rows;
}

/**
 * @typedef {object} WorkflowsProps
 */

/**
 * @param {WorkflowsProps} _props
 * @return {JSX.Element}
 */
export function Workflows(_props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(
    /** @type {import("../Properties").PropertiesTableRow[]} */ ([])
  );
  const [showFilters, setShowFilters] = useState(false);
  const [openDialogCreateWorkflow, setOpenDialogCreateWorkflow] =
    useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(
    /** @type {AgreementWorklfow | null} */ (null)
  );

  useEffect(
    () => {
      loadAgreementWorkflows();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadAgreementWorkflows() {
    try {
      setIsLoading(true);

      const result = await axios.get(
        `${state.settings.api}agrworkflow?organizationId=${state.user.orgID}`
      );

      /** @type {AgreementWorklfow[]} */
      const agreementWorkflows = result.data.data;
      const rows = mapAgreementWorkflowsToRows(agreementWorkflows);
      setRows(rows);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message:
            "Unable to retrieve agreement workflows, try again or contact Canveo Support if the issue persists.",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * @param {string} id
   */
  async function loadAgreementWorkflow(id) {
    try {
      const result = await axios.get(`${state.settings.api}agrworkflow/${id}`);

      return result.data.data;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message:
            "Unable to retrieve agreement workflow, try again or contact Canveo Support if the issue persists.",
        },
      });
    }
  }

  /**
   * @param {*} agrWorkflow
   */
  async function submitAgreementWorkflow(agrWorkflow) {
    try {
      await axios.post(`${state.settings.api}agrWorkflow`, {
        agrWorkflow,
      });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "success",
          message: "Agreement Workflow created successfully.",
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message: "An error occurred while saving your changes.",
        },
      });
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      {isLoading ? (
        <Grid container justifyContent="center" sx={{ mt: 30 }}>
          <CanveoCircularProgress />
        </Grid>
      ) : (
        <>
          <FabStandard
            text="New"
            icon={faCirclePlus}
            sx={{
              left: "100px",
              top: "80px",
              right: "unset",
            }}
            click={() => setOpenDialogCreateWorkflow(true)}
          />

          {openDialogCreateWorkflow && (
            <DialogCreateWorkflow
              open={openDialogCreateWorkflow}
              close={() => {
                setSelectedWorkflow(null);
                setOpenDialogCreateWorkflow(false);
              }}
              submit={async (agrWorkflow) => {
                await submitAgreementWorkflow(agrWorkflow);
                setOpenDialogCreateWorkflow(false);
                loadAgreementWorkflows();
              }}
              workflow={selectedWorkflow}
            />
          )}

          <Grid item>
            <Typography variant="h4">Manage Workflows</Typography>
          </Grid>

          <Grid item sx={{ mt: 2 }}>
            <Typography variant="body1" textAlign={"center"}>
              Manage Workflows
            </Typography>
          </Grid>

          <Box>
            <Grid container direction="row" mt={4} spacing={1}>
              {getCanveoTier(state?.user?.email) === "experimental" && (
                <>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={
                        <FontAwesomeIcon
                          icon={faBarsFilter}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      endIcon={
                        <FontAwesomeIcon
                          icon={showFilters ? faCaretUp : faCaretDown}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      Filters
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          style={{ fontSize: "14px" }}
                        />
                      }
                    >
                      Saved Searches
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {showFilters && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                width={1020}
              >
                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faFileContract} />
                    <Typography variant="subtitle1">Agreement</Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Name" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Type" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Area" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Status" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Next Action Lies With" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Labels" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Source" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Notes" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faHandshake} />
                    <Typography variant="subtitle1">Parties</Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Info" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Info" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Info" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <Typography variant="subtitle1">Dates & Renewal</Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Created Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Updated Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Effective Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Start Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Expiry Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Term" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Mode" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Term" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Notice Period" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Notice Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Task Assignee" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faFileImport} />
                    <Typography variant="subtitle1">
                      Merge Fields & Properties
                    </Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Merge Fields" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Parameters" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faUser} />
                    <Typography variant="subtitle1">
                      Collaborators & Signers
                    </Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Current Agreement Owner" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Agreement Creator" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container mt={2} mb={16} justifyContent="center">
              <Box sx={{ height: 400, width: 1020 }}>
                <DataGrid
                  checkboxSelection
                  density="comfortable"
                  getRowHeight={() => "auto"}
                  // @ts-ignore
                  columns={columns}
                  rows={rows}
                  onRowClick={(params) => {
                    const workflowId = params.row.id;
                    loadAgreementWorkflow(workflowId).then((workflow) => {
                      setSelectedWorkflow(workflow);
                      setOpenDialogCreateWorkflow(true);
                    });
                  }}
                  disableRowSelectionOnClick
                  filterMode="client"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 2,
                    },
                    [`& .${gridClasses.columnHeaderTitle}`]: {
                      fontWeight: 400,
                    },
                    [`& .${gridClasses.detailPanel}`]: {
                      background: "transparent",
                    },
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                      {
                        outline: "none",
                      },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: "none",
                      },
                    "& .MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                    border: 0,
                    "& .MuiDataGrid-cell": {
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                  hideFooter
                  autoHeight
                  disableColumnFilter
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
    </Grid>
  );
}
