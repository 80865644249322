import {
  faArrowLeft,
  faExclamationTriangle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { CanveoCircularProgress, ClauseLibraryList } from "../..";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";

import {
  ASSIGN_TOPICS_COMMAND,
  INSERT_FROM_LIBRARY,
  SAVE_TO_LIBRARY,
} from "../../editor/commands";
import DialogClauseActions from "./DialogClauseActions";
import DialogClauseHeader from "./DialogClauseHeader";

/**
 * @typedef {Object} Details
 * @property {string} name - The name of the detail.
 * @property {string} type - The type of the detail.
 * @property {string} activeClauseKey - The active clause key.
 * @property {*} activeCTs - The active legal topics.
 * @property {string} clauseText - The clause text.
 * @property {string} orgID - The organization ID.
 * @property {string} _id - The ID of the detail.
 * @property {string} mode - The mode of the detail.
 * @property {Object} clitem - The clause library item.
 */

/**
 * @typedef {Object} Props
 * @property {Details} details - The details of the props.
 * @property {boolean} open - The open state of the props.
 * @property {(action: Object | string | null)=> void} close - The close function of the props.
 * @returns
 */

/**
 * DialogClause component
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The DialogClause component.
 */
export default function DialogClause({ details, open, close }) {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [state, dispatch] = useContext(globalStore);
  const [loading, setLoading] = useState(false);
  const [guidance, setGuidance] = useState({
    name: "",
    isNew: true,
  });
  const [errMsg, setErrMsg] = useState(/** @type {string | null} */ (null));
  // const [deletingTopic, setDeletingTopic] = useState(false);
  const [deletingLibItem, setDeletingLibItem] = useState(false);
  const [assignTopics, setAssignTopics] = useState(null);
  const [libItem, setLibItem] = useState(null);
  const [findClauseFromLibrary, setFindClauseFromLibrary] = useState(false);
  const [insertLocation, setInsertLocation] = useState("replaceCurrent");

  useEffect(() => {
    if (!open || !details) return;

    let guid = null;

    const { _id, activeCTs, activeClauseKey, clauseText, clitem, name, type } =
      details;

    switch (type) {
      case "newtopic":
        guid = {
          guidance: "",
          name: "",
          isNew: true,
          isNewTopic: true,
        };
        break;
      case "updatetopic":
        guid = state.clauseTypeGuides.find((g) => g.ctid === _id);
        guid = { ...guid, name }; // Add name of passed in ct details
        break;
      case "assignclausetype":
        if (activeCTs && activeClauseKey) {
          setAssignTopics(activeCTs);
        }
        break;
      case "savelibrary":
        if (activeCTs && clauseText) {
          setLibItem({
            clauseTypes: activeCTs,
            clauseText: clauseText.replace(/\n{4}/g, "\n\n"),
          });
        }
        break;
      case "editlibrary":
        if (clitem) {
          setLibItem({
            clauseTypes: clitem.clauseTypes,
            clauseText: clitem.text.replace(/\n{4}/g, "\n\n"),
          });
        }
        break;
      case "insertclause":
      case "alternativeclause":
        setFindClauseFromLibrary(true);
        if (type === "alternativeclause") {
          setInsertLocation("replaceCurrent");
        }
        break;
      default:
        break;
    }

    if (guid) {
      setGuidance(guid);
    }
  }, [open, details]);

  /**
   * Close the dialog and reset the state
   * @param {Object | string | null} action
   */
  const closeDialog = (action) => {
    close(action);
    setLoading(false);
    setErrMsg(null);
    // setDeletingTopic(false);
    setDeletingLibItem(false);
    setGuidance({ name: "", isNew: true });
    setAssignTopics(null);
    setLibItem(null);
    setInsertLocation("replaceCurrent");
    setFindClauseFromLibrary(false);
  };

  const submitTopicAssign = () => {
    const newClauseTypes = assignTopics;
    const command = ASSIGN_TOPICS_COMMAND;
    const payload = { key: details?.activeClauseKey, ctids: newClauseTypes };
    closeDialog({ command, payload });
  };

  const submitInsertClause = () => {
    const command = INSERT_FROM_LIBRARY;
    const payload = {
      key: details?.activeClauseKey,
      libItem,
      insertLocation,
    };
    closeDialog({ command, payload });
  };

  const submitEditLibraryItem = () => {
    const { clauseText, clauseTypes } = libItem;
    const newLibItem = {
      ...details?.clitem,
      text: clauseText,
      clauseTypes,
      lastUpdateBy: state.user._id,
      lastUpdateDate: new Date().toISOString(),
    };

    axios
      .put(state.settings.api + "clauselib/" + newLibItem._id, {
        clauseLibItem: newLibItem,
      })
      .then((resCLI) => {
        if (resCLI.data.success) {
          // Add newly created agrType to the reducer
          dispatch({ type: "UPDATE_CLAUSELIBITEM", payload: resCLI.data.data });
          closeDialog("clauseLibUpdated");
        } else {
          setErrMsg(
            "An error occured while updating the library item - refresh your browser"
          );
        }
      })
      .catch(() =>
        setErrMsg("An error occured while updating the library item")
      )
      .finally(() => setLoading(false));
  };

  const submitSaveToLibrary = () => {
    const { clauseText, clauseTypes } = libItem;
    const command = SAVE_TO_LIBRARY;
    const payload = {
      key: details?.activeClauseKey,
      ctids: clauseTypes,
      // rating: libItem.rating,
      text: clauseText,
    };
    closeDialog({ command, payload });
  };

  /**
   * Handle the change of topics
   * @param {*} type
   * @param {*} newVal
   */
  const handleTopicChange = (type, newVal) => {
    const ats = [];
    newVal.forEach((nv) => ats.push(nv._id));
    if (type === "library") {
      setLibItem({ ...libItem, clauseTypes: ats });
    } else {
      setAssignTopics(ats);
    }
  };

  const submitTopicUpdate = () => {
    setLoading(true);

    const newct = {
      name: guidance.name,
      orgID: state.org._id,
      active: true,
    };

    const existingClauseId = details?._id;

    if (existingClauseId) {
      axios
        .put(state.settings.api + "clausetype/" + existingClauseId, {
          clauseType: newct,
        })
        .then((resCT) => {
          if (resCT.data.success) {
            dispatch({ type: "UPDATE_CLAUSETYPE", payload: resCT.data.data });
            closeDialog("clauseTypeUpdated");
            // TODO: If needed in future, update guidance if present
          } else {
            setErrMsg(
              "An error occured while updating the clause type - refresh your browser"
            );
          }
        })
        .catch(() =>
          setErrMsg("An error occured while updating the clause type")
        )
        .finally(() => setLoading(false));
    } else {
      axios
        .post(state.settings.api + "clausetype", { clauseType: newct })
        .then((resCT) => {
          if (resCT.data.success) {
            // Add newly created clauseType to the reducer
            dispatch({ type: "ADD_CLAUSETYPE", payload: resCT.data.data });
            closeDialog("clauseTypeCreated");
          } else {
            setErrMsg(
              "An error occured while creating the clause type - refresh your browser"
            );
          }
        })
        .catch(() =>
          setErrMsg("An error occured while creating the clause type")
        )
        .finally(() => setLoading(false));
    }
  };

  // TODO: Commented out since guidance is not currently used

  // const submitGuidanceUpdate = () => {
  //   setLoading(true);

  //   const newGuide = {
  //     orgID: state.org._id,
  //     ctid: details?._id,
  //     guidance: guidance.name,
  //   };

  //   if (guidance.isNew) {
  //     // NEW GUIDANCE
  //     pushCreateGuidance(newGuide);
  //   } else {
  //     // GUIDANCE UPDATE
  //     axios
  //       .put(state.settings.api + "clausetype/guidance/" + newGuide.ctid, {
  //         clauseTypeGuide: newGuide,
  //       })
  //       .then((resGuide) => {
  //         if (resGuide.data.success) {
  //           // Add newly created agrType to the reducer
  //           dispatch({
  //             type: "UPDATE_CLAUSETYPEGUIDE",
  //             payload: resGuide.data.data,
  //           });
  //           closeDialog("clauseTypeGuidanceUpdated");
  //         } else {
  //           setErrMsg(
  //             "An error occured while updating the guidance - refresh your browser"
  //           );
  //         }
  //       })
  //       .catch(() => setErrMsg("An error occured while updating the guidance"))
  //       .finally(() => setLoading(false));
  //   }
  // };

  // const pushCreateGuidance = (newGuide) => {
  //   axios
  //     .post(state.settings.api + "clausetype/guidance", {
  //       clauseTypeGuide: newGuide,
  //     })
  //     .then((resGuide) => {
  //       if (resGuide.data.success) {
  //         // Add newly created agrType to the reducer
  //         dispatch({
  //           type: "ADD_CLAUSETYPEGUIDE",
  //           payload: resGuide.data.data,
  //         });
  //         closeDialog("clauseTypeGuidanceCreated");
  //       } else {
  //         setErrMsg(
  //           "An error occured while creating the guidance - refresh your browser"
  //         );
  //       }
  //     })
  //     .catch(() => setErrMsg("An error occured while creating the guidance"))
  //     .finally(() => setLoading(false));
  // };

  const handleConfirmDelete = () => {
    if (!details) {
      console.log("No details provided for deletion");
      return;
    }
    // if (details._id  && deletingTopic) {
    //   // DELETING TOPIC

    //   const newt = state.clauseTypes.filter(
    //     (ct) => ct.orgID === state.org._id && ct._id === details._id
    //   )[0];

    //   const ctId = newt._id

    //   if (newt) {
    //     try {
    //       setLoading(true);
    //       await axios.delete(
    //         `${state.settings.api}clausetype/bulk/${ctId}`
    //       );
    //       // Dispatch the refresh displayed data
    //       dispatch({
    //         type: "DELETE_CLAUSETYPES",
    //         payload: ctId,
    //       });
    //     } catch (error) {
    //       console.error(error);
    //       dispatch({
    //         type: "NEW_SNACKBAR",
    //         payload: {
    //           message: "An error occurred while deleting the topics.",
    //           severity: "error",
    //         },
    //       });
    //   }
    // } else
    if (details.clitem && deletingLibItem) {
      // DELETING LIB ITEM
      const newcli = details.clitem;
      if (newcli) {
        setLoading(true);
        newcli.orgID = "deleted_" + newcli.orgID;
        axios
          .put(state.settings.api + "clauselib/" + newcli._id, {
            clauseLibItem: newcli,
          })
          .then((resCLI) => {
            if (resCLI.data.success) {
              // Add newly created clauseType to the reducer
              dispatch({
                type: "DELETE_CLAUSELIBITEM",
                payload: resCLI.data.data,
              });
              closeDialog("clauseLibDeleted");
            } else {
              setErrMsg(
                "An error occured while deleting the clause library item - refresh your browser"
              );
            }
          })
          .catch(() =>
            setErrMsg("An error occured while deleting the clause library item")
          )
          .finally(() => setLoading(false));
      }
    }
  };

  const handleSelectClauseLibItem = (item) => {
    setLibItem({
      // rating: item.rating,s
      clauseTypes: item.clauseTypes,
      clauseText: item.text.replaceAll("\n\n\n\n", "\n\n"),
      libID: state.org._id + "_" + item._id,
    });
    setFindClauseFromLibrary(false);
  };

  const handleClauseLibraryListError = (error) =>
    console.log("handleClauseLibraryListError", error);

  /**
   * Get the dialog title based on provided details
   * @param {Details} details
   * @returns {string} - The dialog title.
   */
  const getDialogTitle = (details) => {
    if (!details) return "Clause Options";

    switch (details.type) {
      case "alternativeclause":
      case "insertclause":
        return "Insert Fallback Clause";
      case "editlibrary":
        return "Edit Library Clause";
      case "savelibrary":
        return "Save Clause to Library";
      case "updatetopic":
        return "Edit Legal Topic";
      case "newtopic":
        return "New Legal Topic";
      case "assignclausetype":
        return "Assign Topics to a Clause";
      default:
        return "Clause Options";
    }
  };

  // const canDelete =
  //   details?.type !== "newtopic" &&
  //   details?.type !== "insertclause" &&
  //   details?.orgID !== "CANVEO";

  const getDeleteButtonAction = () => {
    // if (canDelete && !deletingTopic) {
    //   return setDeletingTopic(true);
    // } else

    if (details?.type === "editlibrary" && !deletingLibItem) {
      return setDeletingLibItem(true);
    }
  };

  const getDeleteButtonText = () => {
    // if (canDelete && !deletingTopic) {
    //   return "Delete Legal Topic";
    // } else

    if (details?.type === "editlibrary" && !deletingLibItem) {
      return "Delete Clause";
    }
  };

  const showDeleteButton = () =>
    // (canDelete && !deletingTopic) ||
    details?.type === "editlibrary" && !deletingLibItem;

  const getDeleteConfirmationText = () => "clause from your library";
  // deletingTopic ? "legal topic" : "clause from your library";

  const handleCancelAction = () => {
    setErrMsg(null);
    if (details?.type === "insertclause" && !findClauseFromLibrary) {
      setFindClauseFromLibrary(true);
    } else if (deletingLibItem) {
      setDeletingLibItem(false);
      // } else if (deletingTopic) {
      //   setDeletingTopic(false);
    } else {
      closeDialog(null);
    }
  };

  const getCancelButtonText = () => {
    if (
      // deletingTopic ||
      deletingLibItem ||
      (details?.type === "insertclause" && !findClauseFromLibrary)
    ) {
      return (
        <>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Go Back
        </>
      );
    } else {
      return "Cancel";
    }
  };

  const getSubmitButtonAction = () => {
    if (!details) return submitTopicUpdate;

    switch (details.type) {
      case "insertclause":
      case "alternativeclause":
        return submitInsertClause;
      case "editlibrary":
        return submitEditLibraryItem;
      case "savelibrary":
        return submitSaveToLibrary;
      case "assignclausetype":
        return submitTopicAssign;
      default:
        return submitTopicUpdate;
    }
  };

  const isSubmitButtonDisabled = () => {
    if (guidance?.isNewTopic && guidance?.name.length < 3) return true;
    // if (deletingTopic || deletingLibItem || findClauseFromLibrary) return true;
    if (deletingLibItem || findClauseFromLibrary) return true;
    if (libItem?.clauseTypes?.length < 1) return true;
    return false;
  };

  const getSubmitButtonText = () => {
    if (!details) return "Create";

    switch (details.type) {
      case "alternativeclause":
        return "Replace";
      case "insertclause":
        return "Insert";
      case "editlibrary":
      case "savelibrary":
        return "Save";
      case "assignclausetype":
        return "Assign";
      default:
        return details.type === "newtopic" ? "Create" : "Update";
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogClauseHeader
        title={getDialogTitle(details)}
        onClose={closeDialog}
      />
      <DialogContent sx={{ my: 3 }}>
        <Grid container direction="column" alignItems="center">
          {loading ? (
            <Grid item sx={{ my: 2 }}>
              <CanveoCircularProgress />
            </Grid>
          ) : // ) : deletingTopic || deletingLibItem ? (
          deletingLibItem ? (
            <Grid item sx={{ width: isSmUp ? "400px" : "300px", py: 2 }}>
              <Box sx={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    color: theme.palette.error.main,
                    fontSize: "30px",
                    marginBottom: "30px",
                  }}
                />
                <Typography>
                  Are you sure you want to delete this{" "}
                  {getDeleteConfirmationText()}?
                </Typography>
                <Typography sx={{ fontWeight: "700" }}>
                  This action cannot be undone.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="error"
                    onClick={handleConfirmDelete}
                  >
                    Delete&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Box>
              </Box>
            </Grid>
          ) : findClauseFromLibrary ? (
            <Grid item sx={{ pt: 2 }}>
              <ClauseLibraryList
                type="find"
                onSelect={handleSelectClauseLibItem}
                handleError={handleClauseLibraryListError}
                initialFilters={
                  ["alternativeclause", "insertclause"].includes(
                    details?.type
                  ) && details?.activeCTs
                    ? details?.activeCTs
                    : []
                }
              />
            </Grid>
          ) : (
            <Grid item sx={{ width: isSmUp ? "400px" : "300px", py: 2 }}>
              {errMsg && (
                <Box sx={{ mb: 3 }}>
                  <Typography color="error" align="center">
                    {errMsg}
                  </Typography>
                </Box>
              )}

              {
                // Assign Clause Types to a clause or save to library
                [
                  "assignclausetype",
                  "savelibrary",
                  "editlibrary",
                  "insertclause",
                  "alternativeclause",
                ].includes(details?.type) ? (
                  <>
                    <Box
                      sx={{
                        textAlign: "center",
                        my: libItem?.clauseTypes ? 1 : 3,
                      }}
                    >
                      {
                        // when inserting clauses - just display the chips (ie. don't allow to update)
                        ["insertclause", "alternativeclause"].includes(
                          details?.type
                        ) && libItem?.clauseTypes ? (
                          <Box>
                            {libItem.clauseTypes.map((ct) => {
                              const clauseT = state.clauseTypes.filter(
                                (sct) => sct._id === ct
                              )[0];
                              return (
                                <Chip
                                  key={ct}
                                  color="primary"
                                  size="small"
                                  style={{
                                    padding: "4px",
                                    marginRight: "5px",
                                  }}
                                  label={
                                    <span style={{ fontWeight: "600" }}>
                                      {clauseT?.name ?? "Unknown"}
                                    </span>
                                  }
                                />
                              );
                            })}
                          </Box>
                        ) : assignTopics || libItem?.clauseTypes ? (
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={
                              details?.mode === "default"
                                ? state.clauseTypes
                                : state.clauseTypes.filter(
                                    (ct) =>
                                      !!state.clauseTypeGuides.find(
                                        (g) => g.ctid === ct._id
                                      )
                                  )
                            }
                            getOptionLabel={(option) => option.name}
                            value={
                              libItem?.clauseTypes
                                ? state.clauseTypes.filter((ct) =>
                                    libItem.clauseTypes.includes(ct._id)
                                  )
                                : state.clauseTypes.filter((ct) =>
                                    assignTopics?.includes(ct._id)
                                  )
                            }
                            onChange={(e, value) =>
                              handleTopicChange(
                                libItem ? "library" : "topics",
                                value
                              )
                            }
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Legal Topics"
                                placeholder="Select ..."
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  label={
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "5px 4px 5px 5px",
                                      }}
                                    >
                                      {option.name}
                                    </span>
                                  }
                                  color="primary"
                                />
                              ))
                            }
                          />
                        ) : (
                          "Unable to assign types"
                        )
                      }

                      {libItem && (
                        <Box
                          sx={{
                            pt: 4,
                            pb: 1,
                            textAlign: "left",
                            fontSize: "14px",
                            color: theme.palette.grey[700],
                          }}
                        >
                          {details?.type === "editlibrary" ? (
                            <TextField
                              id="standard-multiline-flexible"
                              label="Clause Text"
                              multiline
                              fullWidth
                              minRows={4}
                              maxRows={8}
                              value={libItem.clauseText}
                              onChange={(e) =>
                                setLibItem({
                                  ...libItem,
                                  clauseText: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <Box
                              sx={{
                                maxHeight: "200px",
                                overflow: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              {libItem.clauseText}
                            </Box>
                          )}
                        </Box>
                      )}

                      {
                        // when inserting clauses - just display the chips (ie. don't allow to update)
                        details?.type === "insertclause" && (
                          <Box sx={{ mt: 1 }}>
                            <FormControl>
                              <RadioGroup
                                row
                                name="insert-location"
                                value={insertLocation}
                                onChange={(e) =>
                                  setInsertLocation(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="replaceCurrent"
                                  control={<Radio />}
                                  label="Replace"
                                />
                                <FormControlLabel
                                  value="insertBefore"
                                  control={<Radio />}
                                  label="Insert before"
                                />
                                <FormControlLabel
                                  value="insertAfter"
                                  control={<Radio />}
                                  label="Insert after"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        )
                      }
                    </Box>
                  </>
                ) : (
                  // Creating new topic / or updating guidance for an existing topic
                  <>
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        label="Legal Topic Name"
                        placeholder="Insert topic name, e.g. 'Payment Terms'"
                        fullWidth
                        value={guidance.name}
                        onChange={(e) =>
                          setGuidance({ ...guidance, name: e.target.value })
                        }
                        autoFocus
                      />
                    </Box>
                    {/* TODO: Commented out to see if its missed. Remove if no longer needed */}
                    {/* <TextField
                      id="outlined-multiline"
                      label="Legal Topic Guidance"
                      multiline
                      autoFocus={!guidance.isNewTopic}
                      fullWidth
                      minRows={4}
                      maxRows={6}
                      value={guidance.guidance}
                      onChange={(e) =>
                        setGuidance({ ...guidance, guidance: e.target.value })
                      }
                      placeholder='E.g. "Our standard is GBP. We can accept USD, but we cannot accept EUR. 
                        If counterparty insists on EUR, route to Legal for approval."'
                    /> */}
                  </>
                )
              }
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogClauseActions
        cancelAction={{
          label: getCancelButtonText(),
          action: handleCancelAction,
        }}
        deleteAction={{
          label: getDeleteButtonText(),
          action: getDeleteButtonAction,
          disabled: !showDeleteButton(),
        }}
        submitAction={{
          label: getSubmitButtonText(),
          action: getSubmitButtonAction(),
          disabled: isSubmitButtonDisabled(),
        }}
      />
    </Dialog>
  );
}
