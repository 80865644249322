import { countries } from "../assets/static";

/**
 * @param {*} isoCode
 * @param {*} withName
 * @returns
 */
function countryToFlag(isoCode, withName = true) {
  const country = countries.find((c) => c.code === isoCode);

  if (!country?.label || typeof String.fromCodePoint === "undefined") {
    return isoCode;
  }

  const flag = isoCode
    .toUpperCase()
    .replace(/./g, (/** @type {string} */ char) =>
      String.fromCodePoint(char.charCodeAt(0) + 127397)
    );

  return withName ? `\xa0\xa0|\xa0\xa0${flag} ${country.label}` : flag;
}

export default countryToFlag;
