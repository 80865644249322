import { faTimes, faUniversity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { countryToFlag } from "../../utils";

const filter = createFilterOptions();

/**
 * @typedef {object} DialogNewDefaultEntityRuleProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(data: *) => void} submit
 */

/**
 * @param {DialogNewDefaultEntityRuleProps} props
 * @returns {JSX.Element}
 */
export function DialogNewDefaultEntityRule({ open, close, submit }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [partyInformationField, setPartyInformationField] = useState(
    /** @type {*} */ (null)
  );
  const [operator, setOperator] = useState(/** @type {*} */ (null));
  const [comparisonValue, setComparisonValue] = useState(
    /** @type {*} */ (null)
  );
  const [
    overallFallbackContractingEntity,
    setOverallFallbackContractingEntity,
  ] = useState(/** @type {*} */ (null));

  /**
   * @param {*} option
   * @returns {*}
   */
  function renderOption(option) {
    let value = option.legalName;
    if (option?.address?.[0].country) {
      value += countryToFlag(option.address[0].country);
    }

    return value;
  }

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>New Default Entity Rule</DialogTitle>

      <DialogContent>
        <Box
          mt={1}
          sx={{
            outline: "grey dashed 1px",
            borderRadius: "25px",
            paddingY: 2,
          }}
        >
          <Grid container direction="row" justifyContent="center" mt={2} mb={4}>
            <b>If the Counterparty's ...</b>
          </Grid>

          <Grid container direction="row" justifyContent="center" mt={2}>
            <Autocomplete
              sx={{ width: "286px" }}
              options={[
                { label: "Party Address (L1)", value: 1 },
                { label: "Party Address (L2)", value: 2 },
                { label: "Party City", value: 3 },
                { label: "Party Country", value: 4 },
                { label: "Party Entity Name", value: 5 },
                { label: "Party Org Name", value: 6 },
                { label: "Party State/Province", value: 7 },
                { label: "Party ZIP Code", value: 8 },
              ]}
              autoHighlight
              openOnFocus={true}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              // value={
              //   baseValueTypes.find((x) => x.value === baseValueType) || null
              // }
              onChange={(_event, option) => {
                setPartyInformationField(option);
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    autoFocus={true}
                    label="Party Information Field"
                    placeholder="Search party information field ..."
                    variant="outlined"
                  />
                </>
              )}
            />
          </Grid>

          {partyInformationField && (
            <>
              <Grid container direction="row" justifyContent="center" mt={2}>
                <Autocomplete
                  sx={{ width: "286px" }}
                  options={[
                    { label: "is equal to", value: 1 },
                    { label: "is not equal to", value: 2 },
                    { label: "contains", value: 3 },
                    { label: "does not contain", value: 4 },
                    { label: "is known", value: 5 },
                    { label: "is unknown", value: 6 },
                  ]}
                  autoHighlight
                  openOnFocus={true}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(_event, option) => {
                    setOperator(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus={true}
                      label="Operator"
                      placeholder="Search party information field ..."
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {operator && (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    mt={2}
                    mb={4}
                  >
                    <Autocomplete
                      multiple
                      openOnFocus={true}
                      getOptionDisabled={(option) =>
                        option === "Type to add a value to this filter"
                      }
                      value={
                        comparisonValue instanceof Array ? comparisonValue : []
                      }
                      // @ts-ignore
                      onChange={(
                        _,
                        /** @type {string[] | {inputValue: string; title: string}[]} */ values
                      ) => {
                        if (values.length) {
                          const mappedValues = values.map((value) => {
                            if (typeof value === "string") {
                              return value;
                            }

                            return value.inputValue;
                          });

                          // We do not allow duplicate values so we use a Set to ensure that.
                          setComparisonValue([
                            ...Array.from(new Set(mappedValues)),
                          ]);
                        } else {
                          setComparisonValue([]);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Press enter to add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={[]}
                      getOptionLabel={(
                        /** @type {string | {inputValue: string; title: string}} */ option
                      ) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.inputValue;
                      }}
                      renderOption={(
                        props,
                        /** @type {string | {title: string}} */ option
                      ) => {
                        if (typeof option === "string") {
                          return <li {...props}>{option}</li>;
                        }

                        return <li {...props}>{option.title}</li>;
                      }}
                      sx={{ width: "286px" }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Comparison Value"
                          variant="outlined"
                          placeholder="Enter comparison value"
                          autoFocus={true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    mt={6}
                    mb={4}
                  >
                    <b>Then our default contracting entity should be ...</b>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    mt={2}
                    mb={4}
                  >
                    <Autocomplete
                      disablePortal
                      options={state.subs}
                      fullWidth
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input.
                        if (typeof option === "string") return option;
                        // Add "xxx" option created dynamically.
                        if (option.inputValue) return option.inputValue;
                        // Regular option.
                        return renderOption(option);
                      }}
                      renderOption={(props, option) => (
                        <Typography
                          variant={
                            option._id !== undefined ? "body1" : "subtitle1"
                          }
                          color={"textPrimary"}
                          {...props}
                        >
                          <span>
                            {renderOption(option)}{" "}
                            {option.defaultLegalEntity ? (
                              // <b>{"(Default)"}</b>
                              // TODO: needs to be reverted upon final implementation
                              <></>
                            ) : (
                              <></>
                            )}
                          </span>
                        </Typography>
                      )}
                      onChange={(_event, value) => {
                        setOverallFallbackContractingEntity(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus={false}
                          label="Overall Fallback Contracting Entity"
                          placeholder="Select overall fallback contracting entity ..."
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: "new-password",
                            startAdornment: (
                              <InputAdornment position="start">
                                <FontAwesomeIcon
                                  icon={faUniversity}
                                  color={theme.palette.primary.main}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      sx={{ width: "286px" }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => {
            submit({
              partyInformationField,
              operator,
              comparisonValue,
              overallFallbackContractingEntity,
            });
          }}
          disabled={
            !partyInformationField ||
            !operator ||
            !comparisonValue ||
            !overallFallbackContractingEntity
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
