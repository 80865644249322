import {
  faBook,
  faBookBookmark,
  faBooks,
  faCircleInfo,
  faClipboardQuestion,
  faClone,
  faComments,
  faDiagramNested,
  faDownload,
  faFileContract,
  faFileLines,
  faGear,
  faGears,
  faHashtag,
  faList,
  faPalette,
  faPeopleGroup,
  faPlugCirclePlus,
  faShieldKeyhole,
  faSignature,
  faTags,
  faUniversity,
  faUser,
  faUserSecret,
} from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@mui/material";
import React from "react";
import { getCanveoTier } from "../../utils/getCanveoTier";

/**
 * @param {string} active
 * @param {(route: string) => {}} handleClick
 * @param {string} email
 * @param {boolean} isSuperAdmin
 */
export function getAdminDrawerItems(
  active,
  handleClick,
  email,
  isSuperAdmin = false
) {
  const drawerItems = [
    {
      name: "Settings",
      icon: faGear,
      active: active === "profile",
      click: () => handleClick("/admin/profile"),
    },
    {
      name: "Companies",
      icon: faUniversity,
      active: active === "entities",
      click: () => handleClick("/admin/entities"),
    },
    {
      name: "Users",
      icon: faUser,
      active: active === "users",
      click: () => handleClick("/admin/users"),
    },
    {
      name: "Roles",
      icon: faShieldKeyhole,
      active: active === "roles",
      click: () => handleClick("/admin/roles"),
    },
    {
      name: "Signers",
      icon: faSignature,
      active: active === "signers",
      click: () => handleClick("/admin/signers"),
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      ...[
        {
          name: "Branding",
          icon: faPalette,
          active: active === "branding",
          click: () => handleClick("/admin/branding"),
        },
      ]
    );
  }

  drawerItems.push({
    name: "Integrations",
    icon: faPlugCirclePlus,
    active: active === "integrations",
    click: () => handleClick("/admin/integrations"),
  });

  if (isSuperAdmin) {
    return [
      ...drawerItems,
      {
        name: "ADMIN",
        icon: faUserSecret,
        active: active === "superadmin",
        click: () => handleClick("/superadmin"),
      },
    ];
  }

  return drawerItems;
}

/**
 * @param {string} active
 * @param {(route: string) => void} handleClick
 * @param {string} email
 * @param {*} state
 */
export function getLegalDrawerItems(active, handleClick, email, state) {
  const drawerItems = [
    {
      name: "Templates",
      icon: faFileLines,
      active: active === "main",
      click: () => handleClick("/templates/main"),
    },
  ];

  if (state?.org?.integrations?.openAi?.apiKey) {
    drawerItems.push(
      ...[
        {
          name: "Legal Topics",
          icon: faHashtag,
          active: active === "topics",
          click: () => handleClick("/templates/topics"),
        },
        {
          name: "Properties",
          icon: faGears,
          active: active === "properties",
          click: () => handleClick("/templates/properties"),
        },
      ]
    );
  }

  drawerItems.push(
    ...[
      {
        name: "Labels",
        icon: faTags,
        active: active === "labels",
        click: () => handleClick("/templates/labels"),
      },
      {
        name: "Teams",
        icon: faPeopleGroup,
        active: active === "teams",
        click: () => handleClick("/templates/teams"),
      },
    ]
  );

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push({
      name: "Clause Library",
      icon: faBooks,
      active: active === "library",
      click: () => handleClick("/templates/library"),
    });
  }

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push({
      name: "Policy",
      icon: faBookBookmark,
      active: active === "policy",
      click: () => handleClick("/templates/policy"),
    });
  }

  if (state?.org?.integrations?.openAi?.apiKey) {
    drawerItems.push({
      name: "Playbooks",
      icon: faBook,
      active: active === "playbook",
      click: () => handleClick("/templates/playbook"),
    });
  }

  if (state?.org?.integrations?.openAi?.apiKey) {
    drawerItems.push({
      // @ts-ignore
      name: (
        <Box textAlign="center">
          Workflows <br /> (Beta)
        </Box>
      ),
      icon: faDiagramNested,
      active: active === "workflows",
      click: () => handleClick("/templates/workflows"),
    });
  }

  return drawerItems;
}

/**
 * @param {string} email
 */
export function getTemplateDrawerItems(email, hideExport = false) {
  const drawerItems = [
    {
      name: "Summary",
      icon: faCircleInfo,
    },
    {
      name: "Exhibits",
      icon: faFileContract,
    },
    {
      name: "Versions",
      icon: faClone,
    },
    {
      name: "Questionnaire",
      icon: faClipboardQuestion,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push({
      name: "Contents",
      icon: faList,
    });
  }

  drawerItems.push({
    name: "Approvals",
    icon: faComments,
  });

  if (!hideExport) {
    drawerItems.push({
      name: "Export",
      icon: faDownload,
    });
  }

  return drawerItems;
}

/**
 * @param {{ email: string; hideExport: boolean; isCounterparty: boolean}} props
 */
export function getAgreementDrawerItems({
  email,
  hideExport = false,
  isCounterparty,
}) {
  const drawerItems = [
    {
      id: "editor-drawer-summary",
      name: "Summary",
      icon: faCircleInfo,
    },
    {
      id: "editor-drawer-exhibits",
      name: "Exhibits",
      icon: faFileContract,
    },
    {
      id: "editor-drawer-versions",
      name: "Versions",
      icon: faClone,
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    drawerItems.push(
      {
        id: "editor-drawer-playbook",
        name: "Playbook",
        icon: faBookBookmark,
      },
      {
        id: "editor-drawer-questionnaire",
        name: "Questionnaire",
        icon: faClipboardQuestion,
      },
      {
        id: "editor-drawer-contents",
        name: "Contents",
        icon: faList,
      }
    );
  }

  if (!isCounterparty) {
    drawerItems.push({
      id: "editor-drawer-approvals",
      name: "Approvals",
      icon: faComments,
    });
  }

  if (!hideExport) {
    drawerItems.push({
      id: "editor-drawer-export",
      name: "Export",
      icon: faDownload,
    });
  }

  return drawerItems;
}
