import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, DialogTitle } from "@mui/material";

/**
 * A header component for dialog clauses that includes a title and close button
 * @param {Object} props - The component props
 * @param {string} props.title - The title text to display in the dialog header
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClose - Callback function triggered when the close button is clicked
 * @returns {JSX.Element} A dialog header with title and close button
 */
const DialogClauseHeader = ({ title, onClose }) => (
  <>
    <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
      <IconButton onClick={onClose}>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ padding: "4px 7px", fontSize: "20px" }}
        />
      </IconButton>
    </Box>
    <DialogTitle>{title}</DialogTitle>
  </>
);

export default DialogClauseHeader;
