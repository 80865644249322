import { faTrash, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DialogActions } from "@mui/material";

/**
 * @typedef DialogActions
 * @property {string | JSX.Element} [label]
 * @property {() => void} action
 * @property {boolean} [disabled]
 */

/**
 * Renders action buttons for the DialogClause component
 * @param {Object} props - Component props
 * @param {DialogActions} props.cancelAction - Cancel button configuration
 * @param {DialogActions} props.deleteAction - Delete button configuration
 * @param {DialogActions} props.submitAction - Submit button configuration
 * @returns {JSX.Element} DialogClauseActions component
 */
const DialogClauseActions = ({ cancelAction, deleteAction, submitAction }) => (
  <DialogActions sx={{ pb: 2, pl: 2, pr: 2 }}>
    <Button onClick={cancelAction.action} sx={{ marginRight: "auto" }}>
      {cancelAction.label}
    </Button>

    {!deleteAction.disabled && (
      <Button color="error" onClick={deleteAction.action}>
        <FontAwesomeIcon icon={faTrash} />
        &nbsp;&nbsp;{deleteAction.label}
      </Button>
    )}

    <Button
      variant="contained"
      disableElevation
      onClick={submitAction.action}
      disabled={submitAction.disabled}
    >
      {submitAction.label}
      &nbsp;&nbsp;
      <FontAwesomeIcon icon={faCheck} />
    </Button>
  </DialogActions>
);

export default DialogClauseActions;
