import axios from "axios";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { durationUnits } from "../components/MergeFieldMenu/constants";
import { renewalModes } from "../components/dialogs/DialogDatesAndRenewal/constants";
import { globalStore } from "../state/store";

const yearsDuration = durationUnits[6];
const daysDuration = durationUnits[2];

/**
 * @typedef {[{settings: { api: string; }, user: { _id: string; displayName: string; email: string; }, agrs: *[], users: *[]}, Function]} GlobalState
 */

/**
 * @typedef {object} useAgreementMetadataProps
 * @property {() => void} close
 * @property {string} agreementId
 */

/**
 * @param {useAgreementMetadataProps} props
 */
export function useAgreementMetadata({ close, agreementId }) {
  const [state, dispatch] = /** @type {GlobalState} */ (
    /** @type {unknown} */ (useContext(globalStore))
  );
  const agreement = state.agrs.find(
    (/** @type {{ _id: string; }} */ agreement) => agreement._id === agreementId
  );
  if (!agreement) throw new Error("Agreement does not exist.");

  const [isLoading, setIsLoading] = useState(true);
  const [effectiveDate, setEffectiveDate] = useState("");
  const [useDateOfLastESignature, setUseDateOfLastESignature] = useState(
    // getCanveoTier(state?.user?.email) === "experimental"
    false
  );
  const [
    startDateIsDifferentFromEffectiveDate,
    setStartDateIsDifferentFromEffectiveDate,
  ] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [termDuration, setTermDuration] = useState("indefiniteTerm");
  const [termDurationValue, setTermDurationValue] = useState("1");
  const [termDurationUnit, setTermDurationUnit] = useState(
    /** @type {import("../components/dialogs/DialogDatesAndRenewal/constants").AutocompleteOption | null} */ (
      yearsDuration
    )
  );
  const [expiryDate, setExpiryDate] = useState("2025-02-15");
  const [expiryDateIsDirty, setExpiryDateIsDirty] = useState(false);
  const [setTask, setSetTask] = useState(false);
  const [assignees] = useState(
    state.users.map((user) => ({
      _id: user._id,
      name: user.displayName,
      email: user.email,
    }))
  );
  const [assignee, setAssignee] = useState(
    /** @type {import("../components/dialogs/DialogDatesAndRenewal/constants").AssigneeOption | null} */ ({
      _id: state.user._id,
      name: state.user.displayName,
      email: state.user.email,
    })
  );
  const [reminders, setReminders] = useState(
    /** @type {{durationValue: string, durationUnit: import("../components/dialogs/DialogDatesAndRenewal/constants").AutocompleteOption | null}[]} */ ([
      { durationValue: "90", durationUnit: daysDuration },
    ])
  );
  const [renewalMode, setRenewalMode] = useState(
    /** @type {typeof renewalModes[number] | null} */ (renewalModes[0])
  );
  const [noticePeriodDurationValue, setNoticePeriodDurationValue] =
    useState("30");
  const [noticePeriodDurationUnit, setNoticePeriodDurationUnit] = useState(
    /** @type {import("../components/dialogs/DialogDatesAndRenewal/constants").AutocompleteOption | null} */ ({
      label: "Days",
      value: "days",
    })
  );
  const [noticeDate, setNoticeDate] = useState("");
  const [renewalTermDurationValue, setRenewalTermDurationValue] = useState("");
  const [renewalTermDurationValueIsDirty, setRenewalTermDurationValueIsDirty] =
    useState(false);
  const [renewalTermDurationUnit, setRenewalTermDurationUnit] = useState(
    /** @type {import("../components/dialogs/DialogDatesAndRenewal/constants").AutocompleteOption | null} */ (
      null
    )
  );
  const [renewalTermDurationUnitIsDirty, setRenewalTermDurationUnitIsDirty] =
    useState(false);

  useEffect(() => {
    if (termDuration === "indefiniteTerm") {
      setTermDurationValue("");
      setTermDurationUnit(null);
    } else {
      setTermDurationValue("1");
      setTermDurationUnit(yearsDuration);
    }
  }, [termDuration]);

  useEffect(() => {
    if (!renewalTermDurationValueIsDirty) {
      setRenewalTermDurationValue(termDurationValue);
    }
  }, [termDurationValue, renewalTermDurationValueIsDirty]);

  useEffect(() => {
    if (!renewalTermDurationUnitIsDirty) {
      setRenewalTermDurationUnit(termDurationUnit);
    }
  }, [termDurationUnit, renewalTermDurationUnitIsDirty]);

  // Automatically calculate noticed date.
  useEffect(() => {
    if (
      renewalMode?.value !== "noRenewal" &&
      expiryDate &&
      noticePeriodDurationValue &&
      noticePeriodDurationUnit
    ) {
      const noticeDate = DateTime.fromFormat(expiryDate, "yyyy-MM-dd")
        .minus({
          [noticePeriodDurationUnit.value]: Number(noticePeriodDurationValue),
        })
        .toFormat("yyyy-MM-dd");
      setNoticeDate(noticeDate);
    }
  }, [
    renewalMode,
    expiryDate,
    noticePeriodDurationValue,
    noticePeriodDurationUnit,
  ]);

  useEffect(() => {
    loadData();
    // Run effect once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {string} dateString
   * @returns {string}
   */
  function convertToDisplayDateWithTimezone(dateString) {
    const date = new Date(dateString);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  }

  /**
   * @param {string} termDurationValue
   * @param {import("../components/dialogs/DialogDatesAndRenewal/constants").AutocompleteOption} termDurationUnit
   * @param {string} effectiveDate
   * @param {string} startDate
   */
  function calculateExpiryDate(
    termDurationValue,
    termDurationUnit,
    effectiveDate,
    startDate
  ) {
    const relevantStartDate = startDateIsDifferentFromEffectiveDate
      ? startDate
      : effectiveDate;

    const expiryDate = DateTime.fromFormat(relevantStartDate, "yyyy-MM-dd")
      .plus({
        [termDurationUnit.value]: Number(termDurationValue),
      })
      .minus({ day: 1 })
      .toFormat("yyyy-MM-dd");

    setExpiryDate(expiryDate);
  }

  /**
   * @param {*} datesAndRenewal
   */
  function loadState(datesAndRenewal) {
    const {
      effectiveDate,
      useDateOfLastESignature,
      startDateIsDifferentFromEffectiveDate,
      startDate,
      termDuration,
      termDurationValue,
      termDurationUnit,
      expiryDate,
      setTask,
      assignee,
      reminders,
      renewalMode,
      noticePeriodDurationValue,
      noticePeriodDurationUnit,
      renewalTermDurationValue,
      noticeDate,
      renewalTermDurationUnit,
    } = datesAndRenewal;

    setEffectiveDate(
      effectiveDate
        ? convertToDisplayDateWithTimezone(effectiveDate)
        : effectiveDate
    );
    setUseDateOfLastESignature(useDateOfLastESignature);
    setStartDateIsDifferentFromEffectiveDate(
      startDateIsDifferentFromEffectiveDate
    );
    setStartDate(
      startDate ? convertToDisplayDateWithTimezone(startDate) : startDate
    );
    setTermDuration(termDuration || "fixedTerm");
    setTermDurationValue(termDurationValue);
    setTermDurationUnit(termDurationUnit);
    setExpiryDate(
      expiryDate ? convertToDisplayDateWithTimezone(expiryDate) : expiryDate
    );
    setSetTask(setTask);
    setAssignee(assignee);
    setReminders(reminders);
    setRenewalMode(renewalMode);
    setNoticePeriodDurationValue(noticePeriodDurationValue);
    setNoticePeriodDurationUnit(noticePeriodDurationUnit);
    setRenewalTermDurationValue(renewalTermDurationValue);
    setRenewalTermDurationValueIsDirty(true);
    setNoticeDate(noticeDate);
    setRenewalTermDurationUnit(renewalTermDurationUnit);
    setRenewalTermDurationUnitIsDirty(true);
  }

  async function loadData() {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${state.settings.api}agr/${agreementId}/datesAndRenewal`
      );
      const data = response.data.data;
      if (data) {
        loadState(data);
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  async function submit() {
    try {
      // We need to convert the dates to ISO string because we need to store them with the user's timezone
      // associated with their browser's locale.
      const datesAndRenewal = {
        effectiveDate: effectiveDate
          ? new Date(effectiveDate).toISOString()
          : effectiveDate,
        useDateOfLastESignature,
        startDateIsDifferentFromEffectiveDate,
        startDate: startDate ? new Date(startDate).toISOString() : startDate,
        termDuration,
        termDurationValue,
        termDurationUnit,
        expiryDate: expiryDate
          ? new Date(expiryDate).toISOString()
          : expiryDate,
        setTask,
        assignee: assignee?._id,
        reminders,
        renewalMode,
        noticePeriodDurationValue,
        noticePeriodDurationUnit,
        renewalTermDurationValue,
        noticeDate: noticeDate ? new Date(noticeDate).toISOString() : startDate,
        renewalTermDurationUnit,
      };

      const response = await axios.put(
        `${state.settings.api}agr/${agreementId}/datesAndRenewal`,
        {
          datesAndRenewal,
        }
      );
      const data = response.data.data;
      if (!data) throw new Error("Failed to update dates and renewal.");

      dispatch({
        type: "UPDATE_AGRS",
        payload: { ...agreement, datesAndRenewal: data },
      });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "success",
          message: "Successfully updated agreement metadata.",
        },
      });
    } catch (error) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message:
            "An error occurred while updating the agreement metadata, try again or contact Canveo Support if the issue persists.",
        },
      });
      throw error;
    } finally {
      close();
    }
  }

  return {
    isLoading,
    effectiveDate,
    setEffectiveDate,
    useDateOfLastESignature,
    setUseDateOfLastESignature,
    startDateIsDifferentFromEffectiveDate,
    setStartDateIsDifferentFromEffectiveDate,
    startDate,
    setStartDate,
    termDuration,
    setTermDuration,
    termDurationValue,
    setTermDurationValue,
    termDurationUnit,
    setTermDurationUnit,
    // agreementHasNoFixedTerm,
    // setAgreementHasNoFixedTerm,
    expiryDate,
    setExpiryDate,
    expiryDateIsDirty,
    setExpiryDateIsDirty,
    setTask,
    setSetTask,
    assignees,
    assignee,
    setAssignee,
    reminders,
    setReminders,
    renewalMode,
    setRenewalMode,
    noticePeriodDurationValue,
    setNoticePeriodDurationValue,
    noticePeriodDurationUnit,
    setNoticePeriodDurationUnit,
    renewalTermDurationValue,
    setRenewalTermDurationValue,
    renewalTermDurationValueIsDirty,
    setRenewalTermDurationValueIsDirty,
    noticeDate,
    setNoticeDate,
    renewalTermDurationUnit,
    setRenewalTermDurationUnit,
    renewalTermDurationUnitIsDirty,
    setRenewalTermDurationUnitIsDirty,
    submit,
    calculateExpiryDate,
  };
}
