import axios from "axios";
import { useContext, useState } from "react";
import { globalStore } from "../state/store";
import { randomString as generateRandomString } from "../utils";

export default function useMergeFieldService() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [errorMessage, setErrorMessage] = useState(/** @type {unknown} */ (""));
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setLoading(false);
    setErrorMessage("");
  };

  /**
   * @param { Omit<import("../components/editor/nodes/MarkNode").MergeField, "_id" | "canonicalId" | "editorMarkNodeId" | "documentId" | "position" | "createdAt" | "updatedAt" | "organizationId">} mergeField
   * @param {string} partyID
   * @param {string} docID
   * @param {"Merge Field" | "Agreement Property"} toastDisplayValue
   */
  const createMergeField = async (
    mergeField,
    partyID,
    docID,
    toastDisplayValue
  ) => {
    try {
      setLoading(true);
      setErrorMessage("");

      let /** @type {string} */ editorMarkNodeId = "";
      if (mergeField.scope === "document") {
        editorMarkNodeId = `pa${partyID.substring(5)}_${generateRandomString(
          5
        )}`;
      }

      const newMergeField = await axios.post(
        state.settings.api + "mergefield",
        {
          mergeField: {
            ...mergeField,
            organizationId: state.user.orgID,
            editorMarkNodeId,
            documentId: docID,
            partyId: partyID,
          },
        }
      );

      dispatch({
        type: "ADD_PARAM",
        payload: { type: "doc", item: newMergeField.data.data },
      });

      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: `${toastDisplayValue} created`,
          severity: "success",
        },
      });

      return newMergeField.data.data;
    } catch (e) {
      setErrorMessage(e);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /**
   * @param {import("../components/editor/nodes/MarkNode").MergeField} mergeField
   * @param {"Merge Field" | "Agreement Property"} toastDisplayValue
   */
  const updateMergeField = async (mergeField, toastDisplayValue) => {
    try {
      setLoading(true);
      setErrorMessage("");
      const updatedMergeField = await axios.put(
        `${state.settings.api}mergefield/${mergeField._id}`,
        {
          mergeField,
        }
      );
      dispatch({
        type: "UPDATE_PARAM",
        payload: { type: "doc", item: updatedMergeField.data.data },
      });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: `${toastDisplayValue} updated`,
          severity: "success",
        },
      });
      setLoading(false);
      return updatedMergeField.data.data;
    } catch (e) {
      setLoading(false);
      setErrorMessage(e);
      return null;
    }
  };

  return {
    errorMessage,
    loading,
    createMergeField,
    updateMergeField,
    resetState,
  };
}
