import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getCanveoTier } from "../utils/getCanveoTier";
import CanveoCircularProgress from "./CanveoCircularProgress";
import { renewalModes } from "./dialogs/DialogDatesAndRenewal/constants";
import { durationUnits } from "./MergeFieldMenu/constants";
import SelectLabels from "./SelectLabels";

const termDurations = [
  { label: "Indefinite Term", value: "indefiniteTerm" },
  { label: "Fixed Term", value: "fixedTerm" },
];

const filter = createFilterOptions();

/**
 * @param {*} options
 * @param {*} params
 * @returns {*}
 */
function filterOptions(options, params) {
  const filtered = filter(options, params);

  // Suggest the creation of a new value.
  if (
    params.inputValue !== "" &&
    !filtered.some(
      (option) => option.name.toLowerCase() === params.inputValue.toLowerCase()
    )
  ) {
    filtered.push({
      inputValue: params.inputValue,
      name: `Create "${params.inputValue}" as a new Parameter`,
    });
  } else if (params.inputValue === "") {
    filtered.unshift({
      inputValue: params.inputValue,
      name: `Create New Parameter`,
    });
  }

  return filtered;
}

/**
 * @typedef {object} AgreementMetadataProps
 * @property {*} document
 * @property {ReturnType<typeof import("../hooks/useAgreementMetadata").useAgreementMetadata>} metadataState
 * @property {*[]} parameters
 */

/**
 * @param {AgreementMetadataProps} props
 * @returns {JSX.Element}
 */
export function AgreementMetadata({ document, parameters, metadataState }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const {
    isLoading,
    effectiveDate,
    setEffectiveDate,
    useDateOfLastESignature,
    setUseDateOfLastESignature,
    startDateIsDifferentFromEffectiveDate,
    setStartDateIsDifferentFromEffectiveDate,
    startDate,
    setStartDate,
    termDurationValue,
    setTermDurationValue,
    termDurationUnit,
    setTermDurationUnit,
    termDuration,
    setTermDuration,
    expiryDate,
    setExpiryDate,
    expiryDateIsDirty,
    setExpiryDateIsDirty,
    setTask,
    setSetTask,
    assignees,
    assignee,
    setAssignee,
    reminders,
    setReminders,
    renewalMode,
    setRenewalMode,
    noticePeriodDurationValue,
    setNoticePeriodDurationValue,
    noticePeriodDurationUnit,
    setNoticePeriodDurationUnit,
    renewalTermDurationValue,
    setRenewalTermDurationValue,
    renewalTermDurationValueIsDirty,
    setRenewalTermDurationValueIsDirty,
    noticeDate,
    setNoticeDate,
    renewalTermDurationUnit,
    setRenewalTermDurationUnit,
    renewalTermDurationUnitIsDirty,
    setRenewalTermDurationUnitIsDirty,
    calculateExpiryDate,
  } = metadataState;

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          {isLoading ? (
            <Grid container justifyContent="center">
              <CanveoCircularProgress />
            </Grid>
          ) : (
            <>
              {/* <Grid container mt={2}>
                <Typography fontWeight="bold" color={theme.palette.grey[700]}>
                  Dates
                </Typography>
              </Grid> 

              <br /> */}

              <Box>
                <Typography variant="subtitle1" fontSize={14} mt={4}>
                  Effective Date
                </Typography>

                <br />

                {useDateOfLastESignature && !effectiveDate ? (
                  <TextField
                    type="date"
                    fullWidth
                    label="Effective Date"
                    InputLabelProps={{ shrink: true }}
                    value={"[Date of last e-signature]"}
                    onChange={(event) => setEffectiveDate(event.target.value)}
                    disabled
                  />
                ) : (
                  <TextField
                    type="date"
                    fullWidth
                    label="Effective Date"
                    InputLabelProps={{ shrink: true }}
                    value={effectiveDate}
                    onChange={(event) => setEffectiveDate(event.target.value)}
                  />
                )}

                <br />
                <br />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useDateOfLastESignature}
                        onChange={(_) => {
                          const updateValue = !useDateOfLastESignature;
                          setUseDateOfLastESignature(updateValue);
                          if (updateValue) {
                            setEffectiveDate("");
                          }
                        }}
                      />
                    }
                    label="Use date of last e-signature"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={startDateIsDifferentFromEffectiveDate}
                        onChange={(_) =>
                          setStartDateIsDifferentFromEffectiveDate(
                            !startDateIsDifferentFromEffectiveDate
                          )
                        }
                      />
                    }
                    label="Start Date is different from Effective Date"
                  />
                </FormGroup>

                {startDateIsDifferentFromEffectiveDate && (
                  <>
                    <br />

                    <TextField
                      type="date"
                      fullWidth
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                  </>
                )}
              </Box>

              <br />

              <Box>
                <Typography variant="subtitle1" fontSize={14}>
                  Term
                </Typography>

                <br />

                <Grid container direction="row">
                  <Autocomplete
                    fullWidth
                    disableClearable
                    options={termDurations}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          label="Duration"
                          placeholder="Select Duration ..."
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </>
                    )}
                    value={
                      termDurations.find((td) => td.value === termDuration) ||
                      termDurations[0]
                    }
                    onChange={(_, value) => {
                      if (value?.value) {
                        setTermDuration(value.value);
                      }
                    }}
                  />
                </Grid>

                <br />

                {termDuration !== "indefiniteTerm" && (
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        label="Initial Term"
                        placeholder="Initial Term"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        disabled={termDuration === "indefiniteTerm"}
                        value={termDurationValue}
                        onChange={(event) => {
                          setTermDurationValue(event.target.value);
                          if (
                            event.target.value &&
                            termDurationUnit &&
                            (effectiveDate || startDate)
                          ) {
                            calculateExpiryDate(
                              event.target.value,
                              termDurationUnit,
                              effectiveDate,
                              startDate
                            );
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        options={durationUnits}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="Initial Term Unit"
                              placeholder="Select Initial Term Unit ..."
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </>
                        )}
                        disabled={termDuration === "indefiniteTerm"}
                        value={termDurationUnit}
                        onChange={(_, value) => {
                          setTermDurationUnit(value);
                          if (
                            termDurationValue &&
                            value &&
                            (effectiveDate || startDate)
                          ) {
                            calculateExpiryDate(
                              termDurationValue,
                              value,
                              effectiveDate,
                              startDate
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>

              {termDuration === "fixedTerm" && (
                <>
                  <br />

                  <Box>
                    <Typography variant="subtitle1" fontSize={14}>
                      Expiry & Renewal
                    </Typography>

                    <br />

                    <TextField
                      type="date"
                      fullWidth
                      label="Expiry Date"
                      InputLabelProps={{ shrink: true }}
                      value={expiryDate}
                      onChange={(event) => {
                        if (!expiryDateIsDirty) {
                          setExpiryDateIsDirty(true);
                        }
                        setExpiryDate(event.target.value);
                      }}
                    />

                    <br />
                  </Box>

                  <br />

                  <Box>
                    <Autocomplete
                      options={renewalModes}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Renewal Mode"
                            placeholder="Select Renewal Mode ..."
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </>
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Grid container direction="column">
                            <Typography>{option.label}</Typography>
                            <Typography
                              variant="caption"
                              color={theme.palette.grey[500]}
                            >
                              {option.description}
                            </Typography>
                          </Grid>
                        </Box>
                      )}
                      value={renewalMode}
                      onChange={(_, value) => {
                        setRenewalMode(value);
                      }}
                    />

                    {renewalMode?.value !== "noRenewal" && (
                      <>
                        <br />

                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <TextField
                              type="number"
                              label="Renewal Term"
                              placeholder="Renewal Term"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={renewalTermDurationValue}
                              onChange={(event) => {
                                if (!renewalTermDurationValueIsDirty) {
                                  setRenewalTermDurationValueIsDirty(true);
                                }
                                setRenewalTermDurationValue(event.target.value);
                              }}
                            />
                          </Grid>

                          <Grid item xs={8}>
                            <Autocomplete
                              options={durationUnits}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    label="Renewal Term Unit"
                                    placeholder="Select Renewal Term Unit ..."
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </>
                              )}
                              value={renewalTermDurationUnit}
                              onChange={(_, value) => {
                                if (!renewalTermDurationUnitIsDirty) {
                                  setRenewalTermDurationUnitIsDirty(true);
                                }
                                setRenewalTermDurationUnit(value);
                              }}
                            />
                          </Grid>
                        </Grid>

                        <br />

                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={4}>
                            <TextField
                              type="number"
                              label="Notice Period"
                              placeholder="Notice Period"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              value={noticePeriodDurationValue}
                              onChange={(event) =>
                                setNoticePeriodDurationValue(event.target.value)
                              }
                            />
                          </Grid>

                          <Grid item xs={8}>
                            <Autocomplete
                              options={durationUnits}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    label="Notice Period Unit"
                                    placeholder="Select Notice Period Unit ..."
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </>
                              )}
                              value={noticePeriodDurationUnit}
                              onChange={(_, value) => {
                                setNoticePeriodDurationUnit(value);
                              }}
                            />
                          </Grid>
                        </Grid>

                        <br />

                        <TextField
                          type="date"
                          label="Notice Date"
                          placeholder="Notice Date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={noticeDate}
                          onChange={(event) => {
                            setNoticeDate(event.target.value);
                          }}
                        />

                        <br />

                        <br />
                      </>
                    )}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={setTask}
                            onChange={(_) => {
                              setSetTask(!setTask);
                            }}
                          />
                        }
                        label="Set Reminder"
                      />
                    </FormGroup>

                    {setTask && (
                      <>
                        <br />

                        <Autocomplete
                          options={assignees}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.email === value.email
                          }
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Assignee"
                                placeholder="Select Assignee ..."
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            </>
                          )}
                          value={
                            assignees.find((x) => x._id === assignee?._id) ||
                            null
                          }
                          onChange={(_, value) => {
                            setAssignee(value);
                          }}
                        />

                        {reminders.map((reminder, i) => (
                          <React.Fragment key={i}>
                            <br />

                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Grid item xs={4}>
                                <TextField
                                  type="number"
                                  label="Reminder"
                                  placeholder="Reminder"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  value={reminder.durationValue}
                                  onChange={(event) => {
                                    const updatedReminders = reminders.map(
                                      (reminder, ii) => {
                                        if (ii === i) {
                                          return {
                                            ...reminder,
                                            durationValue: event.target.value,
                                          };
                                        }

                                        return reminder;
                                      }
                                    );
                                    setReminders(updatedReminders);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={reminders.length === 1 ? 8 : 6}>
                                <Autocomplete
                                  options={durationUnits}
                                  autoHighlight
                                  getOptionLabel={(option) => option.label}
                                  isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                  }
                                  renderInput={(params) => (
                                    <>
                                      <TextField
                                        {...params}
                                        label="Reminder Unit"
                                        placeholder="Select Reminder Unit ..."
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    </>
                                  )}
                                  value={reminder.durationUnit}
                                  onChange={(_, value) => {
                                    const updatedReminders = reminders.map(
                                      (reminder, ii) => {
                                        if (ii === i) {
                                          return {
                                            ...reminder,
                                            durationUnit: value,
                                          };
                                        }

                                        return reminder;
                                      }
                                    );
                                    setReminders(updatedReminders);
                                  }}
                                />
                              </Grid>

                              {reminders.length > 1 && (
                                <Grid item xs={2}>
                                  <IconButton
                                    onClick={() => {
                                      setReminders(() => [
                                        ...reminders.slice(0, i),
                                        ...reminders.slice(i + 1),
                                      ]);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faX}
                                      fontSize={"14px"}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}

                        <br />

                        {reminders.length < 5 && (
                          <Typography
                            color="primary"
                            sx={{ fontWeight: "bolder", cursor: "pointer" }}
                            onClick={() => {
                              setReminders((prev) => {
                                return [
                                  ...prev,
                                  {
                                    durationValue: "",
                                    durationUnit: null,
                                  },
                                ];
                              });
                            }}
                          >
                            Set another reminder ...
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}

              {getCanveoTier(state?.user?.email) === "experimental" && (
                <>
                  <br />
                  <br />
                  <br />
                  <br />

                  <Box>
                    <Grid container mt={2}>
                      <Typography
                        fontWeight="bold"
                        color={theme.palette.grey[700]}
                      >
                        Labels
                      </Typography>
                    </Grid>

                    <br />

                    <Grid container direction="row">
                      <SelectLabels
                        displayIcon={false}
                        handleLabelSelect={() => {}}
                        selectedIDs={document.labels.map(
                          (/** @type {{ labelID: { _id: string; }; }} */ x) =>
                            x.labelID._id
                        )}
                        options={state.labels
                          .filter(
                            (
                              /** @type {{ active: any; type: { name: string; }; }} */ label
                            ) => label.active && label.type.name === "Agreement"
                          )
                          .sort(
                            (
                              /** @type {{ name: number; }} */ previous,
                              /** @type {{ name: number; }} */ next
                            ) => (previous.name > next.name ? 1 : -1)
                          )
                          .sort(
                            (
                              /** @type {{ type: { color: number; }; }} */ previous,
                              /** @type {{ type: { color: number; }; }} */ next
                            ) =>
                              previous.type.color > next.type.color ? 1 : -1
                          )}
                        labelType={"Agreement"}
                      />
                    </Grid>
                  </Box>

                  <br />
                  <br />
                  <br />
                  <br />

                  <Box>
                    <Grid container mt={2}>
                      <Typography
                        fontWeight="bold"
                        color={theme.palette.grey[700]}
                      >
                        Properties
                      </Typography>
                    </Grid>

                    <br />

                    <Grid container direction="row">
                      <Autocomplete
                        fullWidth
                        id="filter-label"
                        options={parameters.filter(
                          (p) => p.scope === "questionnaire"
                        )}
                        value={parameters.filter(
                          (p) => p.scope === "questionnaire"
                        )}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        disableCloseOnSelect
                        multiple
                        onChange={() => {}}
                        renderInput={(
                          /** @type {React.JSX.IntrinsicAttributes & { variant?: import("@mui/material").TextFieldVariants | undefined; } & Omit<import("@mui/material").OutlinedTextFieldProps | import("@mui/material").FilledTextFieldProps | import("@mui/material").StandardTextFieldProps, "variant">} */ params
                        ) => (
                          <TextField
                            {...params}
                            label="Parameters"
                            placeholder="Parameters ..."
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component={"li"} {...props}>
                            {option._id ? (
                              <Chip
                                key={option._id}
                                label={option.name}
                                color="secondary"
                                sx={{
                                  fontWeight: "700",
                                  px: 1,
                                }}
                              />
                            ) : (
                              <Typography
                                variant="subtitle1"
                                color={"textPrimary"}
                              >
                                {option.name}
                              </Typography>
                            )}
                          </Box>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              // @ts-ignore
                              key={option._id}
                              label={`${option.name}: ${option.displayValue}`}
                              color="secondary"
                              sx={{
                                fontWeight: "700",
                                px: 1,
                              }}
                              onClick={() => {}}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        filterOptions={filterOptions}
                      />
                    </Grid>

                    <Grid container mt={2}>
                      <Typography
                        fontWeight="bold"
                        color={theme.palette.grey[700]}
                      >
                        Merge Field Properties
                      </Typography>
                    </Grid>

                    <br />

                    <Grid container direction="row">
                      <Autocomplete
                        fullWidth
                        id="filter-label"
                        options={parameters.filter(
                          (p) => p.scope === "document"
                        )}
                        value={parameters.filter((p) => p.scope === "document")}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        disableCloseOnSelect
                        multiple
                        onChange={() => {}}
                        renderInput={(
                          /** @type {React.JSX.IntrinsicAttributes & { variant?: import("@mui/material").TextFieldVariants | undefined; } & Omit<import("@mui/material").OutlinedTextFieldProps | import("@mui/material").FilledTextFieldProps | import("@mui/material").StandardTextFieldProps, "variant">} */ params
                        ) => (
                          <TextField
                            {...params}
                            label="Parameters"
                            placeholder="Parameters ..."
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component={"li"} {...props}>
                            {option._id ? (
                              <Chip
                                key={option._id}
                                label={option.name}
                                color="secondary"
                                sx={{
                                  fontWeight: "700",
                                  px: 1,
                                }}
                              />
                            ) : (
                              <Typography
                                variant="subtitle1"
                                color={"textPrimary"}
                              >
                                {option.name}
                              </Typography>
                            )}
                          </Box>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              // @ts-ignore
                              key={option._id}
                              label={`${option.name}: ${option.displayValue}`}
                              color="secondary"
                              sx={{
                                fontWeight: "700",
                                px: 1,
                              }}
                              onClick={() => {}}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        filterOptions={filterOptions}
                      />
                    </Grid>

                    {/* <br />

                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Set Reminder" />
                  </FormGroup> */}
                  </Box>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
    </>
  );
}
