import {
  faClockRotateLeft,
  faCommentsQuestionCheck,
  faThumbTack,
  faThumbsUp,
  faTicketPerforated,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Grid, Link, Typography } from "@mui/material";
import React from "react";
import defaultLogo from "../../assets/img/defaultlogo.png";
import theme from "../../theme/theme";
import { dateColumnFormatter } from "../utils/dateColumnFormatter";
import { getDisplayUsersCellForTable } from "./getAssigneesChip";
import { getStatusChip } from "./getStatusChip";

/**
 * @typedef {"Renewal" | "Ticket" | "Task" | "Review" | "Approval" } TaskType
 */

/**
 * @typedef { "Pending" | "Pending Assignment" | "Assigned" | "In Progress" | "Done" | "Completed" | "Approved" | "Overdue" | "Cancelled" } TaskStatus
 */

const taskTypeIconFontSize = "22px";

/**
 * @param {import("react-router-dom").NavigateFunction} navigate
 */
export function getColumns(navigate) {
  return [
    {
      field: "type",
      headerName: "Type",
      renderCell: (/** @type {{ row: { type: string }}} */ params) => {
        const taskType = /** @type {TaskType} */ (params.row.type);
        switch (taskType) {
          case "Renewal": {
            return (
              <Grid container>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    color={theme.palette.primary.main}
                    fontSize={taskTypeIconFontSize}
                  />
                </Grid>
                <Grid item>
                  <Typography marginLeft="10px">{params.row.type}</Typography>
                </Grid>
              </Grid>
            );
          }

          case "Ticket": {
            return (
              <Grid container>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faTicketPerforated}
                    color={theme.palette.primary.main}
                    fontSize={taskTypeIconFontSize}
                  />
                  <Typography marginLeft="10px">{params.row.type}</Typography>
                </Grid>
              </Grid>
            );
          }

          case "Task": {
            return (
              <Grid container>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faThumbTack}
                    color={theme.palette.primary.main}
                    fontSize={taskTypeIconFontSize}
                  />
                </Grid>
                <Grid item>
                  <Typography marginLeft="10px">{params.row.type}</Typography>
                </Grid>
              </Grid>
            );
          }

          case "Review": {
            return (
              <Grid container>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faCommentsQuestionCheck}
                    color={theme.palette.primary.main}
                    fontSize={taskTypeIconFontSize}
                  />
                </Grid>
                <Grid item>
                  <Typography marginLeft="10px">{params.row.type}</Typography>
                </Grid>
              </Grid>
            );
          }

          case "Approval": {
            return (
              <Grid container>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    color={theme.palette.primary.main}
                    fontSize={taskTypeIconFontSize}
                  />
                </Grid>
                <Typography marginLeft="10px">{params.row.type}</Typography>
              </Grid>
            );
          }

          default:
            throw new Error(`${taskType} is not a valid task type.`);
        }
      },
      minWidth: 125,
    },
    {
      width: 10,
      field: "logo",
      headerName: "Logo",
      renderCell: (/** @type {*} */ params) => {
        return (
          <img
            src={params.row?.logo || defaultLogo}
            alt=""
            height="30px"
            style={{ borderRadius: "50%" }}
          />
        );
      },
    },
    {
      field: "counterparty",
      headerName: "Counterparty",
      minWidth: 125,
    },
    {
      field: "area",
      headerName: "Area",
      minWidth: 125,
      renderCell: (/** @type {{ row: { area: string }}} */ params) => {
        if (params.row.area === "n/a") return <></>;

        return (
          <Chip
            size="small"
            label={params.row.area}
            sx={{
              backgroundColor: "#FFFFFF",
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`,
            }}
          />
        );
      },
    },
    {
      field: "agreement",
      headerName: "Agreement",
      renderCell: (/** @type {*} */ params) => {
        return (
          <Link
            href={"blank"}
            fontWeight="bold"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              // The property `agrStatus` only exists on agreements and not on templates.
              const resource = params.row?.agreement?.agrStatus
                ? "agreement"
                : "templates";
              const resourceId = params.row?.agreement?._id;

              let route = `/${resource}/${resourceId}`;

              if (params.row.type === "Renewal") {
                route += `?openDatesAndRenewalDialog=true`;
              } else if (
                params.row.type === "Review" ||
                params.row.type === "Approval"
              ) {
                route += `?taskType=${params.row.type}&taskId=${params.row.id}`;
              }

              navigate(route);
            }}
          >
            {params?.row?.agreement?.agrTitle}
          </Link>
        );
      },
      valueGetter: (/** @type {*} */ params) =>
        params?.row?.agreement?.agrTitle,
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 160,
      renderCell: (/** @type {{ row: { status: string }}} */ params) => {
        const taskStatus = /** @type {TaskStatus} */ (params.row.status);
        return getStatusChip(taskStatus);
      },
    },
    {
      field: "requestor",
      headerName: "Requestor",
      minWidth: 250,
      renderCell: (/** @type {{ row: { requestor: * }}} */ params) => {
        const assignee = params.row.requestor;
        return getDisplayUsersCellForTable([
          {
            displayName: assignee.displayName,
            title: assignee.title,
            photoURL: assignee.photoURL,
          },
        ]);
      },
    },
    {
      field: "assignee",
      headerName: "Assignee(s)",
      minWidth: 250,
      renderCell: (/** @type {{ row: { assignee: *[] }}} */ params) => {
        return getDisplayUsersCellForTable(params.row.assignee);
      },
    },
    {
      field: "created",
      headerName: "Created",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "updated",
      headerName: "Updated",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "dueDate",
      headerName: "Due",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
  ];
}
