import {
  PdfViewerComponent,
  Inject,
  FormFields,
  TextSearch,
  Annotation,
  Toolbar,
  Magnification,
  Navigation,
  TextSelection,
} from "@syncfusion/ej2-react-pdfviewer";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../state/store";

/**
 * @param {{ fileKey: string; }} props
 * @returns {JSX.Element}
 */
export function PdfViewer({ fileKey }) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(
    () => {
      if (!fileKey) return;

      setIsLoading(true);
      axios
        .get(`${state.settings.api}document/${fileKey}`)
        .then((response) => {
          const url = response.data.data.url;
          setUrl(url);
        })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              severity: "error",
              message:
                "An error occurred while retrieving the document, try again or contact Canveo Support if the issue persists.",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // Only runs when `fileKey` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileKey]
  );

  if (!fileKey) return <></>;

  return (
    <div className="control-section">
      {isLoading && <></>}

      {!isLoading && url && (
        <PdfViewerComponent
          id="container"
          // Uncomment to try PDF viewer with test document.
          // documentPath="https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"
          documentPath={url}
          resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
          style={{ height: "850px", width: "1000px" }}
          enableNavigationToolbar={false}
          toolbarSettings={{
            // showTooltip: true,
            // KEPT TO SHOW WHAT'S AVAILABLE:
            // toolbarItems: [
            // "OpenOption",
            // "PageNavigationTool",
            // "MagnificationTool",
            // "PanTool",
            // "SelectionTool",
            // "SearchOption",
            // "PrintOption",
            // "DownloadOption",
            // "UndoRedoTool",
            // "AnnotationEditTool",
            // "InkAnnotationOption",
            // "ShapeAnnotationOption",
            // "StampAnnotationOption",
            // "SignatureOption",
            // "FormDesignerEditTool",
            // "FormFillOption",
            // "CommentTool",
            // "FreeTextAnnotationOption"
            // ],
            toolbarItems: ["SearchOption"],
          }}
          // navigationPaneSettings={{
          //   showNavigationPane: false,
          //   enableThumbnail: false,
          //   enableBookmark: false,
          //   enableDestinations: false,
          //   enableHyperlink: false,
          //   showThumbnail: false,
          //   showBookmark: false,
          // }}
        >
          {/* Available services for reference:
          <Inject
            services={
              [
                Toolbar,
                Magnification,
                Navigation,
                Annotation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                FormFields,
                FormDesigner,
              ]
            }
          /> */}
          <Inject
            services={[
              FormFields,
              TextSearch,
              TextSelection,
              Annotation,
              Toolbar,
              Magnification,
              Navigation,
            ]}
          />
        </PdfViewerComponent>
      )}
    </div>
  );
}
