import {
  Autocomplete,
  Chip,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import defaultLogo from "../../../assets/img/defaultlogo.png";
import theme from "../../../theme/theme";
import { getCanveoTier } from "../../../utils/getCanveoTier";
import { getDisplayUsersCellForTable } from "../../Tasks/getAssigneesChip";
import { dateColumnFormatter } from "../../utils/dateColumnFormatter";

/* 1) Operators for each type: text, number, date. 
   (Mirror MUI X defaults, but simplified.)
*/
const textOperators = [
  { label: "contains", value: "contains" },
  { label: "does not contain", value: "notContains" },
  { label: "equals", value: "equals" },
  { label: "does not equal", value: "notEquals" },
  { label: "starts with", value: "startsWith" },
  { label: "ends with", value: "endsWith" },
  { label: "is empty", value: "isEmpty" },
  { label: "is not empty", value: "isNotEmpty" },
  { label: "is any of", value: "isAnyOf" },
];

const numberOperators = [
  { label: "=", value: "eq" },
  { label: "!=", value: "neq" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
  { label: "<", value: "lt" },
  { label: "<=", value: "lte" },
  { label: "is empty", value: "isEmpty" },
  { label: "is not empty", value: "isNotEmpty" },
  { label: "is any of", value: "isAnyOf" },
];

const dateOperators = [
  { label: "is", value: "is" },
  { label: "is not", value: "isNot" },
  { label: "is after", value: "isAfter" },
  { label: "is on or after", value: "isOnOrAfter" },
  { label: "is before", value: "isBefore" },
  { label: "is on or before", value: "isOnOrBefore" },
  { label: "is empty", value: "isEmpty" },
  { label: "is not empty", value: "isNotEmpty" },
  { label: "Is earlier than or equal", value: "isEarlierOrEqual" },
  { label: "Is later than or equal", value: "isLaterOrEqual" },
];

/*
  2) Our main custom filter operator. 
     In filterItem.value => { propName, propType, operator, propValue }
*/
const propertyFilterOperators = [
  {
    label: "Select Operators",
    value: "propsNameValueOp",
    // @ts-ignore
    getApplyFilterFn: (filterItem) => {
      const val = filterItem.value;
      if (!val || !val.propName || !val.operator) {
        return null;
      }

      const { propName, propType, operator, propValue } = val;

      // @ts-ignore
      return (rowValue) => {
        // rowValue is the row's "properties" array
        if (!Array.isArray(rowValue)) return false;
        // find the property object with the matching name
        const found = rowValue.find((p) => p.name === propName);
        if (!found) return false;

        // handle "is empty" / "is not empty" / "is any of"
        // before we interpret numeric or date comparisons
        if (operator === "isEmpty") {
          return !found.value; // if it's "" or null
        }
        if (operator === "isNotEmpty") {
          return !!found.value;
        }
        if (operator === "isAnyOf") {
          // you might store multiple values in propValue if you want a multi-select
          // for simplicity, treat it as comma separated?
          if (!propValue) return false;
          const list = String(propValue)
            .split(",")
            .map((x) => x.trim());
          return list.includes(String(found.value));
        }

        // do the rest of the checks based on type
        switch (propType) {
          case "text":
            return matchText(String(found.value), operator, String(propValue));
          case "number":
            return matchNumber(found.value, operator, propValue);
          case "date":
            return matchDate(found.value, operator, propValue);
          default:
            return false;
        }
      };
    },
    InputComponent: PropertyFilterInput,
  },
];

/* 3) matchText, matchNumber, matchDate
   The logic for each type
*/
// @ts-ignore
function matchText(rowVal, operator, filterVal) {
  const rowLower = rowVal.toLowerCase();
  const filterLower = filterVal.toLowerCase();

  switch (operator) {
    case "contains":
      return rowLower.includes(filterLower);
    case "notContains":
      return !rowLower.includes(filterLower);
    case "equals":
      return rowLower === filterLower;
    case "notEquals":
      return rowLower !== filterLower;
    case "startsWith":
      return rowLower.startsWith(filterLower);
    case "endsWith":
      return rowLower.endsWith(filterLower);
    default:
      return false;
  }
}

// @ts-ignore
function matchNumber(rowVal, operator, filterVal) {
  const rowNum = Number(rowVal);
  const filterNum = Number(filterVal);
  if (Number.isNaN(rowNum) || Number.isNaN(filterNum)) return false;

  switch (operator) {
    case "eq":
      return rowNum === filterNum;
    case "neq":
      return rowNum !== filterNum;
    case "gt":
      return rowNum > filterNum;
    case "gte":
      return rowNum >= filterNum;
    case "lt":
      return rowNum < filterNum;
    case "lte":
      return rowNum <= filterNum;
    default:
      return false;
  }
}

// @ts-ignore
function matchDate(rowVal, operator, filterVal) {
  // parse them as YYYY-MM-DD if that's how you're storing them
  // or use new Date(...) if that suits your data
  const rowDate = new Date(rowVal);
  const filterDate = new Date(filterVal);

  // @ts-ignore
  if (isNaN(rowDate) || isNaN(filterDate)) return false;

  const rowTime = rowDate.getTime();
  const filterTime = filterDate.getTime();

  switch (operator) {
    case "is":
      return rowTime === filterTime;
    case "isNot":
      return rowTime !== filterTime;
    case "isAfter":
      return rowTime > filterTime;
    case "isOnOrAfter":
      return rowTime >= filterTime;
    case "isBefore":
      return rowTime < filterTime;
    case "isOnOrBefore":
      return rowTime <= filterTime;
    case "isEarlierOrEqual":
      return rowTime <= filterTime;
    case "isLaterOrEqual":
      return rowTime >= filterTime;
    default:
      return false;
  }
}

/*
  4) The custom Input: 
     - property name (Autocomplete)
     - property type is discovered from the row data
       => if user picks "Payment Terms" => type is "number"
       => if user picks "Governing Law" => type = "text"
       => ...
     - operator (Select) => changes if text vs. number vs. date
     - property value (Autocomplete or text field)
*/
// @ts-ignore
function PropertyFilterInput(props) {
  const { item, applyValue, apiRef } = props;
  const valueObj = item.value || {};

  // gather all rows
  const allRows = apiRef.current
    .getAllRowIds()
    // @ts-ignore
    .map((id) => apiRef.current.getRow(id));

  // find all { name, type } pairs
  const allProps = useMemo(() => {
    const map = new Map();
    // map.set(name, type)
    // @ts-ignore
    allRows.forEach((r) => {
      if (Array.isArray(r.properties)) {
        // @ts-ignore
        r.properties.forEach((p) => {
          // store the first type we see for a given name
          if (!map.has(p.name)) {
            map.set(p.name, p.type);
          }
        });
      }
    });
    return map;
  }, [allRows]);

  // array of property names
  const allPropNames = [...allProps.keys()];

  // once user picks a name => we discover its type
  const chosenType = allProps.get(valueObj.propName) || "text";
  // default to text if not found

  // now gather all possible values for that name
  const allPropValues = useMemo(() => {
    if (!valueObj.propName) return [];
    const setVals = new Set();
    // @ts-ignore
    allRows.forEach((r) => {
      if (Array.isArray(r.properties)) {
        // @ts-ignore
        r.properties.forEach((p) => {
          if (p.name === valueObj.propName) {
            setVals.add(p.value);
          }
        });
      }
    });
    return Array.from(setVals);
  }, [allRows, valueObj.propName]);

  /**
   * @type {any[]}
   */
  let operatorOptions = [];
  if (chosenType === "text") operatorOptions = textOperators;
  else if (chosenType === "number") operatorOptions = numberOperators;
  else if (chosenType === "date") operatorOptions = dateOperators;

  // handle changes
  // @ts-ignore
  const handlePropNameChange = (_, newVal) => {
    applyValue({
      ...item,
      value: {
        ...valueObj,
        propName: newVal || "",
        propType: allProps.get(newVal) || "text", // store the type
      },
    });
  };
  // @ts-ignore
  const handleOperatorChange = (e) => {
    applyValue({
      ...item,
      value: { ...valueObj, operator: e.target.value },
    });
  };
  // @ts-ignore
  const handlePropValueChange = (_, newVal) => {
    applyValue({
      ...item,
      value: { ...valueObj, propValue: newVal || "" },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      {/* 4A) Property Name */}
      <Autocomplete
        options={allPropNames}
        value={valueObj.propName || ""}
        onChange={handlePropNameChange}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Property Name" />
        )}
      />

      {/* 4B) Operator (based on chosen type) */}
      <FormControl variant="standard">
        <InputLabel>Operator</InputLabel>
        <Select
          label="Operator"
          value={valueObj.operator || ""}
          onChange={handleOperatorChange}
        >
          <MenuItem value="" disabled>
            Select Operator
          </MenuItem>
          {operatorOptions.map((op) => (
            <MenuItem key={op.value} value={op.value}>
              {op.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* 4C) Property Value (autocomplete) or freeSolo if you want */}
      <Autocomplete
        options={allPropValues}
        value={valueObj.propValue || ""}
        onChange={handlePropValueChange}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Property Value" />
        )}
      />
    </div>
  );
}

/**
 * @param {string} status
 * @returns {{ backgroundColor: string; color: string; }} status color
 */
function getLabelAndColorForStatusChip(status) {
  if (status === "Draft") {
    return {
      backgroundColor: "#ECECEC",
      color: "black",
    };
  }

  if (status === "Review") {
    return {
      backgroundColor: "#B99AFF",
      color: "white",
    };
  }

  if (status === "Negotiation") {
    return {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    };
  }

  if (status === "Being Signed") {
    return {
      backgroundColor: "#41A5E8",
      color: "white",
    };
  }

  if (status === "In Effect") {
    return {
      backgroundColor: "#00933B",
      color: "white",
    };
  }

  return {
    backgroundColor: "",
    color: "",
  };
}

/**
 * @param {import("react-router-dom").NavigateFunction} navigate
 * @param {string} email
 */
export function getColumns(navigate, email, isLawFirm = false) {
  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      renderCell: (/** @type {*} */ params) => {
        return (
          <img
            src={params.row?.logo || defaultLogo}
            alt=""
            height="30px"
            style={{ borderRadius: "50%" }}
          />
        );
      },
    },
    {
      field: !isLawFirm ? "counterpartyLegalName" : "clientLegalName",
      headerName: !isLawFirm ? "Counterparty Legal Name" : "Client Legal Name",
    },
    {
      field: "area",
      headerName: "Area",
      renderCell: (/** @type {*} */ params) => {
        const area = params.row.area;

        return (
          <Chip
            label={area}
            variant="outlined"
            size="small"
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            }}
          />
        );
      },
    },

    {
      field: "name",
      headerName: "Name",

      renderCell: (/** @type {*} */ params) => {
        return (
          <Link
            href={"blank"}
            fontWeight="bold"
            onClick={(event) => {
              event.preventDefault();
              navigate(`/agreement/${params.row?.name?._id}`);
            }}
          >
            {params.row?.name?.agrTitle}
          </Link>
        );
      },
      valueGetter: (/** @type {*} */ params) => params.row?.agreement?.agrTitle,
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (/** @type {*} */ params) => {
        const { backgroundColor, color } = getLabelAndColorForStatusChip(
          params.row.status
        );

        return (
          <Chip
            size="small"
            label={params.row.status}
            sx={{ backgroundColor, color, fontWeight: "bold" }}
          />
        );
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      type: "date",
      renderCell: (/** @type {*} */ params) => {
        const effectiveDate = params?.row?.effectiveDate;
        if (!effectiveDate) return <></>;

        return (
          <>
            {DateTime.fromISO(params.row.effectiveDate.toISOString()).toFormat(
              "dd/MM/yyyy"
            )}
          </>
        );
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      type: "date",
      renderCell: (/** @type {*} */ params) => {
        const effectiveDate = params?.row?.effectiveDate;
        if (!effectiveDate) return <></>;

        return (
          <b>
            {DateTime.fromISO(params.row.effectiveDate.toISOString()).toFormat(
              "dd/MM/yyyy"
            )}
          </b>
        );
      },
    },
    {
      field: "noticeDate",
      headerName: "Notice Date",
      type: "date",
      valueFormatter: dateColumnFormatter,
    },
    {
      field: "source",
      headerName: "Source",
    },
    {
      field: "labels",
      headerName: "Labels",
    },
    {
      field: "properties",
      headerName: "Properties",
      width: 200,
      filterOperators: propertyFilterOperators,
      renderCell: (
        /** @type {{ row: {properties: { name: string; value: string }[];} }} */ params
      ) => {
        if (!Array.isArray(params.row.properties)) return "";

        return params.row.properties
          .map((p) => `${p.name}: ${p.value}`)
          .join(" | ");
      },
      // valueGetter: (/** @type {*} */ params) => {
      //   if (!Array.isArray(params)) return "";

      //   return params
      //     .map(
      //       (/** @type {{ name: string; value: string; }} */ p) =>
      //         `${p.name}: ${p.value}`
      //     )
      //     .join(" | ");
      // },
    },
  ];

  if (getCanveoTier(email) === "experimental") {
    columns.push({
      field: "nextActionLiesWith",
      headerName: "Next Action Lies With ...",
    });
  }

  columns.push(
    {
      field: "currentAgreementOwner",
      headerName: "Current Agreement Owner",
      width: 200,
      renderCell: (
        /** @type {{ row: { currentAgreementOwner: * }}} */ params
      ) => {
        const assignee = params.row.currentAgreementOwner;
        return getDisplayUsersCellForTable([
          {
            displayName: assignee.displayName,
            title: assignee.title,
            photoURL: assignee.photoURL,
          },
        ]);
      },
      valueGetter: (row) => `${row?.displayName} (${row?.title})` || "",
    },
    {
      field: "agreementCreator",
      headerName: "Agreement Creator",
      width: 200,
      renderCell: (/** @type {{ row: { agreementCreator: * }}} */ params) => {
        const assignee = params.row.agreementCreator;
        return getDisplayUsersCellForTable([
          {
            displayName: assignee.displayName,
            title: assignee.title,
            photoURL: assignee.photoURL,
          },
        ]);
      },
      valueGetter: (row) => `${row?.displayName} (${row?.title})` || "",
    }
  );

  return columns;
}
