import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../state/store";
import { Flow } from "../components/Flow";
import { initialEdges, initialNodes } from "../components/Flow/constants";

/**
 * @typedef {object} DialogCreateWorkflowProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {(agrWorflow: *) => void} submit
 * @property {import("..").AgreementWorklfow | null} workflow
 */

/**
 * @param {DialogCreateWorkflowProps} props
 * @returns {JSX.Element}
 */
export function DialogCreateWorkflow({ open, close, submit, workflow = null }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [workflowName, setWorkflowName] = useState(
    workflow ? workflow.name : ""
  );
  const [appliesToAllAreas, setAppliesToAllAreas] = useState(
    workflow ? workflow.appliesToAllAreas : true
  );
  const [selectedAreas, setSelectedAreas] = useState(
    workflow ? workflow.selectedAreas : []
  );
  const [appliesToAllAgreementTypes, setAppliesToAllAgreementTypes] = useState(
    workflow ? workflow.appliesToAllAgreementTypes : true
  );
  const [selectedAgreementTypes, setSelectedAgreementTypes] = useState(
    workflow ? workflow.selectedAgreementTypes : []
  );
  const [nodes, setNodes] = useState(workflow ? workflow.nodes : initialNodes);
  const [edges, setEdges] = useState(workflow ? workflow.nodes : initialEdges);

  const areaLabels = state.labels.filter(
    (/** @type {{ _id: string; type: { name: string; }}} */ l) =>
      l.type.name === "Area"
  );

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>Create Workflow</DialogTitle>

      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container direction="column">
          <Grid item mt={2}>
            <TextField
              autoFocus
              label="Workflow Name"
              placeholder="Enter Workflow name"
              fullWidth
              value={workflowName}
              onChange={(event) => setWorkflowName(event.target.value)}
            />
          </Grid>

          <Grid item mt={5}>
            <Typography fontWeight="bold">
              Apply this workflow to the following agreements ...
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="All Areas"
                checked={appliesToAllAreas}
                onChange={(_event, checked) => setAppliesToAllAreas(checked)}
              />
            </FormGroup>
          </Grid>

          <Grid item mt={2}>
            <Autocomplete
              disablePortal
              disabled={appliesToAllAreas}
              options={areaLabels}
              getOptionLabel={(option) => option.name}
              value={selectedAreas}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              multiple
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Areas"
                  placeholder="Select Agreement Area ..."
                />
              )}
              onChange={(_event, value) => {
                setSelectedAreas(value);
              }}
            />
          </Grid>

          <Grid item mt={2}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="All Agreement Types"
                checked={appliesToAllAgreementTypes}
                onChange={(_event, checked) =>
                  setAppliesToAllAgreementTypes(checked)
                }
              />
            </FormGroup>
          </Grid>

          <Grid item mt={2}>
            <Autocomplete
              disablePortal
              disabled={appliesToAllAgreementTypes}
              options={state.agrTypes}
              getOptionLabel={(option) =>
                option.fullName.at(0) + ` (${option.shortName})`
              }
              value={selectedAgreementTypes}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              multiple
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agreement Types"
                  placeholder="Select Agreement Type ..."
                />
              )}
              onChange={(_event, value) => {
                setSelectedAgreementTypes(value);
              }}
            />
          </Grid>

          <Grid item>
            <Flow
              nodes={nodes}
              setNodes={setNodes}
              edges={edges}
              setEdges={setEdges}
            />

            {/* <Flow2 /> */}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Cancel</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={async () => {
            submit({
              name: workflowName,
              appliesToAllAreas,
              selectedAreas,
              appliesToAllAgreementTypes,
              selectedAgreementTypes,
              nodes,
              edges,
            });
          }}
          disabled={!workflowName}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
