import { Box } from "@mui/material";
import { addEdge, ReactFlow, ReactFlowProvider } from "@xyflow/react";
import React, { useState } from "react";
import { ActionNode } from "./components/ActionNode";
import { StatusNode } from "./components/StatusNode";
import DialogAddAction from "./dialogs/DialogAddAction";

/**
 * @import { initialNodes, initialEdges } from "./constants"
 * @typedef {object} FlowProps
 * @property {typeof initialNodes} nodes
 * @property {React.Dispatch<React.SetStateAction<typeof initialNodes>>} setNodes
 * @property {typeof initialNodes} edges
 * @property {React.Dispatch<React.SetStateAction<typeof initialNodes>>} setEdges
 */

/**
 * @param {FlowProps} props
 * @returns {JSX.Element}
 */
export function Flow({ nodes, setNodes, edges, setEdges }) {
  const [openDialogAddAction, setOpenDialogAddAction] = useState(false);
  const [editingNodeId, setEditingNodeId] = useState(null);
  const [selectedStatusId, setSelectedStatusId] = useState(null);
  const [selectedActionId, setSelectedActionId] = useState(null);

  const [actionType, setActionType] = useState("Send for Approval");

  const [actionStatus, setActionStatus] = useState("Pending");
  const [description, setDescription] = useState("");

  const [anchorCounts, setAnchorCounts] = useState({});

  const nodeTypes = {
    statusNode:
      /**
       * @param {*} nodeProps
       * @returns {JSX.Element}
       */
      (nodeProps) => (
        <StatusNode
          {...nodeProps}
          data={{
            ...nodeProps.data,
            onAddAction: handleOpenModalForStatus,
          }}
        />
      ),
    actionNode:
      /**
       * @param {*} nodeProps
       * @returns {JSX.Element}
       */
      (nodeProps) => (
        <ActionNode
          {...nodeProps}
          data={{
            ...nodeProps.data,
            nodeId: nodeProps.id,
            onEditAction: handleEditAction,
            onAddSequentialAction: handleOpenModalForAction,
          }}
        />
      ),
  };

  /**
   * @param {*} params
   * @returns {void}
   */
  function onConnect(params) {
    setEdges((eds) => addEdge(params, eds));
  }

  /**
   * @param {*} statusId
   * @returns {void}
   */
  function handleOpenModalForStatus(statusId) {
    setEditingNodeId(null);
    setSelectedStatusId(statusId);
    setSelectedActionId(null);
    setActionType("Send for Approval");
    setActionStatus("Pending");
    setDescription("");
    setOpenDialogAddAction(true);
  }

  /**
   * @param {*} actionNodeId
   * @returns {void}
   */
  function handleOpenModalForAction(actionNodeId) {
    setEditingNodeId(null);
    setSelectedStatusId(null);
    setSelectedActionId(actionNodeId);
    setActionType("Send for Approval");
    setActionStatus("Pending");
    setDescription("");
    setOpenDialogAddAction(true);
  }

  /**
   * @param {*} nodeId
   * @returns {void}
   */
  function handleEditAction(nodeId) {
    const nodeToEdit = nodes.find((n) => n.id === nodeId);
    if (!nodeToEdit) return;

    setEditingNodeId(nodeId);
    setSelectedStatusId(null);
    setSelectedActionId(null);

    // @ts-ignore
    setActionType(nodeToEdit.data.actionType);
    // @ts-ignore
    setActionStatus(nodeToEdit.data.actionStatus);
    // @ts-ignore
    setDescription(nodeToEdit.data.description);
    setOpenDialogAddAction(true);
  }

  /**
   * @param {import("./dialogs/DialogAddAction").WorkflowAction} action
   * @returns {void}
   */
  function handleSaveAction(action) {
    if (editingNodeId) {
      setNodes((prev) =>
        prev.map((n) => {
          if (n.id !== editingNodeId) return n;
          return {
            ...n,
            data: {
              ...n.data,
              actionType,
              actionStatus,
              description,
            },
          };
        })
      );
      setOpenDialogAddAction(false);
      setEditingNodeId(null);
      return;
    }

    let anchorId = selectedStatusId || selectedActionId;
    if (!anchorId) return;

    const anchorNode = nodes.find((n) => n.id === anchorId);
    if (!anchorNode) return;

    // @ts-ignore
    const offsetIndex = anchorCounts[anchorNode.id] || 0;
    const newNodeId = `action-${Date.now()}`;

    const newNode = {
      id: newNodeId,
      type: "actionNode",
      position: {
        x: anchorNode.position.x,
        y: anchorNode.position.y + 160 + offsetIndex * 180,
      },
      draggable: true,
      data: {
        actionType: action.label,
        actionStatus,
        hasChild: false,
        ...action,
        agreementStatus: anchorNode.data.agreementStatus,
      },
    };

    if (anchorNode.type === "actionNode") {
      setNodes((prev) =>
        prev.map((n) => {
          if (n.id === anchorNode.id) {
            return {
              ...n,
              data: { ...n.data, hasChild: true },
            };
          }
          return n;
        })
      );
    }

    const edgeStyle = {
      stroke: "#aaa",
      strokeWidth: 1,
      markerEnd: "url(#arrow)",
    };

    /** @type {*} */
    const newEdge = {
      id: `edge-${anchorNode.id}-${newNodeId}`,
      source: anchorNode.id,
      target: newNodeId,
      style: edgeStyle,
      agreementStatus: anchorNode.data.agreementStatus,
    };

    // @ts-ignore
    setNodes((prev) => [...prev, newNode]);
    setEdges((prev) => [...prev, newEdge]);

    setAnchorCounts((prev) => ({
      ...prev,
      [anchorNode.id]: offsetIndex + 1,
    }));

    setOpenDialogAddAction(false);
    setSelectedStatusId(null);
    setSelectedActionId(null);
  }

  return (
    <ReactFlowProvider>
      <Box
        width="100%"
        height="300px"
        bgcolor="#F2F2F2"
        mt={4}
        borderRadius="25px"
      >
        <ReactFlow
          nodes={nodes}
          // @ts-ignore
          edges={edges}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          fitView
        >
          <svg>
            <defs>
              <marker
                id="arrow"
                markerWidth="13"
                markerHeight="13"
                refX="10"
                refY="3"
                orient="auto"
              >
                <path d="M2,2 L2,4 L6,3 z" fill="#aaa" />
              </marker>
            </defs>
          </svg>
        </ReactFlow>

        {openDialogAddAction && (
          <DialogAddAction
            open={openDialogAddAction}
            close={() => setOpenDialogAddAction(false)}
            submit={handleSaveAction}
          />
        )}

        {/* {openDialogAddAction && (
          <Box sx={modalOverlayStyle}>
            <div style={modalContentStyle}>
              <h3>{editingNodeId ? "Edit Action" : "Add Action"}</h3>
              <div style={{ marginBottom: 10 }}>
                <label style={{ marginRight: 10 }}>Action Type: </label>
                <select
                  value={actionType}
                  onChange={(e) => setActionType(e.target.value)}
                >
                  <option>Send for Approval</option>
                  <option>Send for Review</option>
                </select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label style={{ marginRight: 10 }}>Assignee: </label>
                <select
                  value={assignee}
                  onChange={(e) => setAssignee(e.target.value)}
                >
                  {userOptions.map((u) => (
                    <option key={u} value={u}>
                      {u}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label style={{ marginRight: 10 }}>Action Status: </label>
                <select
                  value={actionStatus}
                  onChange={(e) => setActionStatus(e.target.value)}
                >
                  <option>Pending</option>
                  <option>Approved</option>
                </select>
              </div>
              <div style={{ marginBottom: 10 }}>
                <label>Description:</label>
                <textarea
                  style={{ width: "100%", height: 60, marginTop: 5 }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div style={{ marginTop: 10, textAlign: "right" }}>
                <button onClick={() => setOpenDialogAddAction(false)}>
                  Cancel
                </button>
                <button style={{ marginLeft: 10 }} onClick={handleSaveAction}>
                  {editingNodeId ? "Save" : "Submit"}
                </button>
              </div>
            </div>
          </Box>
        )} */}
      </Box>
    </ReactFlowProvider>
  );
}
