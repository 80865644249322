import {
  faBarsFilter,
  faCalendar,
  faCaretDown,
  faCaretUp,
  faCirclePlus,
  faFileContract,
  faFileImport,
  faHandshake,
  faSave,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { CanveoCircularProgress, FabStandard } from "../../components";
import DialogAddNewQuestionToQuestionnaire from "../../components/dialogs/DialogAddNewQuestionToQuestionnaire";
import { getMergeFieldDisplayValue } from "../../components/MergeFieldMenu/utils";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { getCanveoTier } from "../../utils/getCanveoTier";
import FilterDropdown from "../Agreements/components/FilterDropdown";
import { getDisplayUsersCellForTable } from "../Tasks/getAssigneesChip";
import { dateColumnFormatter } from "../utils/dateColumnFormatter";

/**
 * @import {GridColDef} from "@mui/x-data-grid"
 * @import { Property } from "../../hooks/useProperties"
 * @typedef {ReturnType<typeof mapPropertiesToRows>[number]} PropertiesTableRow
 * @typedef {GridColDef<ReturnType<typeof mapPropertiesToRows>[number]>} PropertiesTableColumn
 */

/** @type {PropertiesTableColumn[]} */
const columns = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 250,
    renderCell: (params) => {
      const taskStatus = params.row.name;
      return (
        <Chip
          size="small"
          label={taskStatus}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            fontWeight: "bold",
          }}
        />
      );
    },
  },
  {
    headerName: "Type",
    field: "type",
  },
  {
    headerName: "Area",
    field: "area",
    minWidth: 150,
    renderCell: (params) => {
      const areaLabels = params.row.area;
      if (typeof areaLabels === "string") return areaLabels;

      return (
        <Grid container direction="column" gap={1}>
          {areaLabels.map((al, index) => (
            <Grid key={index} item>
              <Chip
                size="small"
                label={al}
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
              />
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    headerName: "Agreement Type",
    field: "agreementType",
    minWidth: 200,
    renderCell: (params) => {
      const agreementType = params.row.agreementType;
      if (typeof agreementType === "string") return agreementType;

      return (
        <Grid container direction="column" gap={1}>
          {agreementType.map((at, index) => (
            <Grid key={index} item>
              {at} {index !== agreementType.length - 1 ? " | " : undefined}
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    headerName: "Created",
    field: "created",
    valueFormatter: dateColumnFormatter,
  },
  {
    headerName: "Updated",
    field: "updated",
    type: "date",
    valueFormatter: dateColumnFormatter,
  },
  {
    headerName: "Creator",
    field: "creator",
    minWidth: 250,
    renderCell: (params) => {
      const creator = params.row.creator;
      return getDisplayUsersCellForTable([
        {
          displayName: creator.displayName,
          title: creator.title,
          photoURL: creator.photoURL,
        },
      ]);
    },
  },
];

/**
 * @param {Property[]} properties
 */
function mapPropertiesToRows(properties) {
  const rows = properties.map((p) => {
    const displayValue = getMergeFieldDisplayValue(p.value);

    return {
      id: p._id,
      name: `${p.name}${displayValue ? `: ${displayValue}` : ""}`,
      type: p.scope === "document" ? "Merge Field" : "Property",
      area:
        p.areaLabels?.length > 0
          ? p.areaLabels
              // @ts-ignore
              .map((x) => x.name)
          : "[any]",
      agreementType:
        p.agreementTypes?.length > 0
          ? // @ts-ignore
            p.agreementTypes.map((x) => `${x.fullName[0]} (${x.shortName})`)
          : "[any]",
      created: new Date(p.createdAt),
      updated: new Date(p.updatedAt),
      creator: p.createdBy || {
        displayName: "Canveo User",
        title: undefined,
        photoURL: undefined,
      },
    };
  });

  return rows;
}

/**
 * @returns {JSX.Element}
 */
export function Properties() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(/** @type {PropertiesTableRow[]} */ ([]));
  const [showFilters, setShowFilters] = useState(false);
  const [
    openDialogAddNewQuestionToQuestionnaire,
    setOpenDialogAddNewQuestionToQuestionnaire,
  ] = useState(false);

  useEffect(
    () => {
      loadProperties();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function loadProperties() {
    try {
      setIsLoading(true);

      const result = await axios.get(
        `${state.settings.api}properties?isTemplate=true`
      );

      /** @type {Property[]} */
      const properties = result.data.data;
      const rows = mapPropertiesToRows(properties);
      setRows(rows);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          severity: "error",
          message:
            "Unable to retrieve properties, try again or contact Canveo Support if the issue persists.",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      {isLoading ? (
        <Grid container justifyContent="center" sx={{ mt: 30 }}>
          <CanveoCircularProgress />
        </Grid>
      ) : (
        <>
          <FabStandard
            text="New"
            icon={faCirclePlus}
            sx={{
              left: "100px",
              top: "80px",
              right: "unset",
            }}
            click={() => setOpenDialogAddNewQuestionToQuestionnaire(true)}
          />

          {openDialogAddNewQuestionToQuestionnaire && (
            <DialogAddNewQuestionToQuestionnaire
              open={openDialogAddNewQuestionToQuestionnaire}
              close={() => setOpenDialogAddNewQuestionToQuestionnaire(false)}
              submit={() => loadProperties()}
              organizationId={state.org._id}
              organizationName={state.org.shortName}
              documentIsTemplate={true}
              partyId="party0"
              agrvId={state.drawerVersions.active?._id}
            />
          )}

          <Grid item>
            <Typography variant="h4">Manage Properties</Typography>
          </Grid>

          <Grid item sx={{ mt: 2 }}>
            <Typography variant="body1" textAlign="center">
              Properties are metadata points you can track and extract from all
              your agreements
            </Typography>
          </Grid>

          <Box>
            <Grid container direction="row" mt={4} spacing={1}>
              {getCanveoTier(state?.user?.email) === "experimental" && (
                <>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={
                        <FontAwesomeIcon
                          icon={faBarsFilter}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      endIcon={
                        <FontAwesomeIcon
                          icon={showFilters ? faCaretUp : faCaretDown}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      onClick={() => setShowFilters(!showFilters)}
                    >
                      Filters
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ fontSize: "14px" }}
                        />
                      }
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          style={{ fontSize: "14px" }}
                        />
                      }
                    >
                      Saved Searches
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {showFilters && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                width={1020}
              >
                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faFileContract} />
                    <Typography variant="subtitle1">Agreement</Typography>
                  </Grid>
                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Name" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Type" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Area" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Status" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Next Action Lies With" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Labels" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Source" />
                    </Grid>
                    <Grid item xs={2}>
                      <FilterDropdown label="Notes" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faHandshake} />
                    <Typography variant="subtitle1">Parties</Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Info" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Info" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Info" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <Typography variant="subtitle1">Dates & Renewal</Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Created Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Updated Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Effective Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Start Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Expiry Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Term" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Mode" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Term" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Notice Period" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Notice Date" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Renewal Task Assignee" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faFileImport} />
                    <Typography variant="subtitle1">
                      Merge Fields & Properties
                    </Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Merge Fields" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Parameters" />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid container alignItems="center" gap={1}>
                    <FontAwesomeIcon icon={faUser} />
                    <Typography variant="subtitle1">
                      Collaborators & Signers
                    </Typography>
                  </Grid>

                  <Grid container mt={2} gap={2}>
                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Counterparty Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Client Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Collaborators" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Our Signers" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Current Agreement Owner" />
                    </Grid>

                    <Grid item xs={2}>
                      <FilterDropdown label="Agreement Creator" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container mt={2} mb={16} justifyContent="center">
              <Box sx={{ height: 400, width: 1020 }}>
                <DataGrid
                  checkboxSelection
                  density="comfortable"
                  getRowHeight={() => "auto"}
                  columns={columns}
                  rows={rows}
                  disableRowSelectionOnClick
                  onCellClick={() =>
                    setOpenDialogAddNewQuestionToQuestionnaire(true)
                  }
                  filterMode="client"
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 2,
                    },
                    [`& .${gridClasses.columnHeaderTitle}`]: {
                      fontWeight: 400,
                    },
                    [`& .${gridClasses.detailPanel}`]: {
                      background: "transparent",
                    },
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                      {
                        outline: "none",
                      },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: "none",
                      },
                    border: 0,
                    "& .MuiDataGrid-cell": {
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                  hideFooter
                  autoHeight
                  disableColumnFilter
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
    </Grid>
  );
}
