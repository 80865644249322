import { Button, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useGoogleDriveFolderPicker } from "../../hooks/useGoogleDriveFolderPicker";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { randomString } from "../../utils";
import {
  extractPartyInfo,
  getLabels,
  getOrigin,
} from "../../utils/agreementCreation";
import getFileNameFromPathBuilder from "../PathBuilder/utils/getFilenameFromPathBuilder";

/**
 * @import { AgreementData } from "../../views/CreateAgreement"
 */

/**
 * @typedef {object} DirectoryBuilderSelectorProps
 * @property {boolean} active
 * @property {AgreementData} agreement
 * @property {function} handleAgreementChange
 */

/**
 * @param {DirectoryBuilderSelectorProps} props
 */
export default function DirectoryBuilderSelector({
  agreement: agreementData,
  handleAgreementChange,
  active,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [folderPath, setFolderPath] = useState("");

  const [, setLoading] = useState(false);
  const [gdrivePickerLoaded, setGdrivePickerLoaded] = useState(false);

  const sharepointIntegrationEnabled =
    state?.org?.integrations?.azure?.autoStore || false;
  const googleDriveIntegrationEnabled =
    state?.org?.integrations?.gdrive?.autoSync || false;

  const canChangeFolderPath =
    sharepointIntegrationEnabled || googleDriveIntegrationEnabled;

  const { handleOpenPicker } = useGoogleDriveFolderPicker({
    setLoading,
    gdrivePickerLoaded,
    setGdrivePickerLoaded,
    pushOrg: (newOrgData) => {
      const folderName = newOrgData?.integrations?.gdrive?.folderName || "";
      const folderId = newOrgData?.integrations?.gdrive?.folderId || "";

      if (!folderName) return;

      setFolderPath(folderName);
      handleAgreementChange("customFolderPath", { folderName, folderId });
    },
  });

  async function generateFolderPathFromOrganizationDefaultFolderPathBuilder() {
    const isBackload = ["backload"].includes(agreementData.template.uploadType);
    const creationDate = new Date().toISOString();
    const { organizations, entities, signers, collaborators } =
      extractPartyInfo(agreementData, state, isBackload);
    const { labels } = getLabels(agreementData, state);
    const orgSettings = state.org?.orgSettings;
    const wLabel =
      !isBackload &&
      orgSettings?.whiteLabel?.level === "email" &&
      !!orgSettings?.whiteLabel?.color
        ? orgSettings.whiteLabel
        : null;
    const customURL = !isBackload ? randomString(20) : null;
    const description = `Contains work done by ${state.org.shortName}`;
    const template = agreementData.template;
    const origin = getOrigin(template);
    const roles = agreementData?.parties
      ?.filter((party) => !!party.role)
      ?.map((party) => ({
        label: party.role,
      }));
    const fileExtension =
      template?.fileName?.toLowerCase()?.split(".")?.pop() ||
      template?.origin?.import?.split("#")?.at(0)?.split(".")?.pop();
    const pdfFileKey = fileExtension === "pdf" ? template.file : "";

    const document = {
      versionType: ["pdf", "docx", "xlsx", "pptx"].includes(
        agreementData.template?.fileType
      ) // AgreementVersion related
        ? agreementData.template?.fileType // This is an attachment
        : "canveo", // This is a Canveo Doc
      content: agreementData?.template?.content,
      contentMetadata: {
        listsStructure:
          agreementData.template.contentMetadata?.listsStructure ?? [],
      },
      agrTypeID: agreementData.template?.agrTypeID._id,
      agrTitle: agreementData.template?.agrTitle,
      agrStatus: isBackload ? "InEffect" : "Draft",
      orgs: organizations,
      ents: entities,
      collabs: collaborators,
      signers: signers,
      priority: 0,
      amendment: null,
      avOwners: [state.org._id],
      createdByOrg: state.org._id,
      owner: state.org._id,
      effectiveDate: null,
      renewalType: "auto",
      effectiveTerm: null,
      expiryDate: null,
      sigConfig: {
        provider: state?.org?.integrations?.docusign?.useOwnSubscription
          ? "docusign"
          : "skribble",
        quality: "SES",
      },
      dealValue: [],
      labels: labels,
      areaLabels: [{ labelID: agreementData.areaLabel }],
      customURL: customURL,
      whiteLabel: wLabel,
      creationBy: state.user._id,
      creationDate: creationDate,
      lastUpdateBy: state.user._id,
      lastUpdateDate: creationDate,
      fileSource: agreementData.template?.fileSource?.value,
      origin: origin,
      description: description,
      sfdt: agreementData.template?.sfdt,
      firstPageHeader: agreementData.template?.firstPageHeader,
      firstPageFooter: agreementData.template?.firstPageFooter,
      visibleTo: [state.org._id],
      tid: agreementData.template.versionId,
      hubSpotTicketToUpdate: agreementData.hubSpotTicketToUpdate,
      roles,
      tickets: agreementData.tickets,
      workflowCreationOrganization:
        agreementData.trackedChangesAuthorship?.orgID,
      pdfFileKey,
    };

    getFileNameFromPathBuilder(document, state, false, "folder")
      .then((folderPath) => {
        setFolderPath(folderPath);
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            severity: "error",
            message:
              "Unable to generate default folder path for agreement, try again or contact Canveo Support if the issue persists.",
          },
        });
      });
  }

  useEffect(
    () => {
      if (!active) return;

      generateFolderPathFromOrganizationDefaultFolderPathBuilder();
    },
    // Runs only when there are changes to the active step.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active]
  );

  return (
    <Grid container mt={2} alignItems="flex-end">
      <Grid item>
        <Grid container direction="column" alignItems="flex-start">
          {(sharepointIntegrationEnabled || googleDriveIntegrationEnabled) && (
            <Grid item mb={2}>
              <Typography variant="subtitle1">
                {sharepointIntegrationEnabled ? "SharePoint" : "Google Drive"}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography
              fontWeight="bold"
              color={theme.palette.primary.main}
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {folderPath}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {canChangeFolderPath && (
        <Grid item ml={4}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              // Check for Google Drive integration first.
              if (googleDriveIntegrationEnabled) {
                handleOpenPicker();
                return;
              }

              // TODO: Implement custom Sharepoint folder picker by fetching sites via Microsoft Graph API and
              // display everything in a custom UI e.g., using Fluent UI or Material-UI.
            }}
          >
            Change ...
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
