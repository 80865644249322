import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import useSummaryService from "../../../../hooks/useSummaryService";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import { fileSources } from "../../../../utils/fileSourceMapping";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import { AnalyzeCurrentAgreementVersionButton } from "../../../AnalyzeCurrentAgreementVersionButton";
import Collapsable from "../../../Collapsable";
import PartyList from "../../../PartyList";
import UsersByParty from "../../../UsersByParty";

/**
 * @typedef {object} SummaryTabProps
 * @property {string} docID
 * @property {boolean} isTemplate
 * @property {string} partyId
 * @property {string} jobStatus
 * @property {(props: { extractMetadata: boolean; complianceAnalysis: boolean; }) => Promise<void>} analyzeUsingAi
 * @property {*} jobResult
 */

/**
 * @param {SummaryTabProps} props
 */
export function SummaryTab({
  docID,
  isTemplate,
  analyzeUsingAi,
  jobResult,
  jobStatus,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const { document, signers, collaborators } = useSummaryService(
    docID,
    isTemplate
  );

  if (!document) return;

  function getAgrType() {
    if (isTemplate) {
      return `${document.agrTypeID.fullName[0]} (${document.agrTypeID.shortName})`;
    }

    const agrType = state.agrTypes.find(
      (/** @type {{ _id: string; }} */ at) => at._id === document?.agrTypeID
    );
    if (!agrType) return;

    return `${agrType.fullName[0]} (${agrType.shortName})`;
  }

  function getStatus() {
    if (isTemplate) {
      if (document.active) {
        return "Active";
      }

      if (!document.active) {
        return "Draft";
      }
    }

    if (document.agrStatus === "InEffect") {
      return "In Effect";
    }

    return document.agrStatus;
  }

  return (
    <Box position="absolute" ml={4} mt={2}>
      <AnalyzeCurrentAgreementVersionButton
        jobStatus={jobStatus}
        jobResult={jobResult}
        analyzeUsingAi={analyzeUsingAi}
      />

      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <Box sx={{ px: 2 }}>
          <Collapsable
            sx={{ py: 2 }}
            title={"Overview"}
            collapsed={undefined}
            width="500px"
          >
            <Box sx={{ py: 1 }}>
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {document.agrTitle}
                </Typography>
              </Box>
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Type
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {getAgrType()}
                </Typography>
              </Box>
              {state.user.role.name !== "Counterparty" && (
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Source
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "520",
                      fontSize: "16px",
                      color: theme.palette.grey[800],
                    }}
                  >
                    {
                      fileSources?.find(
                        (fs) => fs.value === document.fileSource
                      )?.label
                    }
                  </Typography>
                </Box>
              )}
              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: theme.palette.grey[600],
                  }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "520",
                    fontSize: "16px",
                    color: theme.palette.grey[800],
                  }}
                >
                  {getStatus()}
                </Typography>
              </Box>
              {state.user.role.name !== "Counterparty" && (
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: theme.palette.grey[600],
                    }}
                  >
                    Area
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "520",
                      fontSize: "16px",
                      color: theme.palette.grey[800],
                    }}
                  >
                    {document.areaLabels
                      ?.map((/** @type {*} */ al) => al.labelID.name)
                      .join(", ")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Collapsable>

          {!isTemplate && (
            <>
              <Collapsable
                sx={{ py: 2 }}
                title={"Parties"}
                collapsed={undefined}
              >
                <PartyList
                  readOnly
                  parties={state.parties.filter(
                    (/** @type {{ role: string; }} */ p) => p.role
                  )}
                  sx={{ px: 1, pt: 2 }}
                  roles={document?.roles || []}
                  handleAddParty={() => {}}
                  handleEntityChange={() => {}}
                  handleEntityUpdate={() => {}}
                  handlePartyChange={() => {}}
                  handleRemoveParty={() => {}}
                  handleRoleChange={() => {}}
                  owner={state.org._id}
                />
              </Collapsable>

              <Collapsable sx={{ py: 2 }} collapsed title={"Collaborators"}>
                <UsersByParty
                  py={1}
                  pl={1}
                  parties={state.parties}
                  users={collaborators}
                />
              </Collapsable>

              <Collapsable sx={{ py: 2 }} collapsed title={"Signers"}>
                <UsersByParty
                  py={1}
                  pl={1}
                  parties={state.parties}
                  users={signers}
                />
              </Collapsable>
            </>
          )}

          {getCanveoTier(state?.user?.email) === "experimental" &&
            state.user.role.name !== "Counterparty" && (
              <Collapsable sx={{ py: 2 }} title={"Notes"} collapsed={undefined}>
                <Typography pl={1}>{document.notes}</Typography>
              </Collapsable>
            )}
        </Box>
      </Box>
    </Box>
  );
}
