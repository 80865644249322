import { Box, Chip } from "@mui/material";
import {
  // @ts-ignore
  Handle,
  Position,
} from "@xyflow/react";
import React, { useRef, useState } from "react";

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export function ActionNode({ data }) {
  const [hovered, setHovered] = useState(false);
  const leaveTimerRef = useRef(null);

  // This node won't show "Once completed" if it already has a child.
  const showChip = !data.hasChild;

  const handleMouseEnter = () => {
    if (leaveTimerRef.current) {
      clearTimeout(leaveTimerRef.current);
      leaveTimerRef.current = null;
    }
    setHovered(true);
  };

  const handleMouseLeave = () => {
    // @ts-ignore
    leaveTimerRef.current = setTimeout(() => {
      setHovered(false);
      leaveTimerRef.current = null;
    }, 800);
  };

  return (
    <Box
      style={{
        padding: 10,
        background: "#fff",
        borderRadius: 8,
        boxShadow: "0 0 8px rgba(128,0,128,0.4)",
        minWidth: 120,
        fontFamily: "Roboto, sans-serif",
        cursor: "grab",
        position: "relative",
        paddingBottom: 20,
      }}
      className="drag-handle"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box style={{ fontWeight: "bold" }}>{data.actionType}</Box>
      {data.actionType === "Review Task" && (
        <Box>
          Assignees:{" "}
          {data.reviewTickets
            .at(0)
            .reviewChain.reviewList.filter(
              (/** @type {{ role: string; }} */ rl) => rl.role === "reviewer"
            )
            .map((/** @type {{ displayName: string; }} */ rl) => (
              <div>{rl.displayName}</div>
            ))}
        </Box>
      )}

      {data.actionType === "Approval Request" && (
        <Box>
          Assignees:{" "}
          {data.approvalTickets
            .at(0)
            .approvalChain.approvalList.filter(
              (/** @type {{ role: string; }} */ rl) => rl.role === "approver"
            )
            .map((/** @type {{ displayName: string; }} */ rl) => (
              <div>{rl.displayName}</div>
            ))}
        </Box>
      )}

      {data.actionType === "AI Analysis" && (
        <Box>
          <Box>
            {data.doComplianceAnalysis
              ? "Risk and Compliance"
              : data.fillInCustomProperties
              ? "Extract Properties"
              : ""}
          </Box>
        </Box>
      )}

      <Handle type="target" position={Position.Top} style={{ top: 0 }} />
      <Handle type="source" position={Position.Bottom} style={{ bottom: 0 }} />

      {hovered && showChip && (
        <>
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              width: "2px",
              height: "20px",
              backgroundColor: "purple",
              transform: "translateX(-50%)",
              pointerEvents: "none",
            }}
          />
          <Chip
            label="Once completed"
            onClick={() => data.onAddSequentialAction(data.nodeId)}
            size="small"
            sx={{
              position: "absolute",
              top: "120%",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "white",
              color: "purple",
              border: "1px solid purple",
              cursor: "pointer",
            }}
          />
        </>
      )}
    </Box>
  );
}
